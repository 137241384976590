import axios from 'axios';
import { environment } from '../../../environments/environment';

const postRegister = async (user) => {
  const path = environment.api + environment.postRegister;
  let config = {};
  let body = {
    username: user.email,
    email: user.email,
    first_name: user.name,
    last_name: user.lastName,
    phone_number: user.phone,
    entity: user.entity,
    password: user.password1
  };
  return await axios.post(path, body, config);
};

export { postRegister };
