import './Bioinformatic.css';
import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import Home from './home/Home';
import PipelinesRoutes from './pipelines/PipelinesRoutes';
import Persefone from './persefone/Persefone';
import DatabasesBioinformatic from './databasesBioinformatic/DatabasesBioinformatic';

export default function BioinformaticRoutes() {

  return (
    <React.Fragment>
      <div className='container-fluid overflow-x-hidden'>
        <Routes>
          <Route path="" element={ <Navigate to="home" /> }/>
          <Route path="home" element={<Home/>} />
          <Route path="/pipelines/*" element={<PipelinesRoutes/>} />
          <Route path="persefone" element={<Persefone/>} />
          <Route path="databasesBioinformatic" element={<DatabasesBioinformatic/>} />
          <Route path="persefone" element={<Persefone/>} />
        </Routes>
      </div>
    </React.Fragment>
  )
}
