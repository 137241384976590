import './Genomics.css'
import IconFolder from '../../../../../../assets/images/icon-folder.svg';
import IconArchive from '../../../../../../assets/images/icon-archive.svg';
import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactFlow, { Background, Handle, Position, MarkerType, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated';
import { Appcontext } from '../../../../../../appContext';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
import Preloader from '../../../../../shared/preloader/Preloader';
import { getFilesUser, getFoldersUser, getHardwareUsageService, getPipelinesAmmountService } from '../../../../../../services/dahsboard/bioinformatic/pipelines/filesBioinformatic/filesBioinformatic';
import Swal from 'sweetalert2';
import { executePipelineService } from '../../../../../../services/dahsboard/bioinformatic/pipelines/genomics/genomics';
import { useNavigate } from 'react-router-dom';
require('jquery-mousewheel');

/**
 * MENSAJES PERSONALIZADOS AL BUSCAR O CARGAR OPCIONES EN REACT SELECT
 */

const { NoOptionsMessage } = components;

const customNoOptionsMessage = props => (
  <NoOptionsMessage {...props}>No registrado</NoOptionsMessage>
);

const { LoadingMessage } = components;

const customLoadingMessage = props => (
  <LoadingMessage {...props}>Cargando</LoadingMessage>
);

/**
 * ANIMATE DELETE MULTISELECT
 */

const animatedComponents = makeAnimated();

/**
 * Data que llena los select
 */

const CustomSelect = [
  { value: "opcion-uno", label: "Opcion uno" },
  { value: "opcion-dos", label: "Opcion dos" },
  { value: "opcion-tres", label: "Opcion tres" }
];

const Preprocessing = [
  { value: "trimmomatic", label: "Trimmomatic" }
];

const AssemblerType = [
  { value: "procariota", label: "Procariota" },
  { value: "eucariota", label: "Eucariota" }
];

/**
 * Constante que soporta todo el cambio de los estilo del select
 */

const selectStyles = {
  /**
 * Estilos del icono del dropdown del select
 * Estilos del separador del select
 * Estilos del icono de cerrar del select
 */
  dropdownIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important', 
    paddingRight: '0.5rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  clearIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  /**
 * Estilos del input global
 */
  control: () => ({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    alignSelf: "start",
    justifyContent: "start",
    height: 'auto',
    minHeight: 50,
    maxHeight: 150,
    paddingLeft: '15px',
    paddingTop: '0.3rem',
    width: "100%",
    backgroundColor: 'var(--color-quaternary-blue-)',
    borderRadius: "1.18rem",
    border: "0px solid transparent",
  }),
  /**
* EESTILOS DEL INPUT
*/
  input: (provided) => ({
    ...provided,
    color: 'var(--color-quaternary-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  /**
 * Estilos del menu desplegable del select
 */
  menu: (styles) => ({
    ...styles,
    border: 'none',
    borderColor: 'var(--color-quaternary)',
    backgroundColor: 'var(--color-tertiary-white-rgba-)',
    boxShadow: 'var(--box-shadow-12-)',
    borderRadius: '1rem',
    padding: 0,
    marginTop: 8,
    marginBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "hidden",
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent !important"
    }
  }),
  /**
 * Estilos de las opciones desplegables
 */
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: 'var(--color-gray-)',
    backgroundColor: state.isSelected ? "var(--color-sextary-white-)" : "var(--color-tertiary-white-rgba-)",
    fontFamily: 'var(--font-family-regular-)',
    padding: '0.5rem 0.8rem 0.5rem 0.8rem',
    borderRadius: '1rem',
    ":hover": {
      background: "var(--color-sextary-white-)",
      color: 'var(--color-black-)',
    }
  }),
  /**
 * Estilos del contenedor
 */
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    position: 'relative',
    flex: '1 1 auto'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    paddingTop: '0rem !important',
    marginLeft: '0px',
    paddingRight: 0,
    padding: '0px 0px'
  }),
  /**
 * Estilos placeholder del input
 */
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
    position: "absolute",
    color: 'var(--color-quaternary-gray-)',
    lineHeight: 1.25,
    fontFamily: 'var(--font-family-regular-)',
    overflow: 'hidden',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  /**
 * Estilos texto en el input
 */
  singleValue: (styles) => ({ 
    ...styles, 
    fontSize: 14,
    color: "var(--color-quaternary-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    padding: '3px',
    margin: '0px',
    marginTop: '2px',
    marginLeft: 0,
    marginRight: 0
  })
}

export default function Genomics() {
  const { token, setToken, userApplication, foldersBioinformatic, setFoldersBioinformatic, filesBioinformatic, setFilesBioinformatic } = useContext(Appcontext);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [charging, setCharging] = useState(false);
  const [files, setFiles] = useState([]);
  const [foldersCharged, setFoldersCharged] = useState(false);
  const [carpetRoot, setCarpetRoot] = useState('Sequ');
  const [folderSelected, setFolderSelected] = useState(null);
  const [formSaved, setFormSaved] = useState(false);
  const [formParameters, setFormParameters] = useState({
    cpu: '',
    memory: '',
    preprocessing: '',
    organismType: ''
  });

  useEffect(() => {

    /**
     * Responsive de la grafica de flowchart
     */

    const handleResize = () => {
      const width = containerRef.current.offsetWidth;
      const height = containerRef.current.offsetHeight;

      setContainerSize({ width, height });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    getServices();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * Overlay para las tarjetas
   */

  const [showOverlay, setShowOverlay] = useState(false);
  const [hiddenOverlay, setHiddenOverlay] = useState(true);

  const toggleOverlay = (cardId) => {
    setShowOverlay(cardId);
    setHiddenOverlay(false);
  };

  const QualityControlStepOne = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-quality-control-step-one-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right}/>
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const QualityControlStepTwo = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-quality-control-step-two-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right}/>
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const AssembledGenome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-assembled-genome-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right}/>
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const QualityControlAssembly = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-quality-control-assembly-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right}/>
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const StructuralAnnotation = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-structural-annotation-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right}/>
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const FunctionalAnnotation = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-functional-annotation-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right}/>
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const AnnotationFile = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeEnd-">
          <button className="btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center button-open- btn-bone-white-" type='button' onClick={() => toggleOverlay('card-annotation-file-')}>
            <img className='icon-archive-pipeline-' src={IconArchive} alt="" />
          </button>
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const CustomNodeNormal = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left}/>
      </div>
    );
  };

  const nodeTypesGenomics = {
    nodeQualityControlStepOne: QualityControlStepOne,
    nodeQualityControlStepTwo: QualityControlStepTwo,
    nodeAssembledGenome: AssembledGenome,
    nodeQualityControlAssembly: QualityControlAssembly,
    nodeStructuralAnnotation: StructuralAnnotation,
    nodeFunctionalAnnotation: FunctionalAnnotation,
    nodeAnnotationFile: AnnotationFile,
    nodeNormal: CustomNodeNormal,
  };

  const defaultNodesGenomics = [
    {
      id: 'A',
      type: 'nodeQualityControlStepOne',
      data: { name: 'Control de calidad de lecturas', job: '(FastQC, MultiQC)', action: '' },
      style: { border: '2px solid var(--color-green-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 44, y: 20 }
    },
    {
      id: 'B',
      type: 'nodeNormal',
      data: { name: 'Preprocesamiento de lecturas', job: '(Trimmomatic, Cutadapt)'},
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 344, y: 20 }
    },
    {
      id: 'C',
      type: 'nodeQualityControlStepTwo',
      data: { name: 'Control de calidad de lecturas', job: '(FastQC, MultiQC)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 644, y: 20 }
    },
    {
      id: 'D',
      type: 'nodeNormal',
      data: { name: 'Ensamblaje de novo', job: '(SPAdes, MaSuRCA, ABySs)'},
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 44, y: 200 }
    },
    {
      id: 'E',
      type: 'nodeAssembledGenome',
      data: { name: 'Genoma ensamblado', job: '(Formato fasta)', action: '' },
      style: { border: '2px solid var(--color-secondary-pink)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 100},
      draggable: false,
      position: { x: 344, y: 200 }
    },
    {
      id: 'F',
      type: 'nodeQualityControlAssembly',
      data: { name: 'Control de calidad del ensamblaje', job: '(QUAST, BUSCO)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 644, y: 200 }
    },
    {
      id: 'G',
      type: 'nodeStructuralAnnotation',
      data: { name: 'Anotación estructural', job: '(Prokka, MAKER2)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 44, y: 380 }
    },
    {
      id: 'H',
      type: 'nodeFunctionalAnnotation',
      data: { name: 'Anotación funcional', job: '', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 344, y: 380 }
    },
    {
      id: 'I',
      type: 'nodeAnnotationFile',
      data: { name: 'Archivo de anotación', job: '', action: '' },
      style: { border: '2px solid var(--color-white-)', backgroundColor: 'var(--color-black-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15},
      draggable: false,
      position: { x: 644, y: 380 }
    },
  ];

  const defaultEdgesGenomics = [
    {
      id: 'A->B',
      type: 'smoothstep',
      source: 'A',
      sourceHandle: 'right',
      target: 'B',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 18,
        height: 18,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'B->C',
      type: 'smoothstep',
      source: 'B',
      sourceHandle: 'right',
      target: 'C',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 18,
        height: 18,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'C->D',
      type: 'smoothstep',
      source: 'C',
      sourceHandle: 'right',
      target: 'D',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'D->E',
      type: 'smoothstep',
      source: 'D',
      sourceHandle: 'right',
      target: 'E',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF'},
      arrowHeadType: 'arrow',
    },
    {
      id: 'E->F',
      type: 'smoothstep',
      source: 'E',
      sourceHandle: 'right',
      target: 'F',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'F->G',
      type: 'smoothstep',
      source: 'F',
      sourceHandle: 'right',
      target: 'G',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'G->H',
      type: 'smoothstep',
      source: 'G',
      sourceHandle: 'right',
      target: 'H',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'H->I',
      type: 'smoothstep',
      source: 'H',
      sourceHandle: 'right',
      target: 'I',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    }
  ];

  useEffect(()=>{

    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.wrapper-card-files-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.wrapper-data-results-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

  }, []);

  useEffect(() => {
    if (foldersCharged) {
      getServiceFolder();
    }
  }, [foldersCharged]);

  const getServices = async () => {
    if (!filesBioinformatic) {
      await getFiles();
    } else {
      let array = filesBioinformatic.map(file => {
        return {
          ... file,
          checked: false
        };
      });
      setFiles(array);
    }
  };

  const getServiceFolder = async () => {
    if (!foldersBioinformatic) {
      await getFolders();
    }
  };

  const getFiles = async () => {
    setCharging(true);
    let response = await getFilesUser(token, userApplication._id).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar cargar los archivos',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      let array = data.map(file => {
        let nameFile = file.path.split('/');
        return {
          ... file,
          name: nameFile[nameFile.length - 1]
        };
      });
      let secondArray = data.map(file => {
        let nameFile = file.path.split('/');
        return {
          ... file,
          name: nameFile[nameFile.length - 1],
          checked: false
        };
      });
      setFiles(secondArray);
      setFilesBioinformatic(array);
      setFoldersCharged(true);
      setCharging(false);
    }
  };

  const getFolders = async () => {
    setCharging(true);
    let response = await getFoldersUser(token, userApplication._id).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar cargar las carpetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      let array = [];
      for (let index = 0; index < data.length; index++) {
        array = [
          ... array,
          ... splitFolders(data[index]),
        ]
      }
      setFoldersBioinformatic(array);
      setCharging(false);
    }
  };

  const splitFolders = (folders) => {
    let array = [];
    let fold = folders.folder_path.split('/');
    for (let index = 1; index < fold.length; index++) {
      array.push({
        ... folders,
        name: fold[index],
        parent: fold[index - 1],
        tab: fold[0],
        filesInside: calculateFilesInside(folders, filesBioinformatic)
      });
    }
    return array;
  };

  const calculateFilesInside = (folder, files) => {
    return files.filter(file => file.file_path === folder.folder_path).length;
  };

  const changeCheck = (row) => {
    let array = files.map(result => result);
    let index = array.findIndex(result => result === row);
    array[index] = {
      ...array[index],
      checked: !array[index].checked
    };
    setFiles(array);
  };

  const cleanForm = () => {
    setFormParameters({
      cpu: '',
      memory: '',
      preprocessing: '',
      organismType: ''
    });
    setFormSaved(false);
  };

  const cleanChecks = () => {
    let array = files.map(file => {
      return {
        ... file,
        checked: false
      };
    });
    setFiles(array);
  };

  const conditions = () => {
    return !formParameters.cpu || !formParameters.memory || !formParameters.preprocessing || !formParameters.organismType;
  };

  const savePipeline = async () => {
    let message = '';
    message += checkCpuMemory();
    message += checkSequ();
    message += checkRefAss();
    message += checkRefPro();
    message += checkRefTrans();
    if (message.length > 0) {
      Swal.fire({
        title: 'Advertencia',
        text: message,
        icon: 'info'
      });
    } else {
      await executePipeline();
    }
  };

  const executePipeline = async () => {
    setCharging(true);
    let response = await executePipelineService(token, formParameters, files).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al iniciar el proceso',
          icon: 'error'
        });
      }
      cleanForm();
      cleanChecks();
    });
    if (response) {
      cleanForm();
      cleanChecks();
      setCharging(false);
      Swal.fire({
        text: 'Proceso iniciado con éxito',
        icon: 'success'
      });
    }
  };

  const checkCpuMemory = () => {
    let message = '';
    if (parseInt(formParameters.cpu) > 24) {
      message += 'El valor de CPU no puede exceder los 24 cores.\n';
    }
    if (parseInt(formParameters.memory) > 100) {
      message += 'El valor de memoria no puede exceder las 100 GB.\n';
    }
    return message;
  };

  const checkSequ = () => {
    let sequ = files.filter(file => file.file_path.split('/')[0] === 'Sequ' && file.checked).length;
    let message = sequ === 0 ? 'Debe seleccionar al menos 1 archivo sequ.\n' : '';
    return message;
  };

  const checkRefAss = () => {
    let sequ = files.filter(file => file.file_path.split('/')[0] === 'Ref_ass' && file.checked).length;
    let message = sequ !== 1 ? 'Debe elegir exactamente 1 archivo ref_ass.\n' : '';
    return message;
  };

  const checkRefPro = () => {
    let sequ = files.filter(file => file.file_path.split('/')[0] === 'Ref_pro' && file.checked).length;
    let message = sequ > 1 ? 'Debe elegir máximo 1 archivo ref_pro.\n' : '';
    return message;
  };

  const checkRefTrans = () => {
    let sequ = files.filter(file => file.file_path.split('/')[0] === 'Ref_trans' && file.checked).length;
    let message = sequ > 1 ? 'Debe elegir máximo 1 archivo ref_trans\n.' : '';
    return message;
  };

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='row g-4 mt-2'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
          <div id='card-flowchart-genomics-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Lectura de secuencias
              </h1>
            </div>
            <div className='card-body p-0 w-100' ref={containerRef}>
              {containerSize.width && containerSize.height && (
                <ReactFlow
                  style={{ width: containerSize.width, height: containerSize.height }}
                  nodeTypes={nodeTypesGenomics} 
                  defaultNodes={defaultNodesGenomics}
                  defaultEdges={defaultEdgesGenomics}>
                  <Background show={false}/>
                  <Controls showZoom={false} showZoomOut={false} showInteractive={false}/>
                </ReactFlow>
              )}
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6'>
          <div id='card-config-flowchart-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0 position-relative'>
            <div className='card-header border-0 bg-transparent pb-3 pt-3'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Carpetas
              </h1>
              <div className='w-100 mt-3'>
                <ul className="nav nav-pills d-flex flex-row justify-content-center row gx-2" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-40- bs-6-"
                      id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills" type="button" role="tab" aria-controls="pills" aria-selected="false" onClick={() => setCarpetRoot('Sequ')} ><span className="ff-monse-regular-">Sequ</span></button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-40- bs-6-"
                      id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills" type="button" role="tab" aria-controls="pills" aria-selected="false" onClick={() => setCarpetRoot('Ref_ass')} ><span className="ff-monse-regular-">Ref_ass</span></button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-40- bs-6-"
                      id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills" type="button" role="tab" aria-controls="pills" aria-selected="false" onClick={() => setCarpetRoot('Ref_pro')} ><span className="ff-monse-regular-">Ref_pro</span></button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-40- bs-6-"
                      id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills" type="button" role="tab" aria-controls="pills" aria-selected="false" onClick={() => setCarpetRoot('Ref_trans')} ><span className="ff-monse-regular-">Ref_trans</span></button>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card-body p-0 ps-3 pe-3 pt-2 w-100 wrapper-card-folder-'>
              <div className='row'>
                <div className='col-12'>
                  <div className='w-100'>
                    <div className="tab-content w-100" id="contentTables">
                      <div className="tab-pane fade show active w-100" id="pills" role="tabpanel" aria-labelledby="tab" tabIndex="0">
                        <div className='row row-cols-auto d-flex flex-wrap justify-content-center align-items-start align-self-start justify-content-sm-center align-items-sm-start align-self-sm-start justify-content-md-center align-items-md-start align-self-md-start justify-content-lg-start align-items-lg-start align-self-lg-start justify-content-xl-start align-items-xl-start align-self-xl-start justify-content-xxl-start align-items-xxl-start align-self-xxl-start g-4'>
                          {
                            foldersBioinformatic?.filter(folder => folder.tab === carpetRoot).map(folder => (
                              <div className='col-auto d-flex flex-column justify-content-center align-items-center align-self-start' key={folder.id} >
                                <div id="card-folder-internal" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-' onClick={() => setFolderSelected(folder)} >
                                  <div className='card d-flex flex-column justify-content-center align-items-center align-self-center border-0 position-relative overflow-hidden p-3 bs-6-' onClick={() => toggleOverlay('card-folder-and-files-')}>
                                    <div className='container-fluid'>
                                      <div className='row g-2 d-flex flex-row justify-content-start'>
                                        <div className='col-auto me-1'>
                                          <img className='icon-folder-' src={IconFolder} alt="" />
                                        </div>
                                        <div className='col text-wrap wrapper-name-'>
                                          <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-bold text-truncate tx-black-'>{ folder.name }</p>
                                          <p className='m-0 p-0 lh-sm fs-6- ff-monse-regular- fw-bold tx-gray-dark-'>{ folder.filesInside } archivos</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer border-0 bg-transparent pb-3 pt-3">
              <div className='row g-2 d-flex flex-row justify-content-end align-items-start align-self-start'>
                <div className='col-auto'>
                  <button className='btn rounded-4 ps-3 pe-3 ps-sm-3 pe-sm-3 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-bone-white- bs-6-' type="button" data-bs-toggle="offcanvas" data-bs-target="#file-parameters-" aria-controls="file-parameters-">
                    <i class="fa icon-parameters d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none me-0 me-sm-0 me-md-2 me-lg-2 me-xl-2 me-xxl-2"></i><span className='d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block lh-1 fs-5- ff-monse-regular-'>Parámetros</span>
                  </button>
                </div>
                <div className='col-auto'>
                  <button className='btn rounded-4 ps-3 pe-3 ps-sm-3 pe-sm-3 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- bs-6-' type="button" disabled={!formSaved} onClick={savePipeline} >
                    <i class="fa icon-run d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none me-0 me-sm-0 me-md-2 me-lg-2 me-xl-2 me-xxl-2"></i><span className='d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block lh-1 fs-5- ff-monse-regular-'>Correr</span>
                  </button>
                </div>
              </div>
            </div>
            {showOverlay === 'card-folder-and-files-' && (
            <div className={`overlay-wrapper${hiddenOverlay ? ' hidden' : ''}`} onAnimationEnd={() => hiddenOverlay && setHiddenOverlay(true)}>
              <div className={`overlay-content${hiddenOverlay ? ' hidden' : ''}`} onAnimationEnd={() => hiddenOverlay && setHiddenOverlay(true)}>
                <div className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-4 ps-0 pe-0 pt-0 pb-0'>
                  <div className='card-header border-0 bg-transparent pb-3 pt-3'>
                    <div className='d-flex flex-row justify-content-between align-items-center align-self-center'>
                      <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                        Archivos
                      </h1>
                      <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-start align-self-start button-close- btn-bone-white- bs-6- ms-2' onClick={() => toggleOverlay(null)}>
                        <i className='fa icon-close-overlay d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block'></i>
                      </button>
                    </div>
                  </div>
                  <div className='card-body p-0 w-100 wrapper-card-files-'>
                    <div className='table-responsive table-general-'>
                      <table className='table table-sm table-striped table-no-border- align-middle mb-0'>
                        <thead>
                          <tr>
                            <th scope="col" className='th-width-xs-'>
                              <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                              </div>
                            </th>
                            <th scope="col" className='th-width-md-'>
                              <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tipo de archivo</span>
                              </div>
                            </th>
                            <th scope="col" className='th-width-md-'>
                              <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Especie</span>
                              </div>
                            </th>
                            <th scope="col" className='th-width-sm-'>
                              <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Nombre</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            files.filter(file => file.file_path === folderSelected?.folder_path).map(file => (
                              <tr key={file.id}>
                                <td className='align-middle'>
                                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-center'>
                                    <div className='checks-radios-'>
                                      <label>
                                        <input type="checkbox" name="radio" onClick={() => changeCheck(file)} checked={file.checked} />
                                        <span className='lh-sm fs-5- ff-monse-regular- tx-dark-purple-'></span>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td className='align-middle'>
                                  <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ file.seq_type }</p>
                                </td>
                                <td className='align-middle'>
                                  <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ file.specie_name }</p>
                                </td>
                                <td className='align-middle'>
                                  <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ file.name }</p>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer border-0 bg-transparent pb-3 pt-3">
                    {
                      /*
                        <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start mb-2'>
                          <div className='col-auto'>
                            <nav>
                              <ul className="pagination justify-content-center">
                                {startPage !== 1 && (
                                  <li className="page-item">
                                    <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                  </li>
                                )}
                                {startPage > 2 && (
                                  <li className="page-item disabled">
                                    <span className="page-link">...</span>
                                  </li>
                                )}
                                {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                  <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                  </li>
                                ))}
                                {endPage < totalPages - 1 && (
                                  <li className="page-item disabled">
                                    <span className="page-link">...</span>
                                  </li>
                                )}
                                {endPage !== totalPages && (
                                  <li className="page-item">
                                    <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                  </li>
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      */
                    }
                  </div>
                </div>
              </div>
            </div>
            )}
            {showOverlay === 'card-folder-and-files-' && <div className="overlay-backdrop" onClick={() => toggleOverlay(null)} />}

            <div className='offcanvas offcanvas-bottom offcanvas-bottom- container-file-parameters-' tabIndex="-1" data-bs-scroll="true" data-bs-backdrop="false" id="file-parameters-"
              aria-labelledby="file-parameters-">
              <div className='offcanvas-header pt-4 pb-4'>
                <h2 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>Parámetros</h2>
                <button type="button"
                  className='btn-close-offcanvas'
                  data-bs-dismiss="offcanvas">
                  <i className='fa icon-close'></i>
                </button>
              </div>
              <div className='offcanvas-body'>
                <div className='container-fluid p-0'>
                  <div className='row'>
                    <div className='col-12'>
                      <form id='internal-form-inline' action='' className='position-relative'>
                        <div className='row'>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                            <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">CPU</label>
                            <input type="text" className='form-control' placeholder="Maximo 24 Cores" value={formParameters.cpu} onChange={({ target }) => setFormParameters({... formParameters, cpu: target.value})} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                            <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Memoria</label>
                            <input type="text" className='form-control' placeholder="Maximo 100Gb" value={formParameters.memory} onChange={({ target }) => setFormParameters({... formParameters, memory: target.value})} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                            <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Preprocesamiento</label>
                            <Select id='preprocessing' options={Preprocessing} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Selecciona la herramienta" styles={selectStyles} isClearable={true} value={{ value: formParameters.preprocessing, label: formParameters.preprocessing }} onChange={(e) => setFormParameters({ ... formParameters, preprocessing: e?.value ? e.value : '' })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                            <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Tipo de organismo</label>
                            <Select id='assemblerType' options={AssemblerType} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Selecciona el organismo" styles={selectStyles} isClearable={true} value={{ value: formParameters.organismType, label: formParameters.organismType }} onChange={(e) => setFormParameters({ ... formParameters, organismType: e?.value ? e.value : '' })} />
                          </div>
                        </div>
                        <div className='row g-2 d-flex flex-row justify-content-end align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-bone-white- bs-6-' data-bs-dismiss="offcanvas" type='button' onClick={cleanForm} >
                              <span className='lh-1 fs-5- ff-monse-regular-'>Cancelar</span>
                            </button>
                          </div>
                          <div className='col-auto'>
                            <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- bs-6-' data-bs-dismiss="offcanvas" type='button' disabled={conditions()} onClick={() => setFormSaved(true)} >
                              <span className='lh-1 fs-5- ff-monse-regular-'>Guardar</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}