import axios from 'axios';
import { environment } from '../../../../../environments/environment';

const getFoldersUser = async (token, id) => {
  const path = environment.api + environment.getFolders + id;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const postFolder = async (token, pathBody) => {
  const path = environment.api + environment.postFolder;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    folder_path: pathBody
  };
  return await axios.post(path, body, config);
}

const deleteFolderService = async (id, token) => {
  const path = environment.api + environment.deleteFolder;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    id: [id]
  };
  return await axios.post(path, body, config);
};

const getFilesUser = async (token, id) => {
  const path = environment.api + environment.getFiles + id;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const postFile = async (fileForm, token) => {
  const path = environment.api + environment.postFile;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = new FormData();
  body.append('path', fileForm.file);
  body.append('specie_name', fileForm.specie);
  body.append('file_path', `${fileForm.destinyTab}/${fileForm.destinyFolder}`);
  body.append('tech_type', fileForm.tecnology);
  body.append('description', fileForm.description);
  body.append('seq_type', fileForm.fileType);
  return await axios.post(path, body, config);
};

const postFilePaired = async (fileForm, file, token) => {
  const path = environment.api + environment.postFile;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = new FormData();
  body.append('path', file);
  body.append('specie_name', fileForm.specie);
  body.append('file_path', `${fileForm.destinyTab}/${fileForm.destinyFolder}`);
  body.append('tech_type', fileForm.tecnology);
  body.append('description', fileForm.description);
  body.append('seq_type', fileForm.fileType);
  return await axios.post(path, body, config);
};

const deleteFileService = async (id, token) => {
  const path = environment.api + environment.deleteFile;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    id: [id]
  };
  return await axios.post(path, body, config);
};

const getPipelinesAmmountService = async (token) => {
  const path = environment.api + environment.getPipelinesAmmount;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getHardwareUsageService = async (token) => {
  const path = environment.api + environment.getHardwareUsage;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

export { getFoldersUser, postFolder, deleteFolderService, getFilesUser, postFile, postFilePaired, deleteFileService, getPipelinesAmmountService, getHardwareUsageService };
