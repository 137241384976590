import { configuraciones } from "../webConfig";

let server = configuraciones.server;

export const environment = {
  production: false,
  // API
  api: server,

  // LANDING
  postSearchLanding: 'cienciometria/landing-search',

  // AUTENTICACIÓN

  // Register
  postRegister: 'auth/register',

  // Login
  postLogin: 'auth/login',

  // Forgot
  postForgot: 'auth/forgot-password-request',

  // Recovery
  postRecovery: 'auth/recover-password',

  // CIENCIOMETRÍA

  getCards: 'cienciometria/get-tarjetas-cienciometria',

  // Search
  getSearchGraphics: 'cienciometria/search',

  // Gruplac
  getInstitutions: 'cienciometria/get-institucion-select',
  getGroups: 'cienciometria/get-grupo-select',
  getGraphsGruplac: 'cienciometria/get-graficos-group-lac-institucion',
  getTablesGruplac: 'cienciometria/get-tablas-institucion',
  getGraphsGroup: 'cienciometria/get-graficos-group-lac-grupo-institucion',
  getTablesGroup: 'cienciometria/get-tablas-grupo-institucion',

  // Minciencias
  getGeneralGraphics: 'cienciometria/get-graficos-generales',
  getTableGroups: 'cienciometria/get-tabla-info-grupos-institucion',
  getTableGeneral: 'cienciometria/get-tabla-info-general-articulos',
  getTableInstitutions: 'cienciometria/get-top-10-instituciones-minciencias',

  // Sib Colombia
  getGraphicsSib: 'cienciometria/get-graficos-sib',

  // BIOINFORMÁTICA

  // Resultados
  getResults: 'bioinformatica/get-pipelines-by-user',
  postCancelPipeline: 'bioinformatica/cancel-pipelines',
  getFileResult: 'bioinformatica/download-results-by-job-id/',

  // Archivos
  postFile: 'bioinformatica/upload-file',
  getFiles: 'bioinformatica/get-files/',
  deleteFile: 'bioinformatica/delete-files',

  // Carpetas
  getFolders: 'bioinformatica/retrieve-folders-by-user-id/',
  postFolder: 'bioinformatica/create-folder',
  deleteFolder: 'bioinformatica/delete-folder',

  // Pipelines
  getPipelinesAmmount: 'bioinformatica/get-running-pipelines-amount',
  getHardwareUsage: 'bioinformatica/get-hardware-usage-by-user',

  // Genómica
  postExecutePipeline: 'bioinformatica/execute-pipeline',

  // Bases de datos
  getDatabase: 'bioinformatica/get-all-pipelines',
  getFileDatabase: 'bioinformatica/download-results-database/'
};