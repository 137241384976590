import axios from 'axios';
import { environment } from '../../../environments/environment';

const postRecovery = async (user, code) => {
  const path = environment.api + environment.postRecovery;
  let config = {};
  let body = {
    reset_code: code,
    password: user.password
  };
  return await axios.post(path, body, config);
};

export { postRecovery };
