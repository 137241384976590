import axios from 'axios';
import { environment } from '../../../../environments/environment';

const getOptionsInstitutions = async (token) => {
  const path = environment.api + environment.getInstitutions;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getOptionsGroups = async (institution, token) => {
  const path = environment.api + environment.getGroups;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    institucion: institution
  };
  return await axios.post(path, body, config);
};

const getGraphsGrup = async (institution, token) => {
  const path = environment.api + environment.getGraphsGruplac;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    institucion: institution
  };
  return await axios.post(path, body, config);
};

const getTablesGrup = async (institution, token) => {
  const path = environment.api + environment.getTablesGruplac;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    institucion: institution
  };
  return await axios.post(path, body, config);
};

const getGraphsGroupInstitution = async (group, token) => {
  const path = environment.api + environment.getGraphsGroup;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    institucion: group.institution,
    codigo_grupo: group.code
  };
  return await axios.post(path, body, config);
};

const getTablesGroupInstitution = async (group, token) => {
  const path = environment.api + environment.getTablesGroup;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    institucion: group.institution,
    codigo_grupo: group.code
  };
  return await axios.post(path, body, config);
};

export { getOptionsInstitutions, getOptionsGroups, getGraphsGrup, getTablesGrup, getGraphsGroupInstitution, getTablesGroupInstitution };
