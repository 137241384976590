import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import { Authroutes } from '../auth/AuthRoutes'
import Landing  from '../landing/Landing'
import { DashboardRoutes } from '../dashboard/DashboardRoutes'
import { AppProvider } from '../../appContext'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'
import { OtherRoutes } from './OtherRoutes'

export const Index = () => {
  return (
    <React.Fragment>
      <AppProvider>
        <Routes>
          <Route path='/auth/*' element={
            <PublicRoutes>
                <Authroutes />
            </PublicRoutes>
          } />
          <Route path='/landing' element={
            <PublicRoutes>
                <Landing />
            </PublicRoutes>
          } />
          <Route path='/dashboard/*' element={
            <PrivateRoutes>
                <DashboardRoutes />
            </PrivateRoutes>
          } />
          <Route path='/' element={
            <OtherRoutes>
                <Landing />
            </OtherRoutes>
          } />
          <Route path='/*' element={
            <OtherRoutes>
              <DashboardRoutes />
            </OtherRoutes>
          } />
        </Routes>
      </AppProvider>
    </React.Fragment>
  )
}
