import axios from 'axios';
import { environment } from '../../../../../environments/environment';

const executePipelineService = async (token, form, files) => {
  const path = environment.api + environment.postExecutePipeline;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let ref_ass = files.find(file => file.file_path.split('/')[0] === 'Ref_ass' && file.checked);
  let ref_pro = files.find(file => file.file_path.split('/')[0] === 'Ref_pro' && file.checked);
  let ref_trans = files.find(file => file.file_path.split('/')[0] === 'Ref_trans' && file.checked);
  let body = {    
    cpu: form.cpu,
    mem: form.memory,
    analysis: 'transcriptomics',
    sequences: files.filter(file => file.file_path.split('/')[0] === 'Sequ' && file.checked).map(file => file.id),
    reference_assembly: ref_ass ? ref_ass.id : null, 
    reference_proteins:  ref_pro ? ref_pro.id : null, 
    reference_transcripts: ref_trans.id,
    functional_tool:  '',    
    assembler: 'trinity',
    preprocessing_tool: form.preprocessing
  };
  return await axios.post(path, body, config);
};

export { executePipelineService };
