import './Dashboard.css';
import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import Sidebar from '../shared/sidebar/Sidebar';
import Navbar from '../shared/navbar/Navbar';
import ScientometricRoutes from './scientometric/ScientometricRoutes';
import BioinformaticRoutes from './bioinformatic/BioinformaticRoutes';
import UserManagement from './userManagement/UserManagement';
import $ from "jquery"

export const DashboardRoutes = () => {
  $(document).ready(function () {
    $('#sidebarCollapse').on('click', function () {
      $('#sidebar-, #content-').toggleClass('active');
    });
    $('#close-sidebar-').on('click', function() {
      $('#sidebar-, #content-').toggleClass('active');
    });
  });
  return (
    <React.Fragment>
      <div className='container-fluid vw-100 overflow-x-hidden p-0 min-h-'>
        <div className='wrapper-sidebar- overflow-x-hidden'>
          <Sidebar></Sidebar>
          <div id="content-" className='bg-3bio-dark-'>
            <Navbar></Navbar>
            <Routes>
              <Route path="" element={ <Navigate to="scientometric" /> }/>
              <Route path="/scientometric/*" element={<ScientometricRoutes/>} />
              <Route path="/bioinformatic/*" element={<BioinformaticRoutes/>} />
              <Route path="userManagement" element={<UserManagement/>} />
            </Routes>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
