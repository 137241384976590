import axios from 'axios';
import { environment } from '../../../../environments/environment';

const getGraphicsGeneralSib = async (token) => {
  const path = environment.api + environment.getGraphicsSib;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

export { getGraphicsGeneralSib };
