import './DatabasesBioinformatic.css'
import IconDatabases from '../../../../assets/images/icon-databases.svg';
import IconSearch from '../../../../assets/images/icon-search.svg';
import React, { useContext, useEffect, useState } from 'react'
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
import Preloader from '../../../shared/preloader/Preloader';
import { getDatabaseResults, getDownloadFile } from '../../../../services/dahsboard/bioinformatic/pipelines/database/database';
import { Appcontext } from '../../../../appContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
require('jquery-mousewheel');

export default function DatabasesBioinformatic() {
  const { token, setToken } = useContext(Appcontext);
  const navigate = useNavigate();
  const [charging, setCharging] = useState(false);
  const [search, setSearch] = useState('');
  const [dataResult, setDataResult] = useState(null);

  useEffect(() => {
    configurations();
    getData();
  }, []);

  const configurations = () => {
    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });
  };

  const getData = async () => {
    setCharging(true);
    let response = await getDatabaseResults(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener la información',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setDataResult(data);
      setCharging(false);
    }
  };

  const downloadFile = async (row) => {
    setCharging(true);
    let response = await getDownloadFile(row.id, token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No existe archivo',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      const blob = new Blob([data], { type: 'text/plain' });
      const url = window.URL.createObjectURL(blob);
      const enlaceDescarga = document.createElement('a');
      enlaceDescarga.href = url;
      enlaceDescarga.download = 'file.out';
      enlaceDescarga.click();
      window.URL.revokeObjectURL(url);
      setCharging(false);
    }
  };

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  return (
    <React.Fragment>
      {
        charging
          ?
          <Preloader></Preloader>
          :
          null
      }
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-databases-' src={IconDatabases} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Bases de datos
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  A partir de la ejecución de los flujos de análisis bioinformáticos se generan ensamblajes y anotaciones, en este módulo se pretende almacenar de forma temporal la información generada por los investigadores del país.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-3 pt-3'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                <div className='col d-flex flex-column'>
                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                      Datos registrados
                    </h1>
                  </div>
                  <div className='w-auto mt-3 '>
                    <form action="" className='position-relative wrapper-search-small- me-3'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={search} onChange={({ target }) => setSearch(target.value) } />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                        <div className='card-header border-0 bg-transparent pb-0'>
                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                        </div>
                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                            # <span>{ dataResult?.length }</span>
                          </p>
                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                            Registros
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-0 w-100 wrapper-data-'>
              <div className='table-responsive table-general-'>
                <table className='table table-sm table-striped table-no-border- align-middle'>
                  <thead>
                    <tr>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Especie</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Análisis</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tamaño del reporte</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tamaño del genoma</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Estado</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Acciones</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataResult?.filter(table => {
                        if (search !== '') {
                          let keys = Object.keys(table);
                          let flagKeys = false;
                          let i = 0;
                          while (i < keys.length && !flagKeys) {
                            if (keys[i] === 'specie_name' || keys[i] === 'status' || keys[i] === 'genome_size' || keys[i] === 'report_size' || keys[i] === 'analysis') {
                              let word = '' + table[keys[i]];
                              let words = word.split(' ');
                              let j = 0;
                              flagKeys = word.toUpperCase().includes(search.toUpperCase());
                              while (j < words.length && !flagKeys) {
                                if (words[j].toUpperCase().includes(search.toUpperCase())) {
                                  flagKeys = true;
                                }
                                j++;
                              }
                              if (search.toUpperCase() === word.substring(0, search.length).toUpperCase()) {
                                flagKeys = true;
                              }
                            }
                            if (flagKeys) {
                              return true;
                            }
                            i++;
                          }

                        } else {
                          return true;
                        }
                      }).map(row => (
                        <tr key={row.id}>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.specie_name }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.analysis }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.report_size }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.genome_size }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.status }</p>
                          </td>
                          <td className='align-middle'>
                            <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                              <div className='col-auto'>
                                <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" onClick={() => downloadFile(row)} >
                                  <i className='fa icon-download fs-xxs'></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer border-0 bg-transparent pb-3 pt-3">
              {
                /*
                  <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                    <div className='col-auto'>
                      <nav>
                        <ul className="pagination justify-content-center">
                          {startPage !== 1 && (
                            <li className="page-item">
                              <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                            </li>
                          )}
                          {startPage > 2 && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                            <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                              <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                            </li>
                          ))}
                          {endPage < totalPages - 1 && (
                            <li className="page-item disabled">
                              <span className="page-link">...</span>
                            </li>
                          )}
                          {endPage !== totalPages && (
                            <li className="page-item">
                              <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>                
                */
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
