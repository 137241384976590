import IconScientometric from '../../../../assets/images/icon-scientometric.svg';
import React, { useEffect } from 'react'
import * as echarts from 'echarts';
import $ from "jquery"
import { useNavigate } from 'react-router-dom';

export default function ScientometricPreview(props) {

  const navigate = useNavigate();
  
  useEffect(() => {
    if (props.infoGraph) {
      configurations(props.infoGraph);
    }
  }, [props]);

  const redirectToLogin = () => {
    let button = document.getElementById('button-close-off-canvas');
    button.click();
    navigate('/auth/login');
  };

  const configurations = (info) => {
    /**
     * Grafica Evolución temporal de las publicaciones (LINE CHART)
     */

    let chartEvolution = info.find(chart => chart.nombre === 'evolucion_temporal_search');
    let chartScientometricsOne = echarts.init(document.getElementById('chart-scientometrics-one-'));
    let optionScientometricsOne;

    optionScientometricsOne = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#040E29',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 50,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Publicaciones',
            backgroundColor: '#FAFAFA',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 35,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartEvolution.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'Publicaciones',
        nameLocation: 'middle',
        nameGap: 45,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
                show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartEvolution.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionScientometricsOne && chartScientometricsOne.setOption(optionScientometricsOne);

    $(window).on('resize', function(){
      if(chartScientometricsOne != null && chartScientometricsOne !== undefined){
        chartScientometricsOne.resize();
      }
    });

    /**
     * Grafica Ranking de Instituciones (BAR CHART)
     */

    let chartScientometricsTwo = echarts.init(document.getElementById('chart-scientometrics-two-'));
    let optionScientometricsTwo;

    optionScientometricsTwo = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#040E29',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 50,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#040E29',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Ranking de instituciones',
            backgroundColor: '#FAFAFA',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: 12755,
        max: 101852,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      dataset: {
        source: [
          ['amount', 'product'],
          [58212, 'Instituto de investigación de recursos'],
          [78254, 'Instituto de investigación del pacífico'],
          [41032, 'Parques nacionales naturales'],
          [12755, 'Universidad pedagógica y tecnológica']
        ]
      },
      grid: [
        {
          containLabel: true,
          right: '21%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 35,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Organización',
        nameLocation: 'middle',
        nameGap: 75,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 6;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,6)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: ['Instituto de investigación de recursos', 'Instituto de investigación del pacífico', 'Parques nacionales naturales', 'Universidad pedagógica y tecnológica']
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionScientometricsTwo && chartScientometricsTwo.setOption(optionScientometricsTwo);

    $(window).on('resize', function(){
      if(chartScientometricsTwo != null && chartScientometricsTwo !== undefined){
        chartScientometricsTwo.resize();
      }
    });

  };
  
  return (
    <React.Fragment>
      <div className='offcanvas offcanvas-end offcanvas-end-' tabIndex="-1" data-bs-backdrop="false" id="scientometric-preview"
        aria-labelledby="offcanvasTopLabel" data-bs-scroll="false">
        <div className='offcanvas-header pt-5 pb-4'>
          <button type="button"
            className='btn-close-offcanvas'
            data-bs-dismiss="offcanvas"
            id='button-close-off-canvas'>
            <i className='fa icon-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='w-auto'>
                <h2 className='m-0 p-0 lh-sm fs-3 tx-white- ff-monse-bold- title-'>Bases de datos</h2>
              </div>
            </div>
            <div className='row g-3 mt-3'>
              <div className='col-12 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7'>
                <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                  <div className='card-header border-0 bg-transparent pb-0'>
                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-white-'>Articulos - capitulos - libros</h1>
                  </div>
                  <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                    <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                      # <span>{ props.infoInstitutions?.valores }</span>
                    </p>
                    <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                      Registros
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
                <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                  <div className='card-header border-0 bg-transparent pb-0'>
                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-white-'>Instituciones</h1>
                  </div>
                  <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                    <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                      + <span>{ props.infoArticles?.valores }</span>
                    </p>
                    <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                      Registros
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                <div id="wrapper-graphics-small-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                  <div className='card-header border-0 bg-transparent pb-0'>
                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-white-'>Publicaciones</h1>
                    <p className='m-0 p-0 pt-1 lh-sm fs-7- ff-monse-light- fw-normal tx-white-'>Articulos - capitulos - libros</p>
                  </div>
                  <div className='card-body p-0 ps-2 pe-2 w-100'>
                    <div className='w-100 h-100 mx-auto' id='chart-scientometrics-one-'></div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                <div id="wrapper-graphics-small-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                  <div className='card-header border-0 bg-transparent pb-0'>
                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-white-'>Ranking de instituciones</h1>
                    <p className='m-0 p-0 pt-1 lh-sm fs-7- ff-monse-light- fw-normal tx-white-'>Cantidad de publicaciones</p>
                  </div>
                  <div className='card-body p-0 ps-2 pe-2 w-100'>
                    <div className='w-100 h-100 mx-auto' id='chart-scientometrics-two-'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-scientometric-' src={IconScientometric} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Cienciometría
                </h1>
              </div>
              <div className='w-100 mt-2'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- tx-white-'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Non error distinctio, molestias tempora pariatur eos, aut maxime veniam nesciunt voluptatem magnam.
                </p>
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-50- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light-' type="button" onClick={redirectToLogin}>
                  <span className='lh-1 fs-4- ff-monse-regular- fw-bold'>Ver más datos</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}