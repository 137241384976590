import axios from 'axios';
import { environment } from '../../../../../environments/environment';

const getDataResults = async (token) => {
  const path = environment.api + environment.getResults;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const postCancelResults = async (results, token) => {
  const path = environment.api + environment.postCancelPipeline;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let ids = results.filter(result => result.checked).map(result => result.id);
  let body = {
    id: ids
  };
  return await axios.post(path, body, config);
};

const getDownloadFile = async (id, token) => {
  const path = environment.api + environment.getFileResult + id;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    responseType: 'blob'
  };
  return await axios.get(path, config);
};

export { getDataResults, postCancelResults, getDownloadFile };
