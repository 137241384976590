import './ToolsBioinformatic.css'
import IconTools from '../../../../../assets/images/icon-tools.svg';
import IconMachine from '../../../../../assets/images/icon-machine.svg';
import React from 'react'
import { Navigate, NavLink, Route, Routes } from "react-router-dom"
import Genomics from './genomics/Genomics';
import Transcriptomics from './transcriptomics/Transcriptomics';

export default function ToolsBioinformaticRoutes() {
  return (
    <React.Fragment>
      <div className='container-fluid overflow-x-hidden p-0'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
            <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
              <div className='col d-flex flex-column'>
                <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                  <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                    <img className='icon-tools-' src={IconTools} alt="" />
                  </div>
                  <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                    Herramientas
                  </h1>
                </div>
                <div className='w-100 mt-4'>
                  <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                    Para llevar a cabo los análisis bioinformáticos se requiere la integración de algoritmos, bases de datos y softwares desarrollados para facilitar el almacenamiento, el análisis y la visualización de datos. Se pone a disposición del usuario dos grandes módulos: genómica y transcriptómica, dispuestos de forma sistemática y compuestos por diversas herramientas para el análisis de principio a fin de los datos ingresados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-column flex-xxl-row justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-xxl-between align-items-center align-self-center mt-4'>
          <div className='col-auto'>
            <div className='row g-3'>
              <div className='col-auto'>
                <NavLink className='nav-link rounded-pill rounded-4 ps-4 pe-4 h-50- d-flex flex-row justify-content-center align-items-center align-self-center bs-6-' style={({ isActive }) => ({ color: isActive ? 'var(--color-black-)' : 'var(--color-black-)', background: isActive ? 'var(--color-green-)' : 'var(--color-white-)', })} type="button" to='/dashboard/bioinformatic/pipelines/toolsBioinformatic/genomics'>
                  <i className='fa icon-genomics me-2'></i>
                  <span className='lh-1 fs-5- ff-monse-regular- fw-bold'>Genómica</span>
                </NavLink>
              </div>
              <div className='col-auto'>
                <NavLink className='nav-link rounded-pill rounded-4 ps-4 pe-4 h-50- d-flex flex-row justify-content-center align-items-center align-self-center bs-6-' style={({ isActive }) => ({ color: isActive ? 'var(--color-black-)' : 'var(--color-black-)', background: isActive ? 'var(--color-green-)' : 'var(--color-white-)', })} type="button" to='/dashboard/bioinformatic/pipelines/toolsBioinformatic/transcriptomics'>
                  <i className='fa icon-transcriptomics me-2'></i>
                  <span className='lh-1 fs-5- ff-monse-regular- fw-bold'>Transcriptómica</span>
                </NavLink>
              </div>
            </div>
          </div>
          <div className='col-auto mt-4 mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 mt-xxl-0'>
            <div className='row gx-3 d-flex flex-row justify-content-center align-items-center align-self-center rounded-4 pt-2 pb-2 pt-sm-2 pb-sm-2 pt-md-2 pb-md-2 pt-lg-2 pb-lg-2 pt-xl-2 pb-xl-2 pt-xxl-0 pb-xxl-0 ps-5 pe-5 bg-blue-translucent- glass-effect- bs-4- wrapper-card-global-count-'>
              <div className='col-auto me-2'>
                <img className='icon-machine-consumptions-' src={IconMachine} alt="" />
              </div>
              <div className='col-auto'>
                <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white- fw-bold'>Corriendo</p>
                <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-green-light- fw-bold'>0</p>
              </div>
              <div className='col-auto'>
                <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white- fw-bold'>Horas</p>
                <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-green-light- fw-bold'>100</p>
              </div>
              <div className='col-auto'>
                <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white- fw-bold'>Memoria</p>
                <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-green-light- fw-bold'>500</p>
              </div>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="" element={ <Navigate to="genomics" /> }/>
          <Route path="genomics" element={<Genomics/>} />
          <Route path="transcriptomics" element={<Transcriptomics/>} />
        </Routes>
      </div>
    </React.Fragment>
  )
}
