import axios from 'axios';
import { environment } from '../../../environments/environment';

const postLogin = async (user) => {
  const path = environment.api + environment.postLogin;
  let config = {};
  let body = new FormData();
  body.append('username', user.user);
  body.append('password', user.password);
  return await axios.post(path, body, config);
};

export { postLogin };
