import './UserManagement.css'
import IconUserManagement from '../../../assets/images/icon-user-management.svg';
import IconSearch from '../../../assets/images/icon-search.svg';
import React, { useEffect, useState  } from 'react'
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
require('jquery-mousewheel');

/**
 * MENSAJES PERSONALIZADOS AL BUSCAR O CARGAR OPCIONES EN REACT SELECT
 */

const { NoOptionsMessage } = components;

const customNoOptionsMessage = props => (
  <NoOptionsMessage {...props}>No registrado</NoOptionsMessage>
);

const { LoadingMessage } = components;

const customLoadingMessage = props => (
  <LoadingMessage {...props}>Cargando</LoadingMessage>
);

/**
 * ANIMATE DELETE MULTISELECT
 */

const animatedComponents = makeAnimated();

/**
 * Data que llena los select
 */

const CustomSelect = [
  { value: "opcion-uno", label: "Opcion uno" },
  { value: "opcion-dos", label: "Opcion dos" },
  { value: "opcion-tres", label: "Opcion tres" }
];

/**
 * Constante que soporta todo el cambio de los estilo del select
 */

const selectStyles = {
  /**
 * Estilos del icono del dropdown del select
 * Estilos del separador del select
 * Estilos del icono de cerrar del select
 */
  dropdownIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important', 
    paddingRight: '0.5rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  clearIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  /**
 * Estilos del input global
 */
  control: () => ({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    alignSelf: "start",
    justifyContent: "start",
    height: 'auto',
    minHeight: 50,
    maxHeight: 150,
    paddingLeft: '15px',
    paddingTop: '0.3rem',
    width: "100%",
    backgroundColor: 'var(--color-quaternary-blue-)',
    borderRadius: "1.18rem",
    border: "0px solid transparent",
  }),
  /**
* EESTILOS DEL INPUT
*/
  input: (provided) => ({
    ...provided,
    color: 'var(--color-white-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  /**
 * Estilos del menu desplegable del select
 */
  menu: (styles) => ({
    ...styles,
    border: 'none',
    borderColor: 'var(--color-quaternary)',
    backgroundColor: 'var(--color-tertiary-white-rgba-)',
    boxShadow: 'var(--box-shadow-12-)',
    borderRadius: '1rem',
    padding: 0,
    marginTop: 8,
    marginBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "hidden",
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent !important"
    }
  }),
  /**
 * Estilos de las opciones desplegables
 */
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: 'var(--color-gray-)',
    backgroundColor: state.isSelected ? "var(--color-sextary-white-)" : "var(--color-tertiary-white-rgba-)",
    fontFamily: 'var(--font-family-regular-)',
    padding: '0.5rem 0.8rem 0.5rem 0.8rem',
    borderRadius: '1rem',
    ":hover": {
      background: "var(--color-sextary-white-)",
      color: 'var(--color-black-)',
    }
  }),
  /**
 * Estilos del contenedor
 */
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    position: 'relative',
    flex: '1 1 auto'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    paddingTop: '0rem !important',
    marginLeft: '0px',
    paddingRight: 0,
    padding: '0px 0px'
  }),
  /**
 * Estilos placeholder del input
 */
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
    position: "absolute",
    color: 'var(--color-white-)',
    lineHeight: 1.25,
    fontFamily: 'var(--font-family-regular-)',
    overflow: 'hidden',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  /**
 * Estilos texto en el input
 */
  singleValue: (styles) => ({ 
    ...styles, 
    fontSize: 14,
    color: "var(--color-white-)", 
    fontFamily: 'var(--font-family-regular-)', 
    padding: '3px',
    margin: '0px',
    marginTop: '2px',
    marginLeft: 0,
    marginRight: 0
  })
}

export default function UserManagement() {

  useEffect(()=>{

    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

  },[])

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-user-management-' src={IconUserManagement} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Gestión de usuarios
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  Aquí podras llevar a cabo el registro de los usuarios como tambien poder editarlo y darle permisos de acceso a la plataforma de 3BIO.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-3 pt-3'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                <div className='col d-flex flex-column'>
                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                      Usuarios registrados
                    </h1>
                  </div>
                  <div className='w-auto mt-3 '>
                    <form action="" className='position-relative wrapper-search-small- me-3'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                        <div className='card-header border-0 bg-transparent pb-0'>
                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                        </div>
                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                            # <span>780</span>
                          </p>
                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                            Registros
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-0 w-100 wrapper-data-'>
              <div className='table-responsive table-general-'>
                <table className='table table-sm table-striped table-no-border- align-middle'>
                  <thead>
                    <tr>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>#</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Nombre</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Apellido</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Correo</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Teléfono</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Entidad</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Rol</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Acceso</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Acciones</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Jonathan</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Ospina Garcia</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>jonathan.ospina@bios.co</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>3165497426</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Centro de bioinformatica y biologia computacional</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Administrador</p>
                      </td>
                      <td className='align-middle'>
                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Activado</p>
                      </td>
                      <td className='align-middle'>
                        <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#edit-user" aria-controls="edit-user">
                              <i className='fa icon-edit fs-xxs'></i>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer border-0 bg-transparent pb-3 pt-3">
              <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                <div className='col-auto'>
                  <nav>
                    <ul className="pagination justify-content-center">
                      {startPage !== 1 && (
                        <li className="page-item">
                          <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                        </li>
                      )}
                      {startPage > 2 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                        </li>
                      ))}
                      {endPage < totalPages - 1 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                      {endPage !== totalPages && (
                        <li className="page-item">
                          <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='offcanvas offcanvas-bottom offcanvas-bottom-' tabIndex="-1" data-bs-backdrop="false" id="add-user"
        aria-labelledby="add-user" data-bs-scroll="false">
        <div className='offcanvas-header pt-4 pb-4'>
          <h2 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-green-light-'>Agregar usuario</h2>
          <button type="button"
            className='btn-close-offcanvas'
            data-bs-dismiss="offcanvas">
            <i className='fa icon-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <form id='internal-form-inline' action='' className='position-relative'>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Nombre</label>
                      <input type="text" className='form-control' placeholder="Ingrese su nombre" />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Apellido</label>
                      <input type="text" className='form-control' placeholder="Ingrese su apellido" />
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Correo</label>
                      <input type="email" className='form-control' placeholder="Ingrese su correo" />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Teléfono</label>
                      <input type="tel" className='form-control' placeholder="Ingrese su teléfono" />
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Entidad</label>
                      <Select id='customSelect' options={CustomSelect} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Selecciona la entidad" styles={selectStyles} isClearable={true}/>
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <div className='inner-addon- right-addon-'>
                        <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Contraseña</label>
                        <input type="password" className='form-control' placeholder="Ingrese su contraseña" />
                        <i className='fa icon-hide-password fs-xs'></i>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <div className='inner-addon- right-addon-'>
                        <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Repetir contraseña</label>
                        <input type="password" className='form-control' placeholder="Ingrese su contraseña" />
                        <i className='fa icon-hide-password fs-xs'></i>
                      </div>
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Rol</label>
                      <Select id='customSelect' options={CustomSelect} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Selecciona el rol" styles={selectStyles} isClearable={true}/>
                    </div>
                  </div>
                  <div className='row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mt-3'>
                    <div className='col-12 d-flex flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-column flex-xxl-column justify-content-between align-items-center align-self-center mb-2'>
                      <p className='m-0 me-0 me-sm-3 me-md-3 me-lg-3 me-xl-3 me-xxl-3 mb-3 lh-sm text-center fs-5- ff-monse-regular- fw-normal tx-white-'>¿Desea activar este usuario <br /> <span className='fs-6-'>(Si su selección es <strong>"Activarlo"</strong> el usuario tendra acceso a la plataforma)</span></p>
                      <div className='d-flex flex-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start align-items-center align-self-center'>
                        <div className='checks-radios- me-1'>
                          <label>
                            <input type="radio" name="radio"/>
                            <span className='lh-sm fs-5- ff-monse-regular-'>Activar</span>
                          </label>
                        </div>
                        <div className='checks-radios- me-1'>
                          <label>
                            <input type="radio" name="radio"/>
                            <span className='lh-sm fs-5- ff-monse-regular-'>Desactivar</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row gx-2 d-flex flex-row justify-content-end align-items-start align-self-start'>
                    <div className='col-auto'>
                      <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- bs-6-' type="button">
                        <span className='lh-1 fs-5- ff-monse-regular-'>Guardar</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='offcanvas offcanvas-bottom offcanvas-bottom-' tabIndex="-1" data-bs-backdrop="false" id="edit-user"
        aria-labelledby="edit-user" data-bs-scroll="false">
        <div className='offcanvas-header pt-4 pb-4'>
          <h2 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-green-light-'>Editar usuario</h2>
          <button type="button"
            className='btn-close-offcanvas'
            data-bs-dismiss="offcanvas">
            <i className='fa icon-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <form id='internal-form-inline' action='' className='position-relative'>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Nombre</label>
                      <input type="text" className='form-control' placeholder="Ingrese su nombre" />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Apellido</label>
                      <input type="text" className='form-control' placeholder="Ingrese su apellido" />
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Correo</label>
                      <input type="email" className='form-control' placeholder="Ingrese su correo" />
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Teléfono</label>
                      <input type="tel" className='form-control' placeholder="Ingrese su teléfono" />
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Entidad</label>
                      <Select id='customSelect' options={CustomSelect} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Selecciona la entidad" styles={selectStyles} isClearable={true}/>
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <div className='inner-addon- right-addon-'>
                        <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Contraseña</label>
                        <input type="password" className='form-control' placeholder="Ingrese su contraseña" />
                        <i className='fa icon-hide-password fs-xs'></i>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <div className='inner-addon- right-addon-'>
                        <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Repetir contraseña</label>
                        <input type="password" className='form-control' placeholder="Ingrese su contraseña" />
                        <i className='fa icon-hide-password fs-xs'></i>
                      </div>
                    </div>
                  </div>
                  <div className='row row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mb-3'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                      <label className="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Rol</label>
                      <Select id='customSelect' options={CustomSelect} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Selecciona el rol" styles={selectStyles} isClearable={true}/>
                    </div>
                  </div>
                  <div className='row g-0 g-sm-0 g-md-2 g-lg-2 g-xl-2 g-xxl-2 mt-3'>
                    <div className='col-12 d-flex flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-column flex-xxl-column justify-content-between align-items-center align-self-center mb-2'>
                      <p className='m-0 me-0 me-sm-3 me-md-3 me-lg-3 me-xl-3 me-xxl-3 mb-3 lh-sm text-center fs-5- ff-monse-regular- fw-normal tx-white-'>¿Desea activar este usuario <br /> <span className='fs-6-'>(Si su selección es <strong>"Activarlo"</strong> el usuario tendra acceso a la plataforma)</span></p>
                      <div className='d-flex flex-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xl-start justify-content-xxl-start align-items-center align-self-center'>
                        <div className='checks-radios- me-1'>
                          <label>
                            <input type="radio" name="radio"/>
                            <span className='lh-sm fs-5- ff-monse-regular-'>Activar</span>
                          </label>
                        </div>
                        <div className='checks-radios- me-1'>
                          <label>
                            <input type="radio" name="radio"/>
                            <span className='lh-sm fs-5- ff-monse-regular-'>Desactivar</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row gx-2 d-flex flex-row justify-content-end align-items-start align-self-start'>
                    <div className='col-auto'>
                      <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- bs-6-' type="button">
                        <span className='lh-1 fs-5- ff-monse-regular-'>Guardar</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center float-button- btn-bone-white- bs-6- mt-1 me-1' type="button" data-bs-toggle="offcanvas" data-bs-target="#add-user" aria-controls="add-user">
        <i className='fa icon-add d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block'></i>
      </button>
    </React.Fragment>
  )
}
