import './MachineConsumptions.css'
import IconPipelines from '../../../../../assets/images/icon-pipeline.svg';
import IconArchive from '../../../../../assets/images/icon-archive.svg';
import IconTools from '../../../../../assets/images/icon-tools.svg';
import IconResults from '../../../../../assets/images/icon-results.svg';
import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate} from "react-router-dom"
import * as echarts from 'echarts';
import Swiper, { Pagination, Manipulation, FreeMode } from 'swiper';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import $ from "jquery"
import { getHardwareUsageService } from '../../../../../services/dahsboard/bioinformatic/pipelines/filesBioinformatic/filesBioinformatic';
import { Appcontext } from '../../../../../appContext';
import Swal from 'sweetalert2';
import Preloader from '../../../../shared/preloader/Preloader';

export default function MachineConsumptions() {
  const { token, setToken } = useContext(Appcontext);
  const navigate = useNavigate();
  const [hardware, setHardware] = useState(null);
  const [charging, setCharging] = useState(false);

  useEffect(() => {
    /**
     * Swiper para mostrar la navegación del modulo de pipelines
     */

    new Swiper('.swiper-container-navigation-', {
      modules: [Manipulation, FreeMode, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 20,
      grabCursor: true,
      breakpoints: {
        320: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        576: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        768: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        992: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1400: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
      }
    });

    getService();
  }, []);

  const getService = async () => {
    await getHardwareUsage();
  };

  const getHardwareUsage = async () => {
    setCharging(true);
    let response = await getHardwareUsageService(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setHardware(data);
      setCharging(false);
    }
  };

  return (
    <React.Fragment>
      {
        charging
          ?
          <Preloader></Preloader>
          :
          null
      }
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-pipelines-' src={IconPipelines} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Flujos de trabajo
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  Encontrarás herramientas usadas en las áreas de genómica y transcriptómica. La primera área mencionada se dedica al estudio del conjunto completo del material genético de un organismo: el genoma. La segunda área está involucrada en el estudio del conjunto completo de moléculas de ARN que son producidas por una célula o tejido en un momento de tiempo dado. Cada flujo de trabajo se construyó de forma modular, pensando en los pasos que se deben seguir para llevar a cabo una tarea. Para ambas áreas, se incluyen análisis de principio a fin desde el preprocesamiento de secuencias crudas hasta la anotación de los genes para genómica y perfiles de genes diferencialmente expresados y la anotación de los transcritos para transcriptómica. Ambos flujos de trabajo concluyen en archivos de salida útiles para la interpretación de tus análisis de interés.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row row-cols-auto d-flex flex-wrap justify-content-center align-items-center align-self-center mt-4'>
        <div className='col-12'>
          <div className="swiper swiper-container-navigation-">
            <div className="swiper-wrapper">
              <div className='swiper-slide d-flex flex-row justify-content-center align-items-start align-self-start'>
                <NavLink className='nav-link' to='/dashboard/bioinformatic/pipelines/filesBioinformatic/'>
                  <div className='d-flex flex-column justify-content-center align-items-center align-self-start wrapper-card-navigation-'>
                    <div id="card-navigation-" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                      <div className='card border-0 position-relative overflow-hidden bs-5-'>
                        <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                          <img src={IconArchive} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className='w-auto mt-3'>
                      <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-normal text-center'>Archivos</p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='swiper-slide d-flex flex-row justify-content-center align-items-start align-self-start'>
                <NavLink className='nav-link' to='/dashboard/bioinformatic/pipelines/toolsBioinformatic/'>
                  <div className='d-flex flex-column justify-content-center align-items-center align-self-start wrapper-card-navigation-'>
                    <div id="card-navigation-" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                      <div className='card border-0 position-relative overflow-hidden bs-5-'>
                        <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                          <img src={IconTools} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className='w-auto mt-3'>
                      <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-normal text-center'>Herramientas</p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='swiper-slide d-flex flex-row justify-content-center align-items-start align-self-start'>
                <NavLink className='nav-link' to='/dashboard/bioinformatic/pipelines/results/'>
                  <div className='d-flex flex-column justify-content-center align-items-center align-self-start wrapper-card-navigation-'>
                    <div id="card-navigation-" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                      <div className='card border-0 position-relative overflow-hidden bs-5-'>
                        <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                          <img className='icon-results' src={IconResults} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className='w-auto mt-3'>
                      <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-normal text-center'>Resultados</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row row-cols-auto g-4 mt-0'>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-consumtions-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-column flex-xxl-row justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-xxl-between align-items-center align-self-center'>
                <div className='col-auto d-flex flex-column'>
                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>
                      Horas CPU
                    </h1>
                    <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>{ hardware?.cpu.max_usage } horas</p>
                  </div>
                </div>
                <div className='col-auto mt-4 mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 mt-xxl-0'>
                  <div className='row g-3'>
                    <div className='col'>
                      <div className='w-100 d-flex flex-row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end align-items-center align-self-center'>
                        <div className='card border-0 br-1- w-100 ps-3 pe-3 pt-2 pb-2 br-1- bs-4- bg-bone-white- wrapper-card-count-species-'>
                          <div className='card-header border-0 bg-transparent pb-0'>
                            <h1 className='m-0 p-0 lh-sm text-center fs-5- ff-monse-medium- fw-semibold tx-black-'>Consumidas</h1>
                          </div>
                          <div className='p-0 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                            <p className='m-0 p-0 text-center lh-sm fs-1- ff-monse-bold- tx-black-'>
                              <span>{ hardware?.cpu.usage }</span>
                            </p>
                            <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-black-'>
                              Horas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='w-100 d-flex flex-row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end align-items-center align-self-center'>
                        <div className='card border-0 br-1- w-100 ps-3 pe-3 pt-2 pb-2 br-1- bs-4- bg-green-light- wrapper-card-count-species-'>
                          <div className='card-header border-0 bg-transparent pb-0'>
                            <h1 className='m-0 p-0 lh-sm text-center fs-5- ff-monse-medium- fw-semibold tx-black-'>Disponibles</h1>
                          </div>
                          <div className='p-0 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                            <p className='m-0 p-0 text-center lh-sm fs-1- ff-monse-bold- tx-black-'>
                              <span>{ hardware?.cpu.max_usage - hardware?.cpu.usage ? hardware?.cpu.max_usage - hardware?.cpu.usage : '' }</span>
                            </p>
                            <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-black-'>
                              Horas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-consumtions-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-column flex-xxl-row justify-content-center justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-center justify-content-xxl-between align-items-center align-self-center'>
                <div className='col-auto d-flex flex-column'>
                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>
                      Memoria
                    </h1>
                    <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>{ hardware?.mem.max_usage } GB</p>
                  </div>
                </div>
                <div className='col-auto mt-4 mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 mt-xxl-0'>
                  <div className='row g-3'>
                    <div className='col'>
                      <div className='w-100 d-flex flex-row d-flex flex-row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end align-items-center align-self-center'>
                        <div className='card border-0 br-1- w-100 ps-3 pe-3 pt-2 pb-2 br-1- bs-4- bg-bone-white- wrapper-card-count-species-'>
                          <div className='card-header border-0 bg-transparent pb-0'>
                            <h1 className='m-0 p-0 lh-sm text-center fs-5- ff-monse-medium- fw-semibold tx-black-'>Consumidas</h1>
                          </div>
                          <div className='p-0 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                            <p className='m-0 p-0 text-center lh-sm fs-1- ff-monse-bold- tx-black-'>
                              <span>{ hardware?.mem.usage }</span>
                            </p>
                            <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-black-'>
                              Horas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='w-100 d-flex flex-row d-flex flex-row justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end justify-content-xl-end justify-content-xxl-end align-items-center align-self-center'>
                        <div className='card border-0 br-1- w-100 ps-3 pe-3 pt-2 pb-2 br-1- bs-4- bg-green-light- wrapper-card-count-species-'>
                          <div className='card-header border-0 bg-transparent pb-0'>
                            <h1 className='m-0 p-0 lh-sm text-center fs-5- ff-monse-medium- fw-semibold tx-black-'>Disponibles</h1>
                          </div>
                          <div className='p-0 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                            <p className='m-0 p-0 text-center lh-sm fs-1- ff-monse-bold- tx-black-'>
                              <span>{ hardware?.mem.max_usage - hardware?.mem.usage ? hardware?.mem.max_usage - hardware?.mem.usage : '' }</span>
                            </p>
                            <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-black-'>
                              Horas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
