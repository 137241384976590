import Swal from 'sweetalert2';
import './Navbar.css'
import React, { useContext } from 'react'
import { Appcontext } from '../../../appContext';
import { useNavigate } from 'react-router-dom';

export default function Navbar() {

  const { setToken } = useContext(Appcontext);
  const navigate = useNavigate();

  const logout = () => {
    Swal.fire({
      text: 'Seguro que desea cerrar sesión',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setToken(null);
        sessionStorage.setItem('token', '');
        navigate('/auth');
      }
    });
  };

  return (
    <React.Fragment>
      <nav className='navbar navbar-expand pt-0 pb-0 d-flex flex-row justify-content-end align-items-center align-self-center'>
        <div className='container-fluid navbar-collapse-background position-relative d-flex flex-row justify-content-between align-items-center align-self-center'>
          <div className='navbar-brand d-flex flex-row justify-content-start align-items-center align-self-center'>
            <div id="sidebarCollapse" class='btn d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle p-3 btn-collapse-sidebar- bg-green-light- h-50-'>
              <i className='fa icon-left-arrow fs-xs'></i>
            </div>
          </div>
          <div className='w-auto'>
            <button className='btn rounded-4 ps-0 pe-0 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-transparent-red- bs-6-' type="button" onClick={logout}>
              <i className='fa icon-log-out me-2'></i>
              <span className='lh-1 fs-5- ff-monse-regular- fw-normal'>Cerrar sesion</span>
            </button>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}
