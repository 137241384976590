import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Appcontext } from '../../appContext';

export const OtherRoutes = () => {
    const { token } = useContext(Appcontext);
    return token
        ? <Navigate to='/dashboard' />
        : <Navigate to='/landing' />
}
