import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Appcontext } from '../../appContext';

export const PrivateRoutes = ({children}) => {
    const { token } = useContext(Appcontext);
    return token
        ? children
        : <Navigate to='/landing'></Navigate>
}
