import './FilesBioinformatic.css'
import IconArchive from '../../../../../assets/images/icon-archive.svg';
import IconFolder from '../../../../../assets/images/icon-folder.svg';
import IconFile from '../../../../../assets/images/icon-file.svg';
import IconSearch from '../../../../../assets/images/icon-search.svg';
import IconMachine from '../../../../../assets/images/icon-machine.svg';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom"
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated';
import { Appcontext } from '../../../../../appContext';
import { deleteFileService, deleteFolderService, getFilesUser, getFoldersUser, getHardwareUsageService, getPipelinesAmmountService, postFile, postFilePaired, postFolder } from '../../../../../services/dahsboard/bioinformatic/pipelines/filesBioinformatic/filesBioinformatic';
import Preloader from '../../../../shared/preloader/Preloader';
import Swal from 'sweetalert2';


/**
 * MENSAJES PERSONALIZADOS AL BUSCAR O CARGAR OPCIONES EN REACT SELECT
 */

const { NoOptionsMessage } = components;

const customNoOptionsMessage = props => (
  <NoOptionsMessage {...props}>No registrado</NoOptionsMessage>
);

const { LoadingMessage } = components;

const customLoadingMessage = props => (
  <LoadingMessage {...props}>Cargando</LoadingMessage>
);

/**
* ANIMATE DELETE MULTISELECT
*/

const animatedComponents = makeAnimated();

/**
* Data que llena los select
*/

const CustomSelect = [
  { value: "opcion-uno", label: "Opcion uno" },
  { value: "opcion-dos", label: "Opcion dos" },
  { value: "opcion-tres", label: "Opcion tres" }
];

/**
* Constante que soporta todo el cambio de los estilo del select
*/

const selectStyles = {
  /**
  * Estilos del icono del dropdown del select
  * Estilos del separador del select
  * Estilos del icono de cerrar del select
  */
  dropdownIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important', 
    paddingRight: '0.5rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  clearIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  /**
  * Estilos del input global
  */
  control: () => ({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    alignSelf: "start",
    justifyContent: "start",
    height: 'auto',
    minHeight: 50,
    maxHeight: 150,
    paddingLeft: '15px',
    paddingTop: '0.3rem',
    width: "100%",
    backgroundColor: 'var(--color-quaternary-blue-)',
    borderRadius: "1.18rem",
    border: "0px solid transparent",
  }),
  /**
 * EESTILOS DEL INPUT
 */
  input: (provided) => ({
    ...provided,
    color: 'var(--color-white-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  /**
  * Estilos del menu desplegable del select
  */
  menu: (styles) => ({
    ...styles,
    border: 'none',
    borderColor: 'var(--color-quaternary)',
    backgroundColor: 'var(--color-tertiary-white-rgba-)',
    boxShadow: 'var(--box-shadow-12-)',
    borderRadius: '1rem',
    padding: 0,
    marginTop: 8,
    marginBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "hidden",
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent !important"
    }
  }),
  /**
  * Estilos de las opciones desplegables
  */
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: 'var(--color-gray-)',
    backgroundColor: state.isSelected ? "var(--color-sextary-white-)" : "var(--color-tertiary-white-rgba-)",
    fontFamily: 'var(--font-family-regular-)',
    padding: '0.5rem 0.8rem 0.5rem 0.8rem',
    borderRadius: '1rem',
    ":hover": {
      background: "var(--color-sextary-white-)",
      color: 'var(--color-black-)',
    }
  }),
  /**
  * Estilos del contenedor
  */
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    position: 'relative',
    flex: '1 1 auto'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    paddingTop: '0rem !important',
    marginLeft: '0px',
    paddingRight: 0,
    padding: '0px 0px'
  }),
  /**
  * Estilos placeholder del input
  */
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
    position: "absolute",
    color: 'var(--color-white-)',
    lineHeight: 1.25,
    fontFamily: 'var(--font-family-regular-)',
    overflow: 'hidden',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  /**
  * Estilos texto en el input
  */
  singleValue: (styles) => ({ 
    ...styles, 
    fontSize: 14,
    color: "var(--color-white-)", 
    fontFamily: 'var(--font-family-regular-)', 
    padding: '3px',
    margin: '0px',
    marginTop: '2px',
    marginLeft: 0,
    marginRight: 0
  })
}

/**
 * Constante que soporta todo el cambio de los estilo del select de los modals
 */

const selectStylesModal = {
  /**
  * Estilos del icono del dropdown del select
  * Estilos del separador del select
  * Estilos del icono de cerrar del select
  */
  dropdownIndicator: (styles) => ({ ...styles, 
    color: "var(--color-black-)", 
    padding: 0, 
    paddingTop: '0rem !important', 
    paddingRight: '0.5rem !important',
    "&:hover": {
      color: "var(--color-black-)",
    } 
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  clearIndicator: (styles) => ({ ...styles, 
    color: "var(--color-black-)", 
    padding: 0, 
    paddingTop: '0rem !important',
    "&:hover": {
      color: "var(--color-black-)",
    } 
  }),
  /**
  * Estilos del input global
  */
  control: () => ({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    alignSelf: "start",
    justifyContent: "start",
    height: 'auto',
    minHeight: 50,
    maxHeight: 150,
    paddingLeft: '15px',
    paddingTop: '0.3rem',
    width: "100%",
    backgroundColor: 'var(--color-secundary-white-)',
    borderRadius: "1.18rem",
    border: "0px solid transparent",
  }),
  /**
 * EESTILOS DEL INPUT
 */
  input: (provided) => ({
    ...provided,
    color: 'var(--color-black-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  /**
  * Estilos del menu desplegable del select
  */
  menu: (styles) => ({
    ...styles,
    border: 'none',
    borderColor: 'var(--color-quaternary)',
    backgroundColor: 'var(--color-tertiary-white-rgba-)',
    boxShadow: 'var(--box-shadow-12-)',
    borderRadius: '1rem',
    padding: 0,
    marginTop: 8,
    marginBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "hidden",
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent !important"
    }
  }),
  /**
  * Estilos de las opciones desplegables
  */
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: 'var(--color-gray-)',
    backgroundColor: state.isSelected ? "var(--color-sextary-white-)" : "var(--color-tertiary-white-rgba-)",
    fontFamily: 'var(--font-family-regular-)',
    padding: '0.5rem 0.8rem 0.5rem 0.8rem',
    borderRadius: '1rem',
    ":hover": {
      background: "var(--color-sextary-white-)",
      color: 'var(--color-black-)',
    }
  }),
  /**
  * Estilos del contenedor
  */
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    position: 'relative',
    flex: '1 1 auto'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    paddingTop: '0rem !important',
    marginLeft: '0px',
    paddingRight: 0,
    padding: '0px 0px'
  }),
  /**
  * Estilos placeholder del input
  */
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
    position: "absolute",
    color: 'var(--color-black-)',
    lineHeight: 1.25,
    fontFamily: 'var(--font-family-regular-)',
    overflow: 'hidden',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  /**
  * Estilos texto en el input
  */
  singleValue: (styles) => ({ 
    ...styles, 
    fontSize: 14,
    color: "var(--color-black-)", 
    fontFamily: 'var(--font-family-regular-)', 
    padding: '3px',
    margin: '0px',
    marginTop: '2px',
    marginLeft: 0,
    marginRight: 0
  })
}

export default function FilesBioinformatic() {
  const { token, setToken, userApplication, foldersBioinformatic, setFoldersBioinformatic, filesBioinformatic, setFilesBioinformatic } = useContext(Appcontext);
  const navigate = useNavigate();
  const [carpet, setCarpet] = useState('');
  const [filter, setFilter] = useState('');
  const [carpetRoot, setCarpetRoot] = useState('Sequ');
  const [folders, setFolders] = useState([]);
  const [tabs, setTabs] = useState(['Sequ', 'Ref_ass', 'Ref_pro', 'Ref_trans']);
  const [filterFolders, setFilterFolders] = useState([]);
  const [folderSelected, setFolderSelected] = useState(null);
  const [foldersCharged, setFoldersCharged] = useState(false);
  const [pipelines, setPipelines] = useState(null);
  const [hardware, setHardware] = useState(null);
  const [fileForm, setFileForm] = useState({
    specie: '',
    fileType: '',
    tecnology: '',
    destinyFolder: '',
    destinyTab: '',
    description: '',
    file: '',
    fileName: '',
    file2: '',
    fileName2: ''
  });
  const [fileType, setFileType] = useState([
    { label: 'PAIRED-END SEQUENCE', value: 'PAIRED-END SEQUENCE' },
    { label: 'SINGLE-END SEQUENCE', value: 'SINGLE-END SEQUENCE' },
    { label: 'REFERENCE GENOME', value: 'REFERENCE GENOME' },
    { label: 'REFERENCE PROTEINS', value: 'REFERENCE PROTEINS' },
    { label: 'REFERENCE TRANSCRIPTS', value: 'REFERENCE TRANSCRIPTS' }
  ]);
  const [tecnologiesType, setTecnologiesType] = useState([
    { label: 'Illumina', value: 'illumina' }
  ]);
  const [charging, setCharging] = useState(false);
  const [filePairedCharged, setFilePairedCharged] = useState(false);
  const [enabledButton, setEnabledButton] = useState(true);

  /**
   * Overlay en las tarjetas
   */

  const [showOverlay, setShowOverlay] = useState(false);
  const [hiddenOverlay, setHiddenOverlay] = useState(true);

  const toggleOverlay = (cardId) => {
    setShowOverlay(cardId);
    setHiddenOverlay(false);
  };
  
  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    if (foldersCharged) {
      getServiceFolder();
    }
  }, [foldersCharged]);

  useEffect(() => {
    if (filePairedCharged) {
      saveFileWait();
    }
  }, [filePairedCharged]);

  const saveFileWait = async () => {
    setEnabledButton(false);
    await saveFilePaired(fileForm.file2);
    setFilePairedCharged(false);
    cancelFile();
    setEnabledButton(true);
  };

  const getServices = async () => {
    if (!filesBioinformatic) {
      await getFiles();
    }
    await getPipelinesAmmount();
    await getHardwareUsage();
    setFoldersCharged(true);
  };

  const getServiceFolder = async () => {
    if (!foldersBioinformatic) {
      await getFolders();
    }
    else {
      setCharging(true);
      setFolders(foldersBioinformatic.filter(folder => folder.tab === carpetRoot));
      setCharging(false);
    }
  };

  const getFiles = async () => {
    setCharging(true);
    let response = await getFilesUser(token, userApplication._id).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar cargar los archivos',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      let array = data.map(file => {
        let nameFile = file.path.split('/');
        return {
          ... file,
          name: nameFile[nameFile.length - 1]
        };
      });
      setFilesBioinformatic(array);
      setFoldersCharged(true);
      setCharging(false);
    }
  };

  const getPipelinesAmmount = async () => {
    setCharging(true);
    let response = await getPipelinesAmmountService(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setPipelines(data);
      setCharging(false);
    }
  };

  const getHardwareUsage = async () => {
    setCharging(true);
    let response = await getHardwareUsageService(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setHardware(data);
      setCharging(false);
    }
  };

  const getFolders = async () => {
    setCharging(true);
    let response = await getFoldersUser(token, userApplication._id).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar cargar las carpetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      let array = [];
      for (let index = 0; index < data.length; index++) {
        array = [
          ... array,
          ... splitFolders(data[index]),
        ]
      }
      setFolders(array.filter(folder => folder.tab === carpetRoot));
      setFoldersBioinformatic(array);
      setCharging(false);
    }
  };

  const splitFolders = (folders) => {
    let array = [];
    let fold = folders.folder_path.split('/');
    for (let index = 1; index < fold.length; index++) {
      array.push({
        ... folders,
        name: fold[index],
        parent: fold[index - 1],
        tab: fold[0],
        filesInside: calculateFilesInside(folders, filesBioinformatic)
      });
    }
    return array;
  };

  const calculateFilesInside = (folder, files) => {
    return files.filter(file => file.file_path === folder.folder_path).length;
  };

  const filterByTab = (tab) => {
    setCarpetRoot(tab);
    if (foldersBioinformatic) {
      setFolders(foldersBioinformatic.filter(folder => folder.tab === tab));
    }
  };

  const createCarpet = () => {
    let completeRoot = `${carpetRoot}/${carpet}`;
    postFolder(token, completeRoot).then(res => {
      let { data } = res;
      let array = splitFolders(data.result);
      let arrayFolders = [
        ... foldersBioinformatic,
        ... array
      ];
      setFoldersBioinformatic(arrayFolders);
      setFolders(arrayFolders.filter(folder => folder.tab === carpetRoot));
      setCarpet('');
    }).catch(error => {
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar guardar la carpeta',
          icon: 'error'
        });
      }
      setCarpet('');
    });      
  };

  const deleteFolder = async (folder) => {
    Swal.fire({
      text: 'Seguro que desea eliminar esta carpeta y su contenido?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCharging(true);
        let response = await deleteFolderService(folder.id, token).catch(error => {
          setCharging(false);
          if (error?.response.status === 401) {
            Swal.fire({
              text: 'Su sesión expiró. Le cerraremos la sesión',
              icon: 'info'
            });
            setToken(null);
            sessionStorage.setItem('token', '');  
            navigate('/auth');
          } else {
            Swal.fire({
              text: 'Ocurrió un error al intentar eliminar la carpeta',
              icon: 'error'
            });
          }
        });
        if (response) {
          let array = foldersBioinformatic.filter(folderB => folderB.id !== folder.id);
          setFoldersBioinformatic(array);
          setFolders(array.filter(folder => folder.tab === carpetRoot));
          setCharging(false);
        }
      }
    });
  };

  const changeSelect = (e) => {
    let tab = '';
    if (e?.value.toLowerCase() === 'paired-end sequence' || e?.value.toLowerCase() === 'single-end sequence') {
      tab = 'Sequ';
      filterByFolder(tab);
    } else if (e?.value.toLowerCase() === 'reference genome') {
      tab = 'Ref_ass';
      filterByFolder(tab);
    } else if (e?.value.toLowerCase() === 'reference proteins') {
      tab = 'Ref_pro';
      filterByFolder(tab);
    } else if (e?.value.toLowerCase() === 'reference transcripts') {
      tab = 'Ref_trans';
      filterByFolder(tab);
    }
    setFileForm({
      ... fileForm,
      fileType: e?.value,
      destinyFolder: '',
      destinyTab: tab
    });
  };

  const filterByFolder = (tab) => {
    let array = foldersBioinformatic.filter(folder => folder.tab === tab);
    setFilterFolders(array.map(folder => {
      return { label: folder.name, value: folder.name };
    }));
  };

  const changeFile = ({ target }) => {
    if (target.files[0]?.name) {
      if (fileForm.fileType.toLowerCase() === 'paired-end sequence') {
        if (fileForm.file) {
          setFileForm({
            ... fileForm,
            file2: target.files[0],
            fileName2: target.files[0].name
          });  
        } else {
          setFileForm({
            ... fileForm,
            file: target.files[0],
            fileName: target.files[0].name
          });
        }
      } else {
        setFileForm({
          ... fileForm,
          file: target.files[0],
          fileName: target.files[0].name
        });
      }
    }
  };

  const cancelFile = () => {
    setFileForm({
      specie: '',
      fileType: '',
      tecnology: '',
      destinyFolder: '',
      destinyTab: '',
      description: '',
      file: '',
      fileName: '',
      file2: '',
      fileName2: ''
    });
  };

  const checkExtension = (extension) => {
    const extensionsAllowed = /^(fq|fasta|gff|gff3|fa)$/;
    return extensionsAllowed.test(extension);
  };

  const saveFile = async () => {
    if (fileForm.fileType.toLowerCase() === 'paired-end sequence') {
      let name1 = false;
      let name2 = false;
      let newFile1 = fileForm.fileName.split('.');
      let newFile2 = fileForm.fileName2.split('.');
      let extension1 = checkExtension(newFile1[newFile1.length - 1]);
      let extension2 = checkExtension(newFile2[newFile1.length - 1]);
      if (newFile1.length > 1) {
        name1 = newFile1[newFile1.length - 2].slice(-2) === '_1';
        name2 = newFile1[newFile1.length - 2].slice(-2) === '_2';
      }

      if (newFile2.length > 1) {
        if (!name1) {
          name1 = newFile2[newFile2.length - 2].slice(-2) === '_1';
        } else {
          name2 = newFile2[newFile2.length - 2].slice(-2) === '_2';
        }
      } 

      if (name1 && name2 && extension1 && extension2) {
        await saveFilePaired(fileForm.file);
        setFilePairedCharged(true); 
      } else {
        let message = !name1 || !name2 ? `Los nombres de los archivos deben terminar en '_1' y '_2'\n` : '';
        message += !extension1 || !extension2 ? `Los archivos deben tener la extensión '.fq, .fasta, .gff, .gff3 .fa'` : '';
        Swal.fire({
          title: 'Error con los archivos',
          text: message,
          icon: 'info'
        });
      }
    } else {
      let newFile = fileForm.fileName.split('.');
      let extension = checkExtension(newFile[newFile.length - 1]);
      if (extension) {
        saveFileDifferent();
      } else {
        Swal.fire({
          title: 'Error con los archivos',
          text: `El archivo debe tener la extensión '.fq, .fasta, .gff, .gff3 .fa'`,
          icon: 'info'
        });
      }
    }
  };

  const saveFilePaired = async (fileToSend) => {
    let filesSave = fileForm;
    Swal.fire({
      title: 'Su archivo está siendo cargado',
      text: 'Por favor espere, no abandone la página',
      icon: 'info'
    });
    let response = await postFilePaired(filesSave, fileToSend, token).catch(error => {
      let data = error?.response ? error.response.data : null;
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else if (data?.error === 'No se pudo validar el nombre de la especie, asegurese que este bien escrito') {
        Swal.fire({
          text: 'No se pudo validar el nombre de la especie, asegúrese que esté bien escrito',
          icon: 'error'
        });
      } else {
        Swal.fire({
          text: 'Ocurrió un error, vuelva a intentar',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      let newFile = data;
      let nameFile = data.path.split('/');
      newFile.name = nameFile[nameFile.length - 1];
      let arrayFile = [
        ... filesBioinformatic,
        newFile
      ];
      setFilesBioinformatic(arrayFile);
      let array = foldersBioinformatic.map(folder => {
        return {
          ... folder,
          filesInside: calculateFilesInside(folder, arrayFile)
        };
      });
      setFoldersBioinformatic(array);
      setFolders(array.filter(folder => folder.tab === carpetRoot));
      Swal.fire({
        text: 'Archivo cargado correctamente',
        icon: 'success'
      });
    }
  };

  const saveFileDifferent = () => {
    let filesSave = fileForm;
    Swal.fire({
      title: 'Su archivo está siendo cargado',
      text: 'Por favor espere, no abandone la página',
      icon: 'info'
    });
    cancelFile();
    postFile(filesSave, token).then(res => {
      Swal.fire({
        text: 'Archivo cargado correctamente',
        icon: 'success'
      });
      let { data } = res;
      let newFile = data;
      let nameFile = data.path.split('/');
      newFile.name = nameFile[nameFile.length - 1];
      let arrayFile = [
        ... filesBioinformatic,
        newFile
      ];
      setFilesBioinformatic(arrayFile);
      let array = foldersBioinformatic.map(folder => {
        return {
          ... folder,
          filesInside: calculateFilesInside(folder, arrayFile)
        };
      });
      setFoldersBioinformatic(array);
      setFolders(array.filter(folder => folder.tab === carpetRoot));
    }).catch(error => {
      let data = error?.response ? error.response.data : null;
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else if (data?.error === 'No se pudo validar el nombre de la especie, asegurese que este bien escrito') {
        Swal.fire({
          text: 'No se pudo validar el nombre de la especie, asegúrese que esté bien escrito',
          icon: 'error'
        });
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar guardar el archivo',
          icon: 'error'
        });
      }
    });
  };

  const conditions = () => {
    if (fileForm.fileType.toLowerCase() === 'paired-end sequence') {
      return !fileForm.specie || !fileForm.fileType || !fileForm.tecnology || !fileForm.destinyFolder || !fileForm.destinyTab || !fileForm.description || !fileForm.file || !fileForm.fileName || !fileForm.file2 || !fileForm.fileName2;
    } else {      
      return !fileForm.specie || !fileForm.fileType || !fileForm.tecnology || !fileForm.destinyFolder || !fileForm.destinyTab || !fileForm.description || !fileForm.file || !fileForm.fileName;
    }
  };

  const deleteFile = async (file) => {
    Swal.fire({
      text: 'Seguro que desea eliminar este archivo?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCharging(true);
        let response = await deleteFileService(file.id, token).catch(error => {
          setCharging(false);
          if (error?.response.status === 401) {
            Swal.fire({
              text: 'Su sesión expiró. Le cerraremos la sesión',
              icon: 'info'
            });
            setToken(null);
            sessionStorage.setItem('token', '');  
            navigate('/auth');
          } else {
            Swal.fire({
              text: 'Ocurrió un error al intentar eliminar el archivo',
              icon: 'error'
            });
          }
        });
        if (response) {
          let array = filesBioinformatic.filter(fileB => fileB.id !== file.id);
          let secondArray = foldersBioinformatic.map(folder => {
            return {
              ... folder,
              filesInside: calculateFilesInside(folder, array)
            };
          });
          setFolders(secondArray.filter(folder => folder.tab === carpetRoot));
          setFoldersBioinformatic(secondArray);
          setFilesBioinformatic(array);
          setCharging(false);
        }
      }
    });
  };

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-archive-' src={IconArchive} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Archivos
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  En esta sección se cargarán los archivos necesarios para la ejecución de los pipelines
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-9'>
          <div className='row g-3 d-flex flex-row justify-content-start align-items-center align-self-center'>
            <div className='col-auto'>
              <div className='row g-3'>
                <div className='col-auto'>
                  <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-50- d-flex flex-row justify-content-center align-items-center align-self-center btn-bone-white- bs-6-' type="button" data-bs-toggle="offcanvas" data-bs-target="#upload-file" aria-controls="upload-file" disabled={!enabledButton} >
                    <i className='fa icon-upload-file me-2'></i>
                    <span className='lh-1 fs-5- ff-monse-regular- fw-bold'>Cargar archivo</span>
                  </button>
                </div>
                <div className='col-auto'>
                  <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-50- d-flex flex-row justify-content-center align-items-center align-self-center btn-bone-white- bs-6-' type="button" data-bs-toggle="modal" data-bs-target="#create-folder">
                    <i className='fa icon-add-folder me-2'></i>
                    <span className='lh-1 fs-5- ff-monse-regular- fw-bold'>Agregar carpeta</span>
                  </button>
                </div>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl col-xxl'>
              <div className='row g-3'>
                <div className='col-12 col-sm-12 col-12 col-lg col-xl col-xxl'>
                  <div className='col-12'>
                    <form action="" className='position-relative wrapper-search-auto-'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={filter} onChange={({ target }) => setFilter(target.value)} />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mt-4'>
            <div className='col-12'>
              <div id='container-card-folders-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                <div className='card-header border-0 bg-transparent pb-3 pt-3'>
                  <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                    Carpetas
                  </h1>
                  <div className='w-100 mt-3'>
                    <ul className="nav nav-pills d-flex flex-row justify-content-center row gx-2" role="tablist">
                      {
                        tabs?.map(tab => (
                          <li className="nav-item" role="presentation" key={tab}>
                            <button
                              className={`nav-link ${tab === carpetRoot ? 'active' : ''} ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-40- bs-6-`}
                              id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills" type="button" role="tab" aria-controls="pills" aria-selected="false" onClick={() => filterByTab(tab)} ><span className="ff-monse-regular-">{ tab }</span></button>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                </div>
                <div className='card-body p-0 ps-3 pe-3 pt-2 w-100'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='w-100'>
                        <div className="tab-content w-100" id="contentTables">
                          <div className="tab-pane fade show active w-100" id="pills" role="tabpanel" aria-labelledby="sequ-tab" tabIndex="0">
                            <div className='row row-cols-auto d-flex flex-wrap justify-content-center align-items-start align-self-start justify-content-sm-center align-items-sm-start align-self-sm-start justify-content-md-center align-items-md-start align-self-md-start justify-content-lg-start align-items-lg-start align-self-lg-start justify-content-xl-start align-items-xl-start align-self-xl-start justify-content-xxl-start align-items-xxl-start align-self-xxl-start g-4'>
                              {
                                folders.filter(folder => {
                                  if (filter !== '') {
                                    let flag = false;
                                    let words = folder.name.split(' ');
                                    let j = 0;
                                    while (j < words.length && !flag) {
                                      if (words[j].toUpperCase().includes(filter.toUpperCase())) {
                                        flag = true;
                                      }
                                      j++;
                                    }
                                    if (filter.toUpperCase() === folder.name.substring(0, filter.length).toUpperCase() || folder.name.toUpperCase().includes(filter.toUpperCase())) {
                                      flag = true;
                                    }
                                    if (flag) {
                                      return true;
                                    }
                                  } else {
                                    return folder;
                                  }
                                }).map(folder => (
                                  <div className='col-auto d-flex flex-column justify-content-center align-items-center align-self-start' key={folder.id} >
                                    <div id="card-folder" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-' onClick={() => setFolderSelected(folder)}>
                                      <div className='card d-flex flex-column justify-content-center align-items-center align-self-center border-0 position-relative overflow-hidden p-3 bs-6-'>
                                        <div className='container-fluid'>
                                          <div className='row g-2 d-flex flex-row justify-content-start mt-1' onClick={() => toggleOverlay('card-folder-and-files-')}>
                                            <div className='col-auto me-1'>
                                              <img className='icon-folder-' src={IconFolder} alt="" />
                                            </div>
                                            <div className='col text-wrap wrapper-name-'>
                                              <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-bold text-truncate tx-black-'>{ folder.name }</p>
                                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-regular- fw-bold tx-gray-dark-'>{folder.filesInside} archivo(s)</p>
                                            </div>
                                          </div>
                                        </div>
                                        <button className='btn rounded-pill p-2 position-absolute top-0 end-0 button-delete- btn-red- bs-6- mt-1 me-1' onClick={() => deleteFolder(folder)} >
                                          <i className='fa icon-delete d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block'></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showOverlay === 'card-folder-and-files-' && (
                <div className={`overlay-wrapper${hiddenOverlay ? ' hidden' : ''}`} onAnimationEnd={() => hiddenOverlay && setHiddenOverlay(true)}>
                  <div className={`overlay-content${hiddenOverlay ? ' hidden' : ''}`} onAnimationEnd={() => hiddenOverlay && setHiddenOverlay(true)}>
                    <div className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-4 ps-0 pe-0 pt-0 pb-0'>
                      <div className='card-header border-0 bg-transparent pb-3 pt-3'>
                        <div className='d-flex flex-row justify-content-between align-items-center align-self-center'>
                          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                            Archivos
                          </h1>
                          <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-start align-self-start button-close- btn-bone-white- bs-6- ms-2' onClick={() => toggleOverlay(null)}>
                            <i className='fa icon-close-overlay d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block'></i>
                          </button>
                        </div>
                      </div>
                          <div className='card-body p-0 ps-3 pe-3 w-100 wrapper-data-results-'>
                            <div className='row row-cols-auto d-flex flex-wrap justify-content-center align-items-start align-self-start justify-content-sm-center align-items-sm-start align-self-sm-start justify-content-md-center align-items-md-start align-self-md-start justify-content-lg-start align-items-lg-start align-self-lg-start justify-content-xl-start align-items-xl-start align-self-xl-start justify-content-xxl-start align-items-xxl-start align-self-xxl-start g-4'>
                            {
                              filesBioinformatic.filter(file => file.file_path === folderSelected?.folder_path).map(file => (
                                <div className='col d-flex flex-column justify-content-center align-items-center align-self-start' key={file.id} >
                                  <div id="card-file" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                                    <div className='card d-flex flex-column justify-content-center align-items-center align-self-center border-0 position-relative overflow-hidden p-3 bs-6-'>
                                      <div className='row'>
                                        <div className='col-12 d-flex flex-column justify-content-center align-items-center align-self-center'>
                                          <img className='icon-file-' src={IconFile} alt="" />
                                        </div>
                                      </div>
                                      <hr class="custom-hr" />
                                      <div className='row g-2 d-flex flex-row justify-content-start'>
                                        <div className='col text-wrap wrapper-name-'>
                                          <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-bold text-truncate tx-black-'>{ file.name }</p>
                                          <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-regular- fw-bold tx-gray-dark-'>{ file.registered_date }</p>
                                        </div>
                                      </div>
                                      <button className='btn rounded-pill p-2 position-absolute top-0 end-0 button-delete- btn-red- bs-6- mt-1 me-1' onClick={() => deleteFile(file)}>
                                        <i className='fa icon-delete d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block'></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                ))
                              }
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
                )}
                {showOverlay === 'card-folder-and-files-' && <div className="overlay-backdrop" onClick={() => toggleOverlay(null)} />}
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-3 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-3 mt-xxl-0'>
          <div className='row g-4'>
            <div className='col-12'>
              <div className='row gx-3 d-flex flex-row justify-content-center align-items-center align-self-center rounded-4 bg-blue-translucent- glass-effect- bs-4- wrapper-card-global-count-'>
                <div className='col-auto me-2'>
                  <img className='icon-machine-consumptions-' src={IconMachine} alt="" />
                </div>
                <div className='col-auto'>
                  <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white- fw-bold'>Corriendo</p>
                  <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-green-light- fw-bold'>{ pipelines?.pipeline_running_amount }</p>
                </div>
                <div className='col-auto'>
                  <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white- fw-bold'>CPU</p>
                  <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-green-light- fw-bold'>{ hardware?.cpu.usage }</p>
                </div>
                <div className='col-auto'>
                  <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white- fw-bold'>Memoria</p>
                  <p className='m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-green-light- fw-bold'>{ hardware?.mem.usage }</p>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <div class="d-grid gap-2">
                <NavLink className='nav-link btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-50- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- bs-6-' type="button" to='/dashboard/bioinformatic/pipelines/toolsBioinformatic/'>
                  <span className='lh-1 fs-5- ff-monse-regular- fw-bold'>Herramientas</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='offcanvas offcanvas-bottom offcanvas-bottom-' tabIndex="-1" data-bs-backdrop="false" id="upload-file"
        aria-labelledby="upload-file" data-bs-scroll="false">
        <div className='offcanvas-header pt-4 pb-4'>
          <h2 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-green-light-'>Subir archivo</h2>
          <button type="button"
            className='btn-close-offcanvas'
            data-bs-dismiss="offcanvas">
            <i className='fa icon-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <div className='container'>
            <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-start align-self-start'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-auto col-xxl-auto'>
                <form id='internal-form-inline' action='' className='position-relative'>
                  <div className='row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gx-xxl-5'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mb-xxl-4'>
                      <div className='form-floating inner-addon- left-addon-'>
                        <input type='text' className='form-control' placeholder="Nombre de la especie" value={fileForm.specie} onChange={({ target }) => setFileForm({... fileForm, specie: target.value})} />
                      </div>
                    </div>
                  </div>
                  <div className='row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gx-xxl-5'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mb-xxl-4'>
                      <div className='form-floating inner-addon- left-addon-'>
                        <Select id='customSelect' options={fileType} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Tipo de archivo" styles={selectStyles} isClearable={true} value={fileForm.fileType ? { value: fileForm.fileType, label: fileForm.fileType } : null} onChange={(e) => changeSelect(e) } />
                          <i className='fa icon-id-type'></i>
                      </div>
                    </div>
                  </div>
                  <div className='row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gx-xxl-5'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mb-xxl-4'>
                      <div className='form-floating inner-addon- left-addon-'>
                        <Select id='customSelect' options={tecnologiesType} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Tecnología de secuenciación" styles={selectStyles} isClearable={true} value={fileForm.tecnology ? { value: fileForm.tecnology, label: fileForm.tecnology } : null} onChange={(e) => setFileForm({ ... fileForm, tecnology: e.value })} />
                          <i className='fa icon-id-type'></i>
                      </div>
                    </div>
                  </div>
                  <div className='row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gx-xxl-5'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mb-xxl-4'>
                      <div className='form-floating inner-addon- left-addon-'>
                        <Select id='customSelect' options={filterFolders} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Carpeta destino" styles={selectStyles} isClearable={true} value={fileForm.destinyFolder ? { value: fileForm.destinyFolder, label: fileForm.destinyFolder } : null} onChange={(e) => setFileForm({ ... fileForm, destinyFolder: e.value })} isDisabled={!fileForm.fileType} />
                          <i className='fa icon-id-type'></i>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        <textarea className='form-control pt-2 pb-2' rows="5" placeholder='Ingrese una corta descripción aquí' value={fileForm.description} onChange={({ target }) => setFileForm({ ... fileForm, description: target.value }) } ></textarea>
                    </div>
                  </div>
                  <hr className='border border-1 border-white border-opacity-50 mt-4 mb-4'/>
                  <div className='row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gx-xxl-5'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mb-xxl-4'>
                      <h1 className='m-0 mb-2 p-0 lh-sm fs-4- ff-monse-regular- fw-regular tx-green-light-'>Descripción archivos de entrada</h1>
                      <div class="row gy-3 d-flex flex-column justify-content-start align-items-start align-self-start mt-2">
                        <div className='col-auto d-flex flex-row justify-content-start align-items-center align-self-start'>
                          <p className='p-0 m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold'>Formato de archivos para genómica</p>
                        </div>
                        <div className='col-auto d-flex flex-row justify-content-start align-items-center align-self-start'>
                          <p class="p-0 m-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold">(PE)</p>
                          <div className='d-flex flex-column justify-content-start align-items-start align-self-start ms-2'>
                            <p class="p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- p-0 m-0 mb-2">Forward - [Nombre]_1.fastq</p>
                            <p class="p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- p-0 m-0">Reverse - [Nombre]_2.fastq</p>
                          </div>
                        </div>
                        <div className='col-auto d-flex flex-row justify-content-start align-items-center align-self-start'>
                          <p class="lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold p-0 m-0">(SE)</p>
                          <div className='d-flex flex-column justify-content-start align-items-start align-self-start ms-2'>
                            <p class="lh-sm fs-5- ff-monse-regular- fw-regular tx-white- p-0 m-0">[Nombre]_1.fastq</p>
                          </div>
                        </div>
                        <div className='col-auto d-flex flex-row justify-content-start align-items-center align-self-start'>
                          <p className='p-0 m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold'>Formato de archivos para transcriptómica</p>
                        </div>
                        <div className='col-auto d-flex flex-row justify-content-start align-items-center align-self-start'>
                        <p class="lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold p-0 m-0">(PE)</p>
                          <div className='d-flex flex-column justify-content-start align-items-start align-self-start ms-2'>
                            <p class="lh-sm fs-5- ff-monse-regular- fw-regular tx-white- p-0 m-0 mb-2">[Tratamiento]_[Replica]_1.fastq</p>
                            <p class="lh-sm fs-5- ff-monse-regular- fw-regular tx-white- p-0 m-0">[Tratamiento]_[Replica]_2.fastq</p>
                          </div>
                        </div>
                      </div>
                      <div className='row gy-2 d-flex flex-column justify-content-start align-items-start align-self-start mt-2'>
                        <div className='col-auto'>
                          <p className='p-0 m-0 mb-1 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold'>Genoma de referencia</p>
                          <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-normal'>Opcional</p>
                        </div>
                        <div className='col-auto'>
                          <p className='p-0 m-0 mb-1 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold'>Transcritos de referencia</p>
                          <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-normal'>Opcional</p>
                        </div>
                        <div className='col-auto'>
                          <p className='p-0 m-0 mb-1 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-bold'>Proteinas de referencia</p>
                          <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-regular tx-white- fw-normal'>Opcional</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className='col wrapper-upload-'>
                <div className='row g-4 p-4 d-flex flex-column justify-content-between align-items-center align-self-center'>
                  <div className='col-12'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-green-light-'>Agregar archivo</h1>
                  </div>
                  <div className='col-12'>
                    <div id="test-drop-area" className='file-drop-area- position-relative overflow-hidden'>
                      <div className='row g-1 d-flex flex-column justify-content-center align-items-center align-self-center w-100 pt-3 pb-3'>
                        <div className='col-auto'>
                          <i className='fa icon-file-upload'></i>
                        </div>
                        <div className='col-auto'>
                          <span className='file-msg-drop- lh-sm fs-5- ff-monse-regular- tx-white-'>Arrastre y suelte su archivo aquí</span>
                        </div>
                        <div className='col-auto'>
                          <span className="file-msg-drop- lh-sm fs-5- ff-monse-regular- tx-white- mt-1">o</span>
                        </div>
                        <div className='col-auto'>
                          <span className='fake-btn-drop- mt-2 d-flex flex-row justify-content-start align-items-center align-self-center ps-3 pe-3 pt-1 pb-1'>
                            <i className='fa icon-search-upload-file fs-xs me-2'></i>
                            <span className='lh-sm fs-6- ff-monse-regular- tx-green-light-'>Busca un archivo</span>
                          </span>
                        </div>
                        <input className='file-input-drop-' type="file" onChange={changeFile} />
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row row-cols-auto g-1 d-flex flex-wrap justify-content-start align-items-center align-self-center'>
                      {
                        fileForm.file
                        ?
                        <div className='col d-flex justify-content-start align-items-center align-self-center ps-2 pe-2'>
                          <p className='m-0 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white- text-center'>{ fileForm.fileName }</p>
                          <i className='fa icon-remove-file cursor- ps-2' onClick={() => setFileForm({ ... fileForm, file: null, fileName: '' })} ></i>
                        </div>
                        :
                        null
                      }
                      {
                        fileForm.fileType.toLowerCase() === 'paired-end sequence' && fileForm.file2
                        ?
                        <div className='col d-flex justify-content-start align-items-center align-self-center ps-2 pe-2'>
                          <p className='m-0 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white- text-center'>{ fileForm.fileName2 }</p>
                          <i className='fa icon-remove-file cursor- ps-2' onClick={() => setFileForm({ ... fileForm, file2: null, fileName2: '' })} ></i>
                        </div>
                        :
                        null
                      }
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='row g-2 d-flex flex-row justify-content-end align-items-start align-self-start mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mbxxl-4'>
                      <div className='col-auto'>
                        <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-bone-white- bs-6-' data-bs-dismiss="offcanvas" onClick={cancelFile} >
                          <span className='lh-1 fs-5- ff-monse-regular-'>Cancel</span>
                        </button>
                      </div>
                      <div className='col-auto'>
                        <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- bs-6-' data-bs-dismiss="offcanvas" type="button" onClick={saveFile} disabled={conditions()} >
                          <span className='lh-1 fs-5- ff-monse-regular-'>Cargar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal animated fade fast' id="create-folder" tabIndex="-1" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className='modal-dialog modal-dialog-centered' role="document">
          <div className='modal-content p-0 ps-4 pe-4'>
            <div className='modal-header'>
              <h5 className='modal-title lh-sm fs-4- ff-monse-regular- tx-black- fw-bold'>Crear carpeta</h5>
              <button type="button" className='btn-close' data-bs-dismiss="modal" aria-label="Close" onClick={() => setCarpet('')}></button>
            </div>
            <div className='modal-body position-relative'>
              <div className='row mt-3'>
                <div className='col-12'>
                  <form id='internal-form-modal-inline' className='w-100" autocomplete="nope' onSubmit={(e) => e.preventDefault()} >
                    <div className='row gx-0 gx-sm-0 gx-md-4 gx-lg-4 gx-xl-4 gx-xxl-5'>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-4 mb-lg-4 mb-xl-4 mb-xxl-4'>
                        <input type="text" className='form-control' placeholder="Nombre de la carpeta" onChange={({ target }) => setCarpet(target.value)} value={carpet} />
                      </div>
                    </div>
                    <div className='row g-2 d-flex flex-row justify-content-center align-items-start align-self-start mt-2 mb-2'>
                      <div className='col-auto'>
                        <button className='btn rounded-4 p-2 ps-3 pe-3 ps-sm-3 pe-sm-3 ps-md-3 pe-md-3 ps-lg-3 pe-lg-3 ps-xl-3 pe-xl-3 ps-xxl-4 pe-xxl-4 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-gray-' type="button" data-bs-dismiss="modal" aria-label="Close" onClick={() => setCarpet('')} >
                            <span className='lh-1 fs-6- ff-monse-regular-'>Cancelar</span>
                        </button>
                      </div>
                      <div className='col-auto'>
                        <button className='btn rounded-4 p-2 ps-3 pe-3 ps-sm-3 pe-sm-3 ps-md-3 pe-md-3 ps-lg-3 pe-lg-3 ps-xl-3 pe-xl-3 ps-xxl-4 pe-xxl-4 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light-' type="button" data-bs-dismiss="modal" aria-label="Close" onClick={createCarpet} disabled={carpet === ''} >
                            <span className='lh-1 fs-6- ff-monse-regular-'>Guardar</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}