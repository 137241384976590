import './Auth.css';
import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import Login from './login/Login';
import Register from "./register/Register"
import Forgot from "./forgot/Forgot"
import Recovery from "./recovery/Recovery"

export const Authroutes = () => {
  return (
    <React.Fragment>
      <Routes>
          <Route path="" element={ <Navigate to="login" /> }/>
          <Route path="login" element={<Login/>} />
          <Route path="register" element={<Register/>} />
          <Route path="forgot" element={<Forgot/>} />
          <Route path="recovery" element={<Recovery/>} />
      </Routes>
    </React.Fragment>
  );
}
