import './Landing.css';
import React, { useContext, useEffect, useState } from 'react'
import Logo3bio from '../../assets/images/icon-3bio.png';
import IconScientometric from '../../assets/images/icon-scientometric.svg';
import IconBioinformatic from '../../assets/images/icon-bioinformatic.svg';
import ScientometricPreview from '../shared/offcanvasPreview/scientometricPreview/ScientometricPreview';
import IconSearch from '../..//assets/images/icon-search.svg';
import SearchPreview from '../shared/offcanvasPreview/searchPreview/SearchPreview';
import { useNavigate } from 'react-router-dom';
import { getGraphicsGeneralSearchLanding } from '../../services/landing/landing';
import Preloader from '../shared/preloader/Preloader';
import { Appcontext } from '../../appContext';

export default function Landing() {
  const { setFoldersBioinformatic } = useContext(Appcontext );
  const [allowClick, setAllowClick] = useState(false);
  const [search, setSearch] = useState('');
  const [charging, setCharging] = useState(false);
  const [infoArticles, setInfoArticles] = useState({});
  const [infoInstitutions, setInfoInstitutions] = useState({});
  const [infoGraph, setInfoGraph] = useState(null);

  useEffect(() => {
    setFoldersBioinformatic(null);
  }, []);
  
  const navigate = useNavigate();
  
  const redirectToLogin = () => {
    navigate('/auth/login');
  };

  const keyPressed = (e) => {
    if (e.key === 'Enter' && search.length >= 3) {
      getGeneralChartsData();
    }
  };

  const getGeneralChartsData = async () => {
    setCharging(true);
    let response = await getGraphicsGeneralSearchLanding(search).catch(() => {
      setCharging(false);
    });
    if (response) {
      let { data } = response;
      setAllowClick(true);
      setCharging(false);
      setInfoArticles(data.find(object => object.nombre === 'cantidad_articulos'));
      setInfoInstitutions(data.find(object => object.nombre === 'cantidad_instituciones'));
      let array = [];
      array.push(data.find(object => object.nombre === 'evolucion_temporal_search'));
      setInfoGraph(array);
    }
  };

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='container-fluid overflow-x-hidden d-flex flex-column vw-100 pt-0 pb-0 ps-2 pe-2 ps-sm-2 pe-sm-2 ps-md-4 pe-md-4 ps-lg-4 pe-lg-4 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 bg-3bio- min-h-'>
        <div className='row d-flex flex-row align-items-end align-self-end'>
          <div className='col-12 mt-3 mt-sm-3 mt-md-4 mt-lg-4 mt-xl-4 mt-xxl-4 mb-3'>
            <div className='w-100 d-flex flex-row justify-content-end align-items-center align-self-center'>
              <button className='btn rounded-4 ps-4 pe-4 ps-sm-4 pe-sm-4 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-green-light- ' type="button" onClick={redirectToLogin} >
                <span className='lh-1 fs-4- ff-monse-regular- fw-bold'>Ingresar</span>
              </button>
            </div>
          </div>
        </div>
        <div className='row d-flex flex-column justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-start justify-content-xl-center justify-content-xxl-center align-items-center align-self-center min-h- wrapper-landing-'>
          <div className='col-12 mt-4 mt-sm-4 mt-md-4 mt-lg-5 mt-xl-5 mt-xxl-5'>
            <div className='row g-5 d-flex flex-row justify-content-center align-items-center align-self-center'>
              <div className='col-auto'>
                <img className='logo-3bio-' src={Logo3bio} alt="" />
              </div>
              <div className='col-auto'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-white-'>Desde el segundo país más biodiverso del mundo, te damos la bienvenida a 3bio, una plataforma liderada por el Centro de Bioinformática y Biología Computacional de Colombia (BIOS) en alianza con el Departamento de Caldas, la Universidad de Manizales, la Universidad Autónoma de Manizales y la Universidad Católica Luis Amigó. Esta plataforma tiene como objeto integrar conocimientos derivados del estudio de nuestra biodiversidad colombiana con herramientas que permiten el entendimiento de los sistemas biológicos. Lo anterior, en aras de dar un valor agregado a los datos de la biodiversidad del país promoviendo la producción, utilización y conservación de recursos biológicos, además de la ciencia, la tecnología y la innovación relacionados, para proporcionar información, productos, procesos y servicios en sectores como medicina, agricultura y biotecnología, permitiendo el crecimiento y fortalecimiento económico de forma sostenible y amigable con la naturaleza.</p>
              </div>
            </div>
          </div>
          <div className='col-12 mt-5 mb-5'>
            <form action="" className='position-relative wrapper-search-large- d-block d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block' onSubmit={(e) => { e.preventDefault(); }} >
              <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                <input type="text" className='form-control search-' id="search" placeholder="Buscador global" value={search} onChange={({ target }) =>  setSearch(target.value)} onKeyUp={keyPressed} />
                <img src={IconSearch} alt="" />
              </div>
            </form>
          </div>
          <div className="col-12 d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-center align-self-center mt-4 mb-4 wrapper-buttons-preview-">
            <div className='w-auto me-0 me-sm-5 me-sm-5 me-md-5 me-lg-5 me-xl-5 me-xxl-5 mb-4 mb-sm-5'>
              <h2 className='m-0 p-0 lh-sm fs-3 tx-white- ff-monse-bold- title-'>Servicios</h2>
            </div>
            <div className='d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-center align-self-center wrapper-buttons-preview-'>
              <div className='w-auto d-flex flex-column justify-content-center align-items-center align-self-center mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0 mb-xxl-0'>
                <div className='w-auto'>
                  <input className="checkbox-preview- scientometric-" type="radio" name="preview" id="preview-1" data-bs-toggle="offcanvas" data-bs-target="#scientometric-preview" aria-controls="scientometric-preview" disabled={!allowClick} ></input>
                  <label className="for-checkbox-preview- d-flex flex-row justify-content-center align-items-center align-self-center" htmlFor="preview-1">
                    <img className='icon-service-scientometric-' src={IconScientometric} alt="" />
                  </label>
                </div>
                <p className='m-0 p-0 pt-3 lh-sm fs-4- ls-1- tx-white- ff-monse-regular- fw-semibold'>Cienciometría</p>
              </div>
              <div className='w-auto d-flex flex-column  justify-content-center align-items-center align-self-center mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0 mb-xxl-0'>
                <div className='w-auto'>
                  <input className="checkbox-preview- bioinformatic" type="radio" name="preview" id="preview-2" onClick={redirectToLogin}></input>
                  <label className="for-checkbox-preview- d-flex flex-row justify-content-center align-items-center align-self-center" htmlFor="preview-2">
                    <img className='icon-service-bioinformatic-' src={IconBioinformatic} alt="" />
                  </label>
                </div>
                <p className='m-0 p-0 pt-3 lh-sm fs-4- ls-1- tx-white- ff-monse-regular- fw-semibold'>Bioinformática</p>
              </div>
            </div>
          </div>
        </div>
        <SearchPreview></SearchPreview>
        <ScientometricPreview infoArticles={infoArticles} infoInstitutions={infoInstitutions} infoGraph={infoGraph} ></ScientometricPreview>
      </div>
    </React.Fragment>
  )
}
