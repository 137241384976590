import IconGruplac from '../../../../assets/images/icon-gruplac.svg';
import IconSearch from '../../../../assets/images/icon-search.svg';
import React, { useContext, useEffect, useState } from 'react'
import * as echarts from 'echarts';
import Select, { components } from 'react-select'
import "react-tooltip/dist/react-tooltip.css";
import makeAnimated from 'react-select/animated';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
import { Appcontext } from '../../../../appContext';
import { getGraphsGroupInstitution, getGraphsGrup, getOptionsGroups, getOptionsInstitutions, getTablesGroupInstitution, getTablesGrup } from '../../../../services/dahsboard/scientometric/gruplac/gruplac';
import Preloader from '../../../shared/preloader/Preloader';
import { getCards } from '../../../../services/dahsboard/scientometric/minciencias/minciencias';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
require('jquery-mousewheel');

/**
 * MENSAJES PERSONALIZADOS AL BUSCAR O CARGAR OPCIONES EN REACT SELECT
 */

const { NoOptionsMessage } = components;

const customNoOptionsMessage = props => (
  <NoOptionsMessage {...props}>No registrado</NoOptionsMessage>
);

const { LoadingMessage } = components;

const customLoadingMessage = props => (
  <LoadingMessage {...props}>Cargando</LoadingMessage>
);

/**
 * ANIMATE DELETE MULTISELECT
 */

const animatedComponents = makeAnimated();

/**
 * Data que llena los select
 */

const Entity = [
  { value: "opcion-uno", label: "Opcion uno" },
  { value: "opcion-dos", label: "Opcion dos" },
  { value: "opcion-tres", label: "Opcion tres" }
];

const Group = [
  { value: "opcion-uno", label: "Opcion uno" },
  { value: "opcion-dos", label: "Opcion dos" },
  { value: "opcion-tres", label: "Opcion tres" }
];

/**
 * Constante que soporta todo el cambio de los estilo del select
 */

const selectStyles = {
  /**
   * Estilos del icono del dropdown del select
   * Estilos del separador del select
   * Estilos del icono de cerrar del select
   */
  dropdownIndicator: (styles) => ({ ...styles, 
    color: "var(--color-black-)", 
    padding: 0, 
    paddingTop: '0rem !important', 
    paddingRight: '0.5rem !important',
    "&:hover": {
      color: "var(--color-black-)",
    } 
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  clearIndicator: (styles) => ({ ...styles, 
    color: "var(--color-black-)", 
    padding: 0, 
    paddingTop: '0rem !important',
    "&:hover": {
      color: "var(--color-black-)",
    } 
  }),
  /**
   * Estilos del input global
   */
  control: () => ({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    alignSelf: "start",
    justifyContent: "start",
    height: 'auto',
    minHeight: 50,
    maxHeight: 150,
    paddingLeft: '2.1rem',
    paddingTop: '0.3rem',
    width: "100%",
    backgroundColor: 'var(--color-quinary-white-)',
    borderRadius: "1.18rem",
    border: "1px solid var(--color-white-)",
  }),
  /**
  * EESTILOS DEL INPUT
  */
  input: (provided) => ({
    ...provided,
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  /**
   * Estilos del menu desplegable del select
   */
  menu: (styles) => ({
    ...styles,
    border: 'none',
    borderColor: 'var(--color-quaternary)',
    backgroundColor: 'var(--color-tertiary-white-rgba-)',
    boxShadow: 'var(--box-shadow-12-)',
    borderRadius: '1rem',
    padding: 0,
    marginTop: 8,
    marginBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "hidden",
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent !important"
    }
  }),
  /**
   * Estilos de las opciones desplegables
   */
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: 'var(--color-gray-)',
    backgroundColor: state.isSelected ? "var(--color-sextary-white-)" : "var(--color-tertiary-white-rgba-)",
    fontFamily: 'var(--font-family-regular-)',
    padding: '0.5rem 0.8rem 0.5rem 0.8rem',
    borderRadius: '1rem',
    ":hover": {
      background: "var(--color-sextary-white-)",
      color: 'var(--color-black-)',
    }
  }),
  /**
   * Estilos del contenedor
   */
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    position: 'relative',
    flex: '1 1 auto'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    paddingTop: '0rem !important',
    marginLeft: '8px',
    paddingRight: 0
  }),
  /**
   * Estilos placeholder del input
   */
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -40 : "26%",
    left: state.hasValue || state.selectProps.inputValue ? -32 : "0%",
    transition: "top 0.1s, font-size 0.1s",
    color: 'var(--color-gray-)',
    fontSize: state.hasValue || state.selectProps.inputValue ? 13 : "14px",
    lineHeight: 1.25,
    fontFamily: 'var(--font-family-regular-)',
    overflow: 'hidden',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  /**
   * Estilos texto en el input
   */
  singleValue: (styles) => ({ 
    ...styles, 
    fontSize: 14,
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    padding: '3px',
    margin: '0px',
    marginTop: '2px',
    marginLeft: 0,
    marginRight: 0
  })
}

export default function GrupLac() {
  const { token, setToken, cards, setCards, optionsInstitutions, setOptionsInstitutions } = useContext(Appcontext);
  const navigate = useNavigate();
  const [charging, setCharging] = useState(false);
  const [selectInstitutions, setSelectInstitutions] = useState('');
  const [optionsGroups, setOptionsGroups] = useState(null);
  const [selectGroups, setSelectGroups] = useState({
    institution: '',
    group: '',
    code: ''
  });
  const [institutionsFlag, setInstitutionsFlag] = useState(false);
  const [groupsFlag, setGroupsFlag] = useState(false);
  const [graphicInstitutions, setGraphicInstitutions] = useState(null);
  const [graphicGroups, setGraphicGroups] = useState(null);
  const [tablesGroups, setTablesGroups] = useState({});
  const [tableInstitutionsArticles, setTableInstitutionsArticles] = useState([]);
  const [tableInstitutionsGroups, setTableInstitutionsGroups] = useState([]);
  const [searchTableGroupsInstitution, setSearchTableGroupsInstitution] = useState('');
  const [searchTableArticlesInstitution, setSearchTableArticlesInstitution] = useState('');
  const [searchTables, setSearchtables] = useState({
    table1: '',
    table2: '',
    table3: '',
    table4: '',
    table5: '',
    table6: '',
    table7: '',
    table8: '',
    table9: '',
    table10: '',
  });

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    if (graphicInstitutions && institutionsFlag) {
      configurations(graphicInstitutions);
    }
  }, [graphicInstitutions, institutionsFlag]);

  useEffect(() => {
    if (graphicGroups && groupsFlag) {
      configurationsGroupInstitution(graphicGroups);
    }
  }, [graphicGroups, groupsFlag]);

  const getServices = async () => {
    if (!cards) {
      await getCardsInfo();
    }
    if (!optionsInstitutions) {
      await getInsitutionsOptions();
    }
  };

  const getCardsInfo = async () => {
    setCharging(true);
    let response = await getCards(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCards(data);
      setCharging(false);
    }
  };

  const getInsitutionsOptions = async () => {
    setCharging(true);
    let response = await getOptionsInstitutions(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las instituciones',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      fillOptions(data);
    }
  };

  const getGroupsOptions = async (institution) => {
    setCharging(true);
    let response = await getOptionsGroups(institution, token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      fillOptionsGroups(data);
    }
  };

  const fillOptions = (data) => {
    let array = [];
    data.map(option => {
      array.push({
        ... option,
        label: option.institucion,
        value: option.institucion
      });
    });
    setOptionsInstitutions(array);
  };

  const fillOptionsGroups = (data) => {
    let array = [];
    data.map(option => {
      array.push({
        ... option,
        label: option.nombre_grupo,
        value: option.nombre_grupo
      });
    });
    setOptionsGroups(array);
  };

  const changeSelect = async ({ value }) => {
    setSelectInstitutions(value ? value : '');
    if (value) {
      await getGraphsInstitutionsSelected(value);
      await getTablesInstitutionsSelected(value);
    }
  };

  const changeSelectGroups = async (e, type) => {
    if (type === 'institution') {
      setGroupsFlag(false);
      setSelectGroups({
        group: '',
        institution: e.value ? e.value : ''
      });
      if (e.value) {
        await getGroupsOptions(e.value);
      } else {
        setOptionsGroups(null);
      }
    }
    else {
      setSelectGroups({
        ... selectGroups,
        group: e.value ? e.value : '',
        code: e.codigo_grupo ? e.codigo_grupo : ''
      });
      if (e.value) {
        let object = {
          ... selectGroups,
          group: e.value ? e.value : '',
          code: e.codigo_grupo ? e.codigo_grupo : ''
        };
        await getGraphsGroupsSelected(object);
        await getTablesGroupsSelected(object);
      }
    }
  };

  const getGraphsInstitutionsSelected = async (value) => {
    setCharging(true);
    let response = await getGraphsGrup(value, token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de los gráficos',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      setGraphicInstitutions(data);
    }
  };

  const getTablesInstitutionsSelected = async (value) => {
    setInstitutionsFlag(false);
    setGroupsFlag(false);
    setCharging(true);
    let response = await getTablesGrup(value, token).catch(error => {
      setInstitutionsFlag(false);
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tablas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      setInstitutionsFlag(true);
      setTableInstitutionsGroups(data.find(chart => chart.nombre === 'informacionGeneralGrupos_tabla_institucion'));
      setTableInstitutionsArticles(data.find(chart => chart.nombre === 'informacionGeneralArticulos_tabla_institucion'));
    }
  };

  const getGraphsGroupsSelected = async (value) => {
    setCharging(true);
    let response = await getGraphsGroupInstitution(value, token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tablas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      setGraphicGroups(data);
    }
  };

  const getTablesGroupsSelected = async (value) => {
    setGroupsFlag(false);
    setCharging(true);
    let response = await getTablesGroupInstitution(value, token).catch(error => {
      setInstitutionsFlag(false);
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tablas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      setGroupsFlag(true);
      let object = {};
      object.generalInformationGroups = data.find(table => table.nombre === 'informacionGeneralGrupos_tabla_institucion_grupo');
      object.generalInformationArticles = data.find(table => table.nombre === 'informacionGeneralArticulos_tabla_institucion_grupo');
      object.researchersProfile = data.find(table => table.nombre === 'investigadores_tabla_institucion_grupo');
      object.collaboration = data.find(table => table.nombre === 'colaboracion_tabla_institucion_grupo');
      object.trayectory = data.find(table => table.nombre === 'trayectoria_tabla_institucion_grupo');
      object.production = data.find(table => table.nombre === 'estabilidadProduccion_tabla_institucion_grupo');
      object.knowledge = data.find(table => table.nombre === 'generacionNueva_tabla_institucion_grupo');
      object.development = data.find(table => table.nombre === 'desarrolloTecnologico_tabla_institucion_grupo');
      object.social = data.find(table => table.nombre === 'apropiacionSocial_tabla_institucion_grupo');
      object.human = data.find(table => table.nombre === 'recursoHumano_tabla_institucion_grupo');
      setTablesGroups(object);
    }
  };

  const configurations = (info) => {
    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    /**
     * Grafica cantidad de grupos por año (LINE CHART)
     */

    
    let chartYearinstitution = info.find(chart => chart.nombre === 'anio_linea_institucion');

    let chartGruplacInstitutionsOne = echarts.init(document.getElementById('chart-gruplac-institutions-one-'));
    let optionGruplacInstitutionsOne;

    optionGruplacInstitutionsOne = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Cantidad de grupos por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartYearinstitution.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'Cantidad de artículos',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
              label: {
                  show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartYearinstitution.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsOne && chartGruplacInstitutionsOne.setOption(optionGruplacInstitutionsOne);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsOne != null && chartGruplacInstitutionsOne !== undefined){
        chartGruplacInstitutionsOne.resize();
      }
    });

    /**
     * Grafica clasificación de revistas Scimago (PIE CHART)
     */

    let chartScimagoInstitution = info.find(chart => chart.nombre === 'sjr_q_pastel_institucion');

    let chartGruplacInstitutionsTwo = echarts.init(document.getElementById('chart-gruplac-institutions-two-'));
    let optionGruplacInstitutionsTwo;

    optionGruplacInstitutionsTwo = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de revistas Scimago',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: Math.max(... chartScimagoInstitution.valores.X),
        max: Math.min(... chartScimagoInstitution.valores.X),
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
                opacity: 1,
                shadowOffsetX: 0,
                shadowOffsetY: 0
            }
          },
          data: chartScimagoInstitution.valores.X.map((chart, index) => {
            return {value: chart, name: chartScimagoInstitution.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsTwo && chartGruplacInstitutionsTwo.setOption(optionGruplacInstitutionsTwo);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsTwo != null && chartGruplacInstitutionsTwo !== undefined){
        chartGruplacInstitutionsTwo.resize();
      }
    });

    /**
    * Grafica clasificación de revistas Publindex (PIE CHART)
    */

    let chartPublindexInstitution = info.find(chart => chart.nombre === 'publindex_pastel_institucion');

    let chartGruplacInstitutionsThree = echarts.init(document.getElementById('chart-gruplac-institutions-three-'));
    let optionGruplacInstitutionsThree;

    optionGruplacInstitutionsThree = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de revistas Publindex',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: Math.min(... chartPublindexInstitution.valores.X),
        max: Math.max(... chartPublindexInstitution.valores.X),
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: chartPublindexInstitution.valores.X.map((chart, index) => {
            return {value: chart, name: chartPublindexInstitution.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsThree && chartGruplacInstitutionsThree.setOption(optionGruplacInstitutionsThree);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsThree != null && chartGruplacInstitutionsThree !== undefined){
        chartGruplacInstitutionsThree.resize();
      }
    });

    /**
     * Grafica comparación de revistas Scimago (BAR CHART)
     */

    let scimagoComparation = info.find(chart => chart.nombre === 'sjr_q_barra_institucion');

    let chartGruplacInstitutionsFour = echarts.init(document.getElementById('chart-gruplac-institutions-four-'));
    let optionGruplacInstitutionsFour;

    optionGruplacInstitutionsFour = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de los investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        max: Math.max(... scimagoComparation.valores.Y),
        min: Math.min(... scimagoComparation.valores.Y),
        text: ['', ''],
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'category',
        data: scimagoComparation.valores.X,
        name: '',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'value',
        name: '',
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
              newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      series: [
        {
          type: 'bar',
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
          },
          data: scimagoComparation.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsFour && chartGruplacInstitutionsFour.setOption(optionGruplacInstitutionsFour);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsFour != null && chartGruplacInstitutionsFour !== undefined){
        chartGruplacInstitutionsFour.resize();
      }
    });

    /**
    * Grafica información de artículos: tipo de producto (PIE CHART)
    */

    let publindexComparation = info.find(chart => chart.nombre === 'publindex_barra_institucion');

    let chartGruplacInstitutionsFourFive = echarts.init(document.getElementById('chart-gruplac-institutions-four-five-'));
    let optionGruplacInstitutionsFourFive;

    optionGruplacInstitutionsFourFive = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de los investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        max: Math.max(... publindexComparation.valores.Y),
        min: Math.min(... publindexComparation.valores.Y),
        text: ['', ''],
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'category',
        data: publindexComparation.valores.X,
        name: '',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'value',
        name: '',
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
              newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      series: [
        {
          type: 'bar',
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
          },
          data: publindexComparation.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsFourFive && chartGruplacInstitutionsFourFive.setOption(optionGruplacInstitutionsFourFive);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsFourFive != null && chartGruplacInstitutionsFourFive !== undefined){
        chartGruplacInstitutionsFourFive.resize();
      }
    });

    /**
    * Grafica información de artículos: tipo de producto (PIE CHART)
    */

    let articleType = info.find(chart => chart.nombre === 'tipo_articulo_pastel_institucion');

    let chartGruplacInstitutionsSeven = echarts.init(document.getElementById('chart-gruplac-institutions-seven-'));
    let optionGruplacInstitutionsSeven;

    optionGruplacInstitutionsSeven = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Información de artículos tipo de producto',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: Math.min(... articleType.valores.X),
        max: Math.max(... articleType.valores.X),
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: articleType.valores.X.map((chart, index) => {
            return {value: chart, name: articleType.valores.etiquetas[index]}
          })
          /*[
            {value: 40, name: 'Categoría C'},
            {value: 38, name: 'Categoría B'},
            {value: 32, name: 'Categoría A'},
            {value: 30, name: 'Categoría A1'},
            {value: 28, name: 'Categoría 00'},
            {value: 20, name: 'Grupo reconocido'}
          ]*/,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsSeven && chartGruplacInstitutionsSeven.setOption(optionGruplacInstitutionsSeven);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsSeven != null && chartGruplacInstitutionsSeven !== undefined){
        chartGruplacInstitutionsSeven.resize();
      }
    });

    /**
     * Grafica clasificación de investigadores (BAR CHART)
     */

    let categoryInstitution = info.find(chart => chart.nombre === 'categoria_barra_institucion');

    let chartGruplacInstitutionsTen = echarts.init(document.getElementById('chart-gruplac-institutions-ten-'));
    let optionGruplacInstitutionsTen;

    optionGruplacInstitutionsTen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: categoryInstitution.valores.Y
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: Math.min(... categoryInstitution.valores.Y),
        max: Math.max(... categoryInstitution.valores.Y),
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      dataset: {
        source: [
          ['amount', 'product'],
          [58212, 'Sin clasificar'],
          [78254, 'Investigador junior'],
          [41032, 'CvLAC oculto'],
          [12755, 'Investigador asociado'],
          [20145, 'Investigador senior'],
          [79146, 'Investigador emérito']
        ]
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Cantidad de investigadores',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Clasificación',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: categoryInstitution.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: categoryInstitution.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsTen && chartGruplacInstitutionsTen.setOption(optionGruplacInstitutionsTen);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsTen != null && chartGruplacInstitutionsTen !== undefined){
        chartGruplacInstitutionsTen.resize();
      }
    });

    /**
    * Grafica formación de investigadores (PIE CHART)
    */

    let institutionPos = info.find(chart => chart.nombre === 'posgrado_pastel_institucion');

    let chartGruplacInstitutionsEleven = echarts.init(document.getElementById('chart-gruplac-institutions-eleven-'));
    let optionGruplacInstitutionsEleven;

    optionGruplacInstitutionsEleven = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Formación de investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: Math.min(... institutionPos.valores.X),
        max: Math.max(... institutionPos.valores.X),
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: institutionPos.valores.X.map((chart, index) => {
            return {value: chart, name: institutionPos.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacInstitutionsEleven && chartGruplacInstitutionsEleven.setOption(optionGruplacInstitutionsEleven);

    $(window).on('resize', function(){
      if(chartGruplacInstitutionsEleven != null && chartGruplacInstitutionsEleven !== undefined){
        chartGruplacInstitutionsEleven.resize();
      }
    });
  };

  const configurationsGroupInstitution = (info) => {
    /**
     * Grafica de la institución filtrada (LINE CHART)
     */

    let evolutionGroup = info.find(chart => chart.nombre === 'anio__linea_institucion_grupo');
    let chartGruplacGroupsTwo = echarts.init(document.getElementById('chart-gruplac-groups-two-'));
    let optionGruplacGroupsTwo;

    optionGruplacGroupsTwo = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Institución',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: evolutionGroup?.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'Cantidad de artículos',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
              label: {
                  show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: evolutionGroup?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsTwo && chartGruplacGroupsTwo.setOption(optionGruplacGroupsTwo);

    $(window).on('resize', function(){
      if(chartGruplacGroupsTwo != null && chartGruplacGroupsTwo !== undefined){
        chartGruplacGroupsTwo.resize();
      }
    });

    /**
     * Grafica clasificación de revistas Scimago (PIE CHART)
     */

    let scimagoPie = info.find(chart => chart.nombre === 'sjr_q_pastel_institucion_grupo');
    let chartGruplacGroupsThree = echarts.init(document.getElementById('chart-gruplac-groups-three-'));
    let optionGruplacGroupsThree;

    optionGruplacGroupsThree = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de revistas Scimago',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: scimagoPie?.valores.X ? Math.min(... scimagoPie?.valores.X) : 0,
        max: scimagoPie?.valores.X ? Math.max(... scimagoPie?.valores.X) : 1,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: scimagoPie?.valores.X.map((chart, index) => {
            return {value: chart, name: scimagoPie.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsThree && chartGruplacGroupsThree.setOption(optionGruplacGroupsThree);

    $(window).on('resize', function(){
      if(chartGruplacGroupsThree != null && chartGruplacGroupsThree !== undefined){
        chartGruplacGroupsThree.resize();
      }
    });

    /**
   * Grafica clasificación de revistas Publindex (PIE CHART)
   */

    let publindexPie = info.find(chart => chart.nombre === 'publindex_pastel_institucion_grupo');
    let chartGruplacGroupsFour = echarts.init(document.getElementById('chart-gruplac-groups-four-'));
    let optionGruplacGroupsFour;

    optionGruplacGroupsFour = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de revistas Publindex',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: publindexPie?.valores.X ? Math.min(... publindexPie?.valores.X) : 0,
        max: publindexPie?.valores.X ? Math.max(... publindexPie?.valores.X) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: publindexPie?.valores.X.map((chart, index) => {
            return {value: chart, name: publindexPie.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsFour && chartGruplacGroupsFour.setOption(optionGruplacGroupsFour);

    $(window).on('resize', function(){
      if(chartGruplacGroupsFour != null && chartGruplacGroupsFour !== undefined){
        chartGruplacGroupsFour.resize();
      }
    });

    /**
     * Grafica comparación de revistas Scimago (BAR CHART)
     */

    let scimagoBar = info.find(chart => chart.nombre === 'sjr_q_barra_institucion_grupo');
    let chartGruplacGroupsFive = echarts.init(document.getElementById('chart-gruplac-groups-five-'));
    let optionGruplacGroupsFive;

    optionGruplacGroupsFive = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de los investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        max: scimagoBar?.valores.Y ? Math.max(... scimagoBar.valores.Y) : 0,
        min: scimagoBar?.valores.Y ? Math.min(... scimagoBar.valores.Y) : 0,
        text: ['', ''],
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'category',
        data: scimagoBar?.valores.X,
        name: '',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'value',
        name: '',
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
              newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      series: [
        {
          type: 'bar',
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
          },
          data: scimagoBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsFive && chartGruplacGroupsFive.setOption(optionGruplacGroupsFive);

    $(window).on('resize', function(){
      if(chartGruplacGroupsFive != null && chartGruplacGroupsFive !== undefined){
        chartGruplacGroupsFive.resize();
      }
    });
    
    /**
     * Grafica comparación de revistas Scimago (BAR CHART)
     */

    let publindexBar = info.find(chart => chart.nombre === 'publindex_barra_institucion_grupo');
    let chartGruplacGroupsFourFive = echarts.init(document.getElementById('chart-gruplac-groups-four-five-'));
    let optionGruplacGroupsFourFive;

    optionGruplacGroupsFourFive = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de los investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        max: publindexBar?.valores.Y ? Math.max(... publindexBar.valores.Y) : 0,
        min: publindexBar?.valores.Y ? Math.min(... publindexBar.valores.Y) : 0,
        text: ['', ''],
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'category',
        data: publindexBar?.valores.X,
        name: '',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'value',
        name: '',
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
              newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      series: [
        {
          type: 'bar',
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
          },
          data: publindexBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsFourFive && chartGruplacGroupsFourFive.setOption(optionGruplacGroupsFourFive);

    $(window).on('resize', function(){
      if(chartGruplacGroupsFourFive != null && chartGruplacGroupsFourFive !== undefined){
        chartGruplacGroupsFourFive.resize();
      }
    });

    /**
   * Grafica información de artículos: tipo de producto (PIE CHART)
   */

    let articleType = info.find(chart => chart.nombre === 'tipo_articulo_pastel_institucion_grupo');
    let chartGruplacGroupsEight = echarts.init(document.getElementById('chart-gruplac-groups-eight-'));
    let optionGruplacGroupsEight;

    optionGruplacGroupsEight = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Información de artículos tipo de producto',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: articleType?.valores.X ? Math.min(... articleType.valores.X) : 0,
        max: articleType?.valores.X ? Math.max(... articleType.valores.X) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: articleType?.valores.X.map((chart, index) => {
            return {value: chart, name: articleType.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
          return idx * 5;
      }
    };

    optionGruplacGroupsEight && chartGruplacGroupsEight.setOption(optionGruplacGroupsEight);

    $(window).on('resize', function(){
      if(chartGruplacGroupsEight != null && chartGruplacGroupsEight !== undefined){
        chartGruplacGroupsEight.resize();
      }
    });

    /**
    * Grafica clasificación de investigadores (BAR CHART)
    */

    let classificationGroup = info.find(chart => chart.nombre === 'categoria_barra_institucion_grupo');
    let chartGruplacGroupsEleven = echarts.init(document.getElementById('chart-gruplac-groups-eleven-'));
    let optionGruplacGroupsEleven;

    optionGruplacGroupsEleven = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: classificationGroup?.valores.Y
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: classificationGroup?.valores.Y ? Math.min(... classificationGroup.valores.Y) : 0,
        max: classificationGroup?.valores.Y ? Math.max(... classificationGroup.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
    },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Cantidad de investigadores',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Clasificación',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: classificationGroup?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: classificationGroup?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsEleven && chartGruplacGroupsEleven.setOption(optionGruplacGroupsEleven);

    $(window).on('resize', function(){
      if(chartGruplacGroupsEleven != null && chartGruplacGroupsEleven !== undefined){
        chartGruplacGroupsEleven.resize();
      }
    });

    /**
   * Grafica formación de investigadores (PIE CHART)
   */

    let researchersFormation = info.find(chart => chart.nombre === 'posgrado_pastel_institucion_grupo');
    let chartGruplacGroupsTwelve = echarts.init(document.getElementById('chart-gruplac-groups-twelve-'));
    let optionGruplacGroupsTwelve;

    optionGruplacGroupsTwelve = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Formación de investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: researchersFormation?.valores.X ? Math.min(... researchersFormation.valores.X) : 0,
        max: researchersFormation?.valores.X ? Math.max(... researchersFormation.valores.X) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: researchersFormation?.valores.X.map((chart, index) => {
            return {value: chart, name: researchersFormation.valores.etiquetas[index]}
          }),
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsTwelve && chartGruplacGroupsTwelve.setOption(optionGruplacGroupsTwelve);

    $(window).on('resize', function(){
      if(chartGruplacGroupsTwelve != null && chartGruplacGroupsTwelve !== undefined){
        chartGruplacGroupsTwelve.resize();
      }
    });

    /**
     * Grafica investigadores (BAR CHART)
     */

    let researchersBar = info.find(chart => chart.nombre === 'investigadores_barra_institucion_grupo');
    let chartGruplacGroupsThirteen = echarts.init(document.getElementById('chart-gruplac-groups-thirteen-'));
    let optionGruplacGroupsThirteen;

    optionGruplacGroupsThirteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: researchersBar?.valores.Y ? Math.min(... researchersBar.valores.Y) : 0,
        max: researchersBar?.valores.Y ? Math.max(... researchersBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
    },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: researchersBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: researchersBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsThirteen && chartGruplacGroupsThirteen.setOption(optionGruplacGroupsThirteen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsThirteen != null && chartGruplacGroupsThirteen !== undefined){
        chartGruplacGroupsThirteen.resize();
      }
    });

    /**
     * Grafica Colaboración (BAR CHART)
     */

    let colaborationBar = info.find(chart => chart.nombre === 'colaboracion_barra_institucion_grupo');
    let chartGruplacGroupsFourteen = echarts.init(document.getElementById('chart-gruplac-groups-fourteen-'));
    let optionGruplacGroupsFourteen;

    optionGruplacGroupsFourteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Colaboración',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: colaborationBar?.valores.Y ? Math.min(... colaborationBar.valores.Y) : 0,
        max: colaborationBar?.valores.Y ? Math.max(... colaborationBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: colaborationBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: colaborationBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsFourteen && chartGruplacGroupsFourteen.setOption(optionGruplacGroupsFourteen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsFourteen != null && chartGruplacGroupsFourteen !== undefined){
        chartGruplacGroupsFourteen.resize();
      }
    });

    /**
     * Grafica trayectoria (BAR CHART)
     */

    let trayectoryBar = info.find(chart => chart.nombre === 'trayectoria_barra_institucion_grupo');
    let chartGruplacGroupsFifteen = echarts.init(document.getElementById('chart-gruplac-groups-fifteen-'));
    let optionGruplacGroupsFifteen;

    optionGruplacGroupsFifteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Trayectoria',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: trayectoryBar?.valores.Y ? Math.min(... trayectoryBar.valores.Y) : 0,
        max: trayectoryBar?.valores.Y ? Math.max(... trayectoryBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: trayectoryBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: trayectoryBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsFifteen && chartGruplacGroupsFifteen.setOption(optionGruplacGroupsFifteen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsFifteen != null && chartGruplacGroupsFifteen !== undefined){
        chartGruplacGroupsFifteen.resize();
      }
    });

    /**
     * Grafica estabilidad de producción (BAR CHART)
     */

    let productionBar = info.find(chart => chart.nombre === 'estabilidadProduccion_barra_institucion_grupo');
    let chartGruplacGroupsSixteen = echarts.init(document.getElementById('chart-gruplac-groups-sixteen-'));
    let optionGruplacGroupsSixteen;

    optionGruplacGroupsSixteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Estabilidad de producción',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: productionBar?.valores.Y ? Math.min(... productionBar.valores.Y) : 0,
        max: productionBar?.valores.Y ? Math.max(... productionBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: productionBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: productionBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsSixteen && chartGruplacGroupsSixteen.setOption(optionGruplacGroupsSixteen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsSixteen != null && chartGruplacGroupsSixteen !== undefined){
        chartGruplacGroupsSixteen.resize();
      }
    });

    /**
     * Grafica generación del nuevo conocimiento (BAR CHART)
     */

    let knowledgeBar = info.find(chart => chart.nombre === 'generacionNueva_barra_institucion_grupo');
    let chartGruplacGroupsSeventeen = echarts.init(document.getElementById('chart-gruplac-groups-seventeen-'));
    let optionGruplacGroupsSeventeen;

    optionGruplacGroupsSeventeen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Generación del nuevo conocimiento',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: knowledgeBar?.valores.Y ? Math.min(... knowledgeBar.valores.Y) : 0,
        max: knowledgeBar?.valores.Y ? Math.max(... knowledgeBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: knowledgeBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: knowledgeBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsSeventeen && chartGruplacGroupsSeventeen.setOption(optionGruplacGroupsSeventeen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsSeventeen != null && chartGruplacGroupsSeventeen !== undefined){
        chartGruplacGroupsSeventeen.resize();
      }
    });

    /**
     * Grafica generación del nuevo conocimiento (BAR CHART)
     */

    let developmentBar = info.find(chart => chart.nombre === 'desarrolloTecnologico_barra_institucion_grupo');
    let chartGruplacGroupsEighteen = echarts.init(document.getElementById('chart-gruplac-groups-eighteen-'));
    let optionGruplacGroupsEighteen;

    optionGruplacGroupsEighteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Generación del nuevo conocimiento',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: developmentBar?.valores.Y ? Math.min(... developmentBar.valores.Y) : 0,
        max: developmentBar?.valores.Y ? Math.max(... developmentBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: developmentBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data:developmentBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsEighteen && chartGruplacGroupsEighteen.setOption(optionGruplacGroupsEighteen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsEighteen != null && chartGruplacGroupsEighteen !== undefined){
        chartGruplacGroupsEighteen.resize();
      }
    });

    /**
     * Grafica generación del nuevo conocimiento (BAR CHART)
     */

    let socialBar = info.find(chart => chart.nombre === 'apropiacionSocial_barra_institucion_grupo');
    let chartGruplacGroupsNineteen = echarts.init(document.getElementById('chart-gruplac-groups-nineteen-'));
    let optionGruplacGroupsNineteen;

    optionGruplacGroupsNineteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Generación del nuevo conocimiento',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: socialBar?.valores.Y ? Math.min(... socialBar.valores.Y) : 0,
        max: socialBar?.valores.Y ? Math.max(... socialBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: socialBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: socialBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsNineteen && chartGruplacGroupsNineteen.setOption(optionGruplacGroupsNineteen);

    $(window).on('resize', function(){
      if(chartGruplacGroupsNineteen != null && chartGruplacGroupsNineteen !== undefined){
        chartGruplacGroupsNineteen.resize();
      }
    });

    /**
     * Grafica generación del nuevo conocimiento (BAR CHART)
     */

    let humanBar = info.find(chart => chart.nombre === 'recursoHumano_barra_institucion_grupo');
    let chartGruplacGroupsTwenty = echarts.init(document.getElementById('chart-gruplac-groups-twenty-'));
    let optionGruplacGroupsTwenty;

    optionGruplacGroupsTwenty = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Generación del nuevo conocimiento',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: humanBar?.valores.Y ? Math.min(... humanBar.valores.Y) : 0,
        max: humanBar?.valores.Y ? Math.max(... humanBar.valores.Y) : 0,
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Valor indicador',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Indicador',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: humanBar?.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          data: humanBar?.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionGruplacGroupsTwenty && chartGruplacGroupsTwenty.setOption(optionGruplacGroupsTwenty);

    $(window).on('resize', function(){
      if(chartGruplacGroupsTwenty != null && chartGruplacGroupsTwenty !== undefined){
        chartGruplacGroupsTwenty.resize();
      }
    });
  };

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);
  
  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='row'>
        <div className='col-12'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-gruplac-' src={IconGruplac} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Instituciones GrupLAC
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  En esta sección de tiene un directorio de los grupos de investigación, instituciones e investigadores que participan activamente en el desarrollo de nuevas estrategias en el ámbito de la Ciencia, la Tecnología y la Innovación, en esta sección se permite ver la producción científica del grupo basada en las hojas de vida de sus integrantes, pertenencia a más de una institución, relaciones con empresas, reportes de revisión de información, entre otras.
                </p>
              </div>
            </div>
            <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
              <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                <div className='col-12 col-sm-7 col-md-8 col-lg-8 col-xl-7 col-xxl-5'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Articulos - capitulos - libros</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        # <span>{ cards?.articulos }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Instituciones</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        + <span>{ cards?.instituciones }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='accordion row gy-4 gx-0 mt-2' id="accordionPanelsStayOpenExample">
          <div className='accordion-item'>
            <div className='d-flex flex-row justify-content-between align-items-end align-self-end justify-content-sm-between align-items-sm-end align-self-sm-end justify-content-md-between align-items-md-end align-self-md-end justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
              <div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-start align-self-center justify-content-sm-center align-items-sm-start align-self-sm-center justify-content-md-center align-items-md-start align-self-md-center justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                <h5 className='m-0 ms-4 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Instituciones</h5>
              </div>
              <h2 className='accordion-header d-flex flex-column justify-content-xxl-center align-items-xxl-center align-self-xxl-center ms-4 me-4' id="panelsStayOpen-headingThree">
                <button className='accordion-button ps-0 pe-0 pt-2 pb-2' type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne">
                </button>
              </h2>
            </div>
            <div id="panelsStayOpen-collapseOne" className='accordion-collapse collapse show' aria-labelledby="panelsStayOpen-headingOne">
              <div className='accordion-body'>
                <div className='row row-cols-auto g-4 mb-2'>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <form id='internal-form' action='' className='position-relative'>
                      <div className='row'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                          <div className='form-floating inner-addon- left-addon-'>
                            <Select id='entity' options={optionsInstitutions} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Seleccione la institución" styles={selectStyles} isClearable={true} value={{ value: selectInstitutions,label: selectInstitutions }} onChange={(e) => changeSelect(e ? e : '')} />
                              <i className='fa icon-entity icon-one-'></i>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div> 
                  {
                    institutionsFlag
                    ?
                      <>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Tabla de información de los grupos filtrado por institución
                                    </h1>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTableGroupsInstitution} onChange={({target}) => setSearchTableGroupsInstitution(target.value)} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tableInstitutionsGroups.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Nombre del grupo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Fecha creación</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Lugar de creación</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Lider</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tableInstitutionsGroups.valores.filter(table => {
                                        if (searchTableGroupsInstitution !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'nombre grupo' || keys[i] === 'lugar_creacion' || keys[i] === 'lider' || keys[i] === 'fecha_creacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTableGroupsInstitution.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTableGroupsInstitution.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTableGroupsInstitution.toUpperCase() === word.substring(0, searchTableGroupsInstitution.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['nombre grupo'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.fecha_creacion }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.lugar_creacion }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.lider }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Información de artículos
                                    </h1>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTableArticlesInstitution} onChange={({target}) => setSearchTableArticlesInstitution(target.value)} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tableInstitutionsArticles.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Título</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tipo artículo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Revista</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Año</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Autores</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    tableInstitutionsArticles.valores.filter(table => {
                                      if (searchTableArticlesInstitution !== '') {
                                        let keys = Object.keys(table);
                                        let flagKeys = false;
                                        let i = 0;
                                        while (i < keys.length && !flagKeys) {
                                          if (keys[i] === 'anio' || keys[i] === 'tipo_articulo' || keys[i] === 'revista' || keys[i] === 'titulo' || keys[i] === 'autores') {
                                            let word = '' + table[keys[i]];
                                            let words = word.split(' ');
                                            let j = 0;
                                            flagKeys = word.toUpperCase().includes(searchTableArticlesInstitution.toUpperCase());
                                            while (j < words.length && !flagKeys) {
                                              if (words[j].toUpperCase().includes(searchTableArticlesInstitution.toUpperCase())) {
                                                flagKeys = true;
                                              }
                                              j ++;
                                            }
                                            if (searchTableArticlesInstitution.toUpperCase() === word.substring(0, searchTableArticlesInstitution.length).toUpperCase()) {
                                              flagKeys = true;
                                            }
                                          }
                                          if (flagKeys) {
                                            return true;
                                          }  
                                          i ++;
                                        }
                                        
                                      } else {
                                        return true;
                                      }
                                    }).map(row => (
                                      <tr>
                                        <td className='align-middle'>
                                          <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.titulo }</p>
                                        </td>
                                        <td className='align-middle'>
                                          <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.tipo_articulo }</p>
                                        </td>
                                        <td className='align-middle'>
                                          <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.revista }</p>
                                        </td>
                                        <td className='align-middle'>
                                          <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.anio }</p>
                                        </td>
                                        <td className='align-middle'>
                                          <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.autores }</p>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*                            
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Evolución temporal producción de artículos por institución
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Número de artículos por año</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-one-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Clasificación de revistas: artículos</h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Scimago
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-two-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Publindex
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-three-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Comparación entre Scimago y Publindex</h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Scimago
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-four-'></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Publindex
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-four-five-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Información de artículos</h1>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Tipo de artículo
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-seven-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Información de investigadores: CvLAC</h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Clasificación de investigadores
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-ten-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Formación de investigadores
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-institutions-eleven-'></div>
                            </div>
                          </div>
                        </div>
                      </>
                    :
                      null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            {              
              institutionsFlag
              ?
                <div className='d-flex flex-row justify-content-between align-items-end align-self-end justify-content-sm-between align-items-sm-end align-self-sm-end justify-content-md-between align-items-md-end align-self-md-end justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                    <div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-start align-self-center justify-content-sm-center align-items-sm-start align-self-sm-center justify-content-md-center align-items-md-start align-self-md-center justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                      <h5 className='m-0 ms-4 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Grupos GrupLAC</h5>
                    </div>
                    <h2 className='accordion-header d-flex flex-column justify-content-xxl-center align-items-xxl-center align-self-xxl-center ms-4 me-4' id="panelsStayOpen-headingThree">
                      <button className='accordion-button ps-0 pe-0 pt-2 pb-2' type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseTwo">
                      </button>
                    </h2>
                  </div>
              :
                null
            }
            <div id="panelsStayOpen-collapseTwo" className='accordion-collapse collapse show' aria-labelledby="panelsStayOpen-headingTwo">
              <div className='accordion-body'>
                <div className="row row-cols-auto g-4 mb-2">
                  {
                    institutionsFlag
                    ?
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <form id='internal-form' action='' className='position-relative'>
                        <div className='row'>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                            <div className='form-floating inner-addon- left-addon-'>
                              <Select id='entity' options={optionsInstitutions} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Seleccione la institución" styles={selectStyles} isClearable={true} value={{ value: selectGroups.institution, label: selectGroups.institution }} onChange={(e) => changeSelectGroups(e ? e : '', 'institution')} />
                                <i className='fa icon-entity icon-one-'></i>
                            </div>
                          </div>
                          <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                            <div className='form-floating inner-addon- left-addon-'>
                              <Select id='group' options={optionsGroups} components={{ animatedComponents, NoOptionsMessage: customNoOptionsMessage, LoadingMessage: customLoadingMessage }} placeholder="Seleccione el grupo" styles={selectStyles} isClearable={true} value={{ value: selectGroups.group, label: selectGroups.group }} onChange={(e) => changeSelectGroups(e ? e : '', 'group')} isDisabled={!optionsGroups} />
                                <i className='fa icon-team icon-one-'></i>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div> 
                    :
                      null
                  }
                  {
                    groupsFlag
                    ?
                      <>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Tabla de información de los grupos filtrado por institución
                                    </h1>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table1} onChange={({target}) => setSearchtables({... searchTables, table1: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups?.generalInformationGroups?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Nombre del grupo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Fecha creación</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Departamento</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Lider</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>                                    
                                    {
                                      tablesGroups.generalInformationGroups?.valores.filter(table => {
                                        if (searchTables.table1 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'nombre grupo' || keys[i] === 'lugar_creacion' || keys[i] === 'lider' || keys[i] === 'fecha_creacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table1.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table1.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table1.toUpperCase() === word.substring(0, searchTables.table1.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['nombre grupo'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.fecha_creacion }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.lugar_creacion }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.lider }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*                            
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'><span>Información de los artículos</span> - <span>Nombre del grupo</span></h1>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      <span>Información de los artículos</span> - <span>Nombre del grupo</span> 
                                    </h1>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table2} onChange={({target}) => setSearchtables({... searchTables, table2: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups?.generalInformationArticles?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Título</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tipo artículo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-sm-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Revista</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Año</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Autores</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.generalInformationArticles?.valores.filter(table => {
                                        if (searchTables.table2 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'titulo' || keys[i] === 'tipo_articulo' || keys[i] === 'revista' || keys[i] === 'anio' || keys[i] === 'autores') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table2.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table2.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table2.toUpperCase() === word.substring(0, searchTables.table2.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.titulo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.tipo_articulo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.revista }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.anio }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.autores }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*                            
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Evolución temporal producción de artículos filtrado por institución y por grupo
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Número de artículos por año</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-two-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Clasificación de revistas: artículos</h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Scimago
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-three-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Publindex
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-four-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Comparación entre Scimago y Publindex</h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Scimago
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-five-'></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Publindex
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-four-five-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Información de artículos</h1>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Tipo de artículo
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-eight-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Información de investigadores: CvLAC</h1>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Clasificación de investigadores
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-eleven-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Formación de investigadores
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Área de conocimiento</p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-twelve-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Perfil del grupo: Nombre del grupo</h1>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Perfil de los investigadores
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table3} onChange={({target}) => setSearchtables({... searchTables, table3: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.researchersProfile?.valores.length}</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.researchersProfile?.valores.filter(table => {
                                        if (searchTables.table3 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table3.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table3.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table3.toUpperCase() === word.substring(0, searchTables.table3.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*                             
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>                            
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Investigadores
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-thirteen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de colaboración
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de Colaboración se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table4} onChange={({target}) => setSearchtables({... searchTables, table4: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.collaboration?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.collaboration?.valores.filter(table => {
                                        if (searchTables.table4 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table4.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table4.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table4.toUpperCase() === word.substring(0, searchTables.table4.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*                            
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Colaboración
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-fourteen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de trayectoria, permanencia y estabilidad de los investigadores
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de trayectoria se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table5} onChange={({target}) => setSearchtables({... searchTables, table5: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.trayectory?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.trayectory?.valores.filter(table => {
                                        if (searchTables.table5 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table5.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table5.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table5.toUpperCase() === word.substring(0, searchTables.table5.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*                            
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Trayectoria
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-fifteen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de estabilidad de la producción
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de trayectoria se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table6} onChange={({target}) => setSearchtables({... searchTables, table6: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.production?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.production?.valores.filter(table => {
                                        if (searchTables.table6 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table6.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table6.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table6.toUpperCase() === word.substring(0, searchTables.table6.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* 
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Estabilidad de producción
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-sixteen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de generación del nuevo conocimiento
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de trayectoria se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table7} onChange={({target}) => setSearchtables({... searchTables, table7: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.knowledge?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Subtipo producto</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.knowledge?.valores.filter(table => {
                                        if (searchTables.table7 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'subtipo_producto' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table7.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table7.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table7.toUpperCase() === word.substring(0, searchTables.table7.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.subtipo_producto }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* 
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Generación del nuevo conocimiento
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-seventeen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de desarrollo tecnológico e innovación
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de trayectoria se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table8} onChange={({target}) => setSearchtables({... searchTables, table8: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.development?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.development?.valores.filter(table => {
                                        if (searchTables.table8 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table8.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table8.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table8.toUpperCase() === word.substring(0, searchTables.table8.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* 
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Desarrollo tecnologico e innovación
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-eighteen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de apropiación social del conocimiento
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de trayectoria se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table9} onChange={({target}) => setSearchtables({... searchTables, table9: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.social?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.social?.valores.filter(table => {
                                        if (searchTables.table9 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table9.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table9.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table9.toUpperCase() === word.substring(0, searchTables.table9.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* 
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Apropiación social del conocimiento
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-nineteen-'></div>
                            </div>
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                                <div className='col d-flex flex-column'>
                                  <div className='w-auto d-flex flex-column justify-content-start align-items-start align-self-start'>
                                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                      Indicador de formación del recurso humano
                                    </h1>
                                    <p className='m-0 mt-1 p-0 lh-sm fs-6- ff-monse-regular- fw-normal tx-white-'>El valor del indicador de trayectoria se normaliza respecto al valor máximo de los cuartiles</p>
                                  </div>
                                  <div className='w-auto mt-3 '>
                                    <form action="" className='position-relative wrapper-search-small- me-3'>
                                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTables.table10} onChange={({target}) => setSearchtables({... searchTables, table10: target.value})} />
                                        <img src={IconSearch} alt="" />
                                      </div>
                                    </form>
                                  </div>
                                </div>
                                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                        <div className='card-header border-0 bg-transparent pb-0'>
                                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                        </div>
                                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                            # <span>{ tablesGroups.human?.valores.length }</span>
                                          </p>
                                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                            Registros
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='card-body p-0 w-100 wrapper-data-'>
                              <div className='table-responsive table-general-'>
                                <table className='table table-sm table-striped table-no-border- align-middle'>
                                  <thead>
                                    <tr>
                                      <th scope="col" className='th-width-md-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Mínimo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 4</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 3</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil 2</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Maximo</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Valor indicador</span>
                                        </div>
                                      </th>
                                      <th scope="col" className='th-width-auto-'>
                                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cuartil ubicación</span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      tablesGroups.human?.valores.filter(table => {
                                        if (searchTables.table10 !== '') {
                                          let keys = Object.keys(table);
                                          let flagKeys = false;
                                          let i = 0;
                                          while (i < keys.length && !flagKeys) {
                                            if (keys[i] === 'cuartil 4' || keys[i] === 'cuartil 3' || keys[i] === 'cuartil 2' || keys[i] === 'minimo' || keys[i] === 'maximo' || keys[i] === 'valor indicador' || keys[i] === 'indicador' || keys[i] === 'cuartil ubicacion') {
                                              let word = '' + table[keys[i]];
                                              let words = word.split(' ');
                                              let j = 0;
                                              flagKeys = word.toUpperCase().includes(searchTables.table10.toUpperCase());
                                              while (j < words.length && !flagKeys) {
                                                if (words[j].toUpperCase().includes(searchTables.table10.toUpperCase())) {
                                                  flagKeys = true;
                                                }
                                                j ++;
                                              }
                                              if (searchTables.table10.toUpperCase() === word.substring(0, searchTables.table10.length).toUpperCase()) {
                                                flagKeys = true;
                                              }
                                            }
                                            if (flagKeys) {
                                              return true;
                                            }  
                                            i ++;
                                          }
                                          
                                        } else {
                                          return true;
                                        }
                                      }).map(row => (
                                        <tr>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.indicador }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.minimo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 4'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 3'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil 2'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.maximo }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['valor indicador'] }</p>
                                          </td>
                                          <td className='align-middle'>
                                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row['cuartil ubicacion'] }</p>
                                          </td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/* 
                              <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                                  <div className='col-auto'>
                                    <nav>
                                      <ul className="pagination justify-content-center">
                                        {startPage !== 1 && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                          </li>
                                        )}
                                        {startPage > 2 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                          </li>
                                        ))}
                                        {endPage < totalPages - 1 && (
                                          <li className="page-item disabled">
                                            <span className="page-link">...</span>
                                          </li>
                                        )}
                                        {endPage !== totalPages && (
                                          <li className="page-item">
                                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                          </li>
                                        )}
                                      </ul>
                                    </nav>
                                  </div>
                                </div>
                              </div>
                            */}
                          </div>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Formación del recurso humano
                              </h1>
                              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                            </div>
                            <div className='card-body p-0 ps-2 pe-2 w-100'>
                              <div className='w-100 h-100 mx-auto' id='chart-gruplac-groups-twenty-'></div>
                            </div>
                          </div>
                        </div>
                      </>
                    :
                      null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
