import axios from 'axios';
import { environment } from '../../../../environments/environment';

const getGraphicsGeneralSearch = async (search, token) => {
  const path = environment.api + environment.getSearchGraphics;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  let body = {
    query: search
  }
  return await axios.post(path, body, config);
};

export { getGraphicsGeneralSearch };
