import './Pipelines.css'
import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import MachineConsumptions from './machineConsumptions/MachineConsumptions'
import FilesBioinformatic from './filesBioinformatic/FilesBioinformatic'
import ToolsBioinformaticRoutes from './toolsBioinformatic/ToolsBioinformaticRoutes'
import Results from './results/Results'

export default function PipelinesRoutes() {
  return (
    <React.Fragment>
      <div className='container-fluid overflow-x-hidden'>
        <Routes>
          <Route path="" element={ <Navigate to="machineConsumptions" /> }/>
          <Route path="machineConsumptions" element={<MachineConsumptions/>} />
          <Route path="filesBioinformatic" element={<FilesBioinformatic/>} />
          <Route path="/toolsBioinformatic/*" element={<ToolsBioinformaticRoutes/>} />
          <Route path="results" element={<Results/>} />
        </Routes>
      </div>
    </React.Fragment>
  )
}
