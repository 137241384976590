import axios from 'axios';
import { environment } from '../../environments/environment';

const getGraphicsGeneralSearchLanding = async (search) => {
  const path = environment.api + environment.postSearchLanding;
  let body = {
    query: search
  };
  return await axios.post(path, body);
};

export { getGraphicsGeneralSearchLanding };
