import Logo3bio from '../../../assets/images/logo-3bio-small.png';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select'
import makeAnimated from 'react-select/animated';
import { postRegister } from '../../../services/auth/register/register';
import Swal from 'sweetalert2';
import Preloader from '../../shared/preloader/Preloader';
import { Appcontext } from '../../../appContext';
import CryptoJS from 'crypto-js';

/**
 * MENSAJES PERSONALIZADOS AL BUSCAR O CARGAR OPCIONES EN REACT SELECT
 */

const { NoOptionsMessage } = components;

const customNoOptionsMessage = props => (
  <NoOptionsMessage {...props}>No registrado</NoOptionsMessage>
);

const { LoadingMessage } = components;

const customLoadingMessage = props => (
  <LoadingMessage {...props}>Cargando</LoadingMessage>
);

/**
* ANIMATE DELETE MULTISELECT
*/

const animatedComponents = makeAnimated();

/**
* Data que llena los select
*/

const CustomSelect = [
  { value: "opcion-uno", label: "Opcion uno" },
  { value: "opcion-dos", label: "Opcion dos" },
  { value: "opcion-tres", label: "Opcion tres" }
];

/**
* Constante que soporta todo el cambio de los estilo del select
*/

const selectStyles = {
  /**
* Estilos del icono del dropdown del select
* Estilos del separador del select
* Estilos del icono de cerrar del select
*/
  dropdownIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important', 
    paddingRight: '0.5rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
  clearIndicator: (styles) => ({ ...styles, 
    color: "var(--color-white-)", 
    padding: 0, 
    paddingTop: '0rem !important',
    "&:hover": {
      color: "var(--color-white-)",
    } 
  }),
  /**
* Estilos del input global
*/
  control: () => ({
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    alignSelf: "start",
    justifyContent: "start",
    height: 'auto',
    minHeight: 50,
    maxHeight: 150,
    paddingLeft: '15px',
    paddingTop: '0.3rem',
    width: "100%",
    backgroundColor: 'var(--color-quaternary-blue-)',
    borderRadius: "1.18rem",
    border: "0px solid transparent",
  }),
  /**
 * EESTILOS DEL INPUT
 */
  input: (provided) => ({
    ...provided,
    color: 'var(--color-quaternary-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  /**
* Estilos del menu desplegable del select
*/
  menu: (styles) => ({
    ...styles,
    border: 'none',
    borderColor: 'var(--color-quaternary)',
    backgroundColor: 'var(--color-tertiary-white-rgba-)',
    boxShadow: 'var(--box-shadow-12-)',
    borderRadius: '1rem',
    padding: 0,
    marginTop: 8,
    marginBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "hidden",
    color: 'var(--color-gray-)',
    fontSize: 14,
    fontFamily: 'var(--font-family-regular-)',
  }),
  menuList: () => ({
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 300,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0px !important",
      height: "0px !important",
    },
    "::-webkit-scrollbar-track": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent !important"
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "transparent !important"
    }
  }),
  /**
* Estilos de las opciones desplegables
*/
  option: (provided, state) => ({
    ...provided,
    fontSize: 13,
    color: 'var(--color-gray-)',
    backgroundColor: state.isSelected ? "var(--color-sextary-white-)" : "var(--color-tertiary-white-rgba-)",
    fontFamily: 'var(--font-family-regular-)',
    padding: '0.5rem 0.8rem 0.5rem 0.8rem',
    borderRadius: '1rem',
    ":hover": {
      background: "var(--color-sextary-white-)",
      color: 'var(--color-black-)',
    }
  }),
  /**
* Estilos del contenedor
*/
  container: (provided, state) => ({
    ...provided,
    marginTop: 0,
    width: '100%',
    position: 'relative',
    flex: '1 1 auto'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    color: "var(--color-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    paddingTop: '0rem !important',
    marginLeft: '0px',
    paddingRight: 0,
    padding: '0px 0px'
  }),
  /**
* Estilos placeholder del input
*/
  placeholder: (provided, state) => ({
    ...provided,
    width: '100%',
    position: "absolute",
    color: 'var(--color-quaternary-gray-)',
    lineHeight: 1.25,
    fontFamily: 'var(--font-family-regular-)',
    overflow: 'hidden',
    textAlign: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  /**
* Estilos texto en el input
*/
  singleValue: (styles) => ({ 
    ...styles, 
    fontSize: 14,
    color: "var(--color-quaternary-gray-)", 
    fontFamily: 'var(--font-family-regular-)', 
    padding: '3px',
    margin: '0px',
    marginTop: '2px',
    marginLeft: 0,
    marginRight: 0
  })
}

export default function Register() {
  const { setToken, setUserApplication, setFoldersBioinformatic } = useContext(Appcontext);
  const navigate = useNavigate();
  const [user, setUser] = useState  ({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    entity: '',
    password1: '',
    password2: ''
  });
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [charging, setCharging] = useState(false);

  useEffect(() => {
    setFoldersBioinformatic(null);
  }, []);

  const changeValue = ({ target }) =>  {
    setUser({
      ... user,
      [target.name]: target.value
    });
  };

  const conditions = () => {
    return user.name === '' || user.email === '' || user.password1.length < 8 || user.password1 !== user.password2;
  };
  
  const registerUser = async () => {
    setCharging(true);
    let response = await postRegister(user).catch(error => {
      setCharging(false);
      if (error?.response?.data?.detail === 'Usuario ya existe') {
        Swal.fire({
          icon: 'error',
          text: 'El usuario ya existe'
        });  
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Ocurrió un error. Vuelva a intentar'
        });
      }
    });
    if (response) {
      let { data } = response;
      setUserApplication(data.result);
      setToken(data.token);
      sessionStorage.setItem('token', data.token);
      setInfoStorage(data.result);
      setCharging(false);
      navigate('/dashboard');
    }
  };

  const setInfoStorage = (info) => {
    sessionStorage.setItem('UserInfo', JSON.stringify(info));
    if (user.remember) {
      let userStorage = { ... user };
      userStorage = {
        ... userStorage,
        ... info,
        password: CryptoJS.AES.encrypt(user.password1, user.user).toString()
      }
      localStorage.setItem('3BIOUserData', JSON.stringify(userStorage));
    } else {
      localStorage.setItem('3BIOUserData', '');
    }
  };

  const redirectToLogin = () => {
    navigate('/auth/login');
  };
  
  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='container-fluid overflow-x-hidden d-flex flex-column justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center justify-content-xxl-center align-items-center align-self-center vw-100 pt-5 pb-5 bg-medical- min-h-auth-'>
        <div className='row'>
          <div className='col-12 h-auto d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center align-self-center pb-4'>
              <img className='logo-3bio-small' src={Logo3bio} alt="" />
            </div>
            <div className='w-100 d-flex flex-column justify-content-start align-items-center align-self-center ps-2 pe-2 ps-sm-2 pe-sm-2 ps-md-2 pe-md-2 ps-lg-2 pe-lg-2 ps-xl-2 pe-xl-2 ps-xxl-2 pe-xxl-2 pb-3'>
            </div>
            <div className='card border-0 br-1- bg-blue-translucent- glass-effect- bs-4- p-0 card-big-auth-'>
                <div className='card-body'>
                  <form id='internal-form-inline' action='' className='position-relative'>
                    <div className='row'>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Nombre <span className='lh-sm fs-5- ff-monse-regular- fw-bold tx-red-'>*</span></label>
                        <input type="text" className='form-control' placeholder="Ingrese su nombre" name='name' value={user.name} onChange={changeValue} />
                      </div>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Apellido</label>
                        <input type="text" className='form-control' placeholder="Ingrese su apellido" name='lastName' value={user.lastName} onChange={changeValue} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Correo <span className='lh-sm fs-5- ff-monse-regular- fw-bold tx-red-'>*</span></label>
                        <input type="email" className='form-control' placeholder="Ingrese su correo" name='email' value={user.email} onChange={changeValue} />
                      </div>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Teléfono</label>
                        <input type="tel" className='form-control' placeholder="Ingrese su teléfono" name='phone' value={user.phone} onChange={changeValue} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Entidad</label>
                        <input type="text" className='form-control' placeholder="Ingrese su entidad" name='entity' value={user.entity} onChange={changeValue} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <div className='inner-addon- right-addon-'>
                          <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Contraseña <span className='lh-sm fs-5- ff-monse-regular- fw-bold tx-red-'>*</span></label>
                          <input type={ showPass1 ? 'text' : 'password'} className='form-control' placeholder="Ingrese su contraseña" name='password1' value={user.password1} onChange={changeValue} />
                          <i className='fa icon-hide-password fs-xs' onClick={ () => setShowPass1(!showPass1) } ></i>
                          {
                            user.password1.length < 8
                            ?
                            <span className='h-sm fs-6- ff-monse-regular- fw-normal validation-error-'>Debe tener al menos 8 caracteres</span>
                            :
                            null
                          }
                        </div>
                      </div>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <div className='inner-addon- right-addon-'>
                          <label class="form-label lh-sm fs-5- ff-monse-regular- fw-bold tx-white-">Repetir contraseña <span className='lh-sm fs-5- ff-monse-regular- fw-bold tx-red-'>*</span></label>
                          <input type="password" className='form-control' placeholder="Ingrese su contraseña" name='password2' value={user.password2} onChange={changeValue} />
                          <i className='fa icon-hide-password fs-xs' onClick={ () => setShowPass2(!showPass2) } ></i>
                          {
                            user.password1 !== user.password2
                            ?
                            <span className='h-sm fs-6- ff-monse-regular- fw-normal validation-error-'>Las contraseñas deben ser iguales</span>
                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                    <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start mt-3'>
                      <div className='col-12 col-sm-12 col-md-9 col-lg-8 col-xl-8 col-xxl-8'>
                        <button className='btn rounded-pill d-flex flex-row justify-content-center align-items-center align-self-center w-100 btn-green-light- h-40-' type="button" onClick={registerUser} disabled={conditions()} >
                          <span className='lh-1 fs-6- ff-monse-regular- fw-semibold'>Crear cuenta</span>
                        </button>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex flex-row justify-content-center align-items-center align-self-center'>
                        <span className='me-2 fs-6- ff-monse-regular- fw-semibold tx-white-'>Ya tienes una cuenta? </span>
                        <button className='btn rounded-4 p-2 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-transparent-green-' type="button" onClick={redirectToLogin} >
                            <span className='lh-1 fs-6- ff-monse-regular-'>Ingresa aquí</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
