import axios from 'axios';
import { environment } from '../../../environments/environment';

const postForgot = async (user) => {
  const path = environment.api + environment.postForgot;
  let config = {};
  let body = {
    email: user.email
  };
  return await axios.post(path, body, config);
};

export { postForgot };
