import Logo3bio from '../../../assets/images/logo-3bio-small.png';
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { postLogin } from '../../../services/auth/login/login';
import Preloader from '../../shared/preloader/Preloader';
import { Appcontext } from '../../../appContext';
import CryptoJS from 'crypto-js';

export default function Login() {
  const { setToken, setUserApplication, setFoldersBioinformatic } = useContext(Appcontext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [charging, setCharging] = useState(false);
  const [user, setUser] = useState({
    user: '',
    password: '',
    remember: false
  });

  useEffect(() => {
    setFoldersBioinformatic(null);
    let localUser = localStorage.getItem('3BIOUserData');
    if (localUser && localUser !== '') {
      let userStorage = JSON.parse(localUser);
      setUser({
        user: userStorage.user,
        password: CryptoJS.AES.decrypt(userStorage.password, userStorage.user).toString(CryptoJS.enc.Utf8),
        remember: true
      });
    }
  }, []);

  const redirectToRegister = () => {
    navigate('/auth/register');
  };

  const redirectToForgot = () => {
    navigate('/auth/forgot');
  };

  const changeValue = ({ target }) => {
    setUser({
      ... user,
      [target.name]: target.value
    });
  };

  const login = async () => {
    setCharging(true);
    let response = await postLogin(user).catch(error => {
      setCharging(false);
      if (error?.response?.data?.detail === 'Usuario o contraseña incorrecto') {
        Swal.fire({
          icon: 'error',
          text: 'Usuario o contraseña incorrecto'
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Ocurrió un error. Vuelva a intentar'
        });
      }      
    });
    if (response) {
      let { data } = response;
      setUserApplication(data.user_data);
      setToken(data.access_token);
      sessionStorage.setItem('token', data.access_token);
      setInfoStorage(data.user_data);
      setCharging(false);
      navigate('/dashboard');
    }
  };

  const setInfoStorage = (info) => {
    sessionStorage.setItem('UserInfo', JSON.stringify(info));
    if (user.remember) {
      let userStorage = { ... user };
      userStorage = {
        ... userStorage,
        password: CryptoJS.AES.encrypt(user.password, user.user).toString()
      }
      localStorage.setItem('3BIOUserData', JSON.stringify(userStorage));
    } else {
      localStorage.setItem('3BIOUserData', '');
    }
  };

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='container-fluid overflow-x-hidden d-flex flex-column justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center justify-content-xxl-center align-items-center align-self-center vw-100 pt-5 pb-5 bg-medical- min-h-auth-'>
        <div className='row'>
          <div className='col-12 h-auto d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center align-self-center pb-4'>
              <img className='logo-3bio-small' src={Logo3bio} alt="" />
            </div>
            <div className='w-100 d-flex flex-column justify-content-start align-items-center align-self-center ps-2 pe-2 ps-sm-2 pe-sm-2 ps-md-2 pe-md-2 ps-lg-2 pe-lg-2 ps-xl-2 pe-xl-2 ps-xxl-2 pe-xxl-2 pb-3'>
            </div>
            <div className='card border-0 br-1- bg-blue-translucent- glass-effect- bs-4- p-0 card-small-auth-'>
                <div className='card-body'>
                  <form id='internal-form-inline' action='' className='position-relative'>
                    <div className='row'>
                      <div className='col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Usuario</label>
                        <input type="text" className='form-control' placeholder="Ingrese su usuario" name='user' value={user.user} onChange={changeValue} />
                      </div>
                      <div className='col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <div className='inner-addon- right-addon-'>
                          <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Contraseña</label>
                          <input type={showPassword ? 'text' : 'password'} className='form-control' placeholder="Ingrese su contraseña" name='password' value={user.password} onChange={changeValue} />
                          <i className='fa icon-hide-password fs-xs' onClick={ () => setShowPassword(!showPassword) }></i>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-12 d-flex flex-row justify-content-between align-items-center align-self-center'>
                        <p className='p-0 m-0 fs-6- ff-monse-regular- fw-normal tx-white-'>Recordar mi contraseña</p>
                        <div className='d-flex flex-row justify-content-start align-items-center align-self-center wrapper-type-project-toggle-'
                          id='tabTypeProject' role="tablist">
                          <input id='off' name='remember' type="radio" defaultChecked checked={user.remember} onClick={ () => setUser({ ... user, remember: false })} />
                          <input id='on' name='remember' type="radio" checked={user.remember} onClick={ () => setUser({ ... user, remember: true })} />
                          <label htmlFor='off'
                            className='label- label-off- txt-sm-static font-monrope-light fw-bold active'
                            id='off' type="button"></label>
                          <div className='toggle-activate- ms-2 me-2'>
                            <div className='dot-toggle-'></div>
                            <div className='d-flex flex-row justify-content-start align-items-center align-self-center w-100 h-100 position-absolute'>
                              <div className='w-50 h-100'>
                                <label htmlFor='off'
                                  className='label- label-off- txt-sm-static font-monrope-light fw-bold w-100 h-100'
                                  id='off' type="button">
                                </label>
                              </div>
                              <div className='w-50 h-100'>
                                <label htmlFor='on'
                                  className='label- label-on- txt-sm-static font-monrope-light w-100 h-100'
                                  id='on' type="button">
                                </label>
                              </div>
                            </div>
                          </div>
                          <label htmlFor='on'
                            className='label- label-on- txt-sm-static font-monrope-light'
                            id='on' type="button"></label>
                        </div>
                      </div>
                    </div>
                    <div className='row g-3 d-flex flex-row justify-content-center align-items-start align-self-start mt-4'>
                      <div className='col-12'>
                        <button className='btn rounded-pill d-flex flex-row justify-content-center align-items-center align-self-center w-100 btn-green-light- h-40-' type="button" onClick={login}>
                          <span className='lh-1 fs-6- ff-monse-regular- fw-semibold'>Ingresar</span>
                        </button>
                      </div>
                      <div className='col-12'>
                        <button className='btn rounded-pill d-flex flex-row justify-content-center align-items-center align-self-center w-100 btn-bone-white- h-40-' type="button" onClick={redirectToRegister} >
                          <span className='lh-1 fs-6- ff-monse-regular- fw-semibold'>No tengo una cuenta</span>
                        </button>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-12 d-flex flex-row justify-content-center align-items-center align-self-center'>
                        <a onClick={redirectToForgot}><span className='fs-6- ff-monse-regular- fw-semibold tx-green-light-'>Olvidé mi contraseña</span></a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
