import './Sidebar.css';
import Logo3bioLarge from '../../../assets/logo/logoWhite.svg';
import Logo3bioSmall from '../../../assets/logo/logoSmallWhite.svg';
import IconSearchGlobal from '../../../assets/images/icon-search-global.svg';
import IconMinciencias from '../../../assets/images/icon-minciencias.svg';
import IconGruplac from '../../../assets/images/icon-gruplac.svg';
import IconSibcolombia from '../../../assets/images/icon-sibcolombia.svg';
import IconBioinformatic from '../../../assets/images/icon-bioinformatic.svg';
import IconPipelines from '../../../assets/images/icon-pipeline.svg';
import IconGenomeBrowser from '../../../assets/images/icon-genome.svg';
import IconDatabases from '../../../assets/images/icon-databases.svg';
import IconUserManagement from '../../../assets/images/icon-user-management.svg';
import React from 'react'
import { NavLink } from 'react-router-dom';

export default function Sidebar() {
  return (
    <React.Fragment>
      <nav id="sidebar-">
        <div className='d-flex flex-column justify-content-between min-h-'>
          <div className='w-100'>
            <div className='sidebar-header- d-flex flex-row justify-content-start align-items-center align-self-center position-relative'>
              <div className='w-100 d-flex flex-row justify-content-start align-items-center align-self-center wrapper-logo-medical-big-'>
                <div className='p-2 me-3 rounded-3 d-flex flex-row justify-content-center align-items-center align-self-center wrapper-logo-sidebar- big-'>
                  <img className='logo-3bio-sidebar-big-' src={Logo3bioLarge} alt="" />
                </div>
              </div>
              <div className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center wrapper-logo-medical-small-'>
                <div className='p-2 rounded-3 d-flex flex-row justify-content-center align-items-center align-self-center wrapper-logo-sidebar- small-'>
                  <img className='logo-3bio-sidebar-small-' src={Logo3bioSmall} alt="" />
                </div>
              </div>
              <div id="close-sidebar-" class='btn d-flex d-flex d-sm-flex d-md-none d-lg-none d-xl-none d-xxl-none flex-row justify-content-center align-items-center align-self-center rounded-circle p-3 btn-collapse-sidebar- bg-green-light- h-50- position-absolute top-0 end-0 mt-3 me-3'>
                <i className='fa icon-close fs-xs'></i>
              </div>
            </div>
            <ul className='nav d-flex flex-column'>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/scientometric/Search'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-search-global- abbreviated-' src={IconSearchGlobal} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Buscador</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/scientometric/Minciencias'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <img className='align-items-center align-self-center icon-sidebar-minciencias- abbreviated-' src={IconMinciencias} alt="" />
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Minciencias Colombia</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })}  to='/dashboard/scientometric/GrupLac'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-gruplac- abbreviated-' src={IconGruplac} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Instituciones GrupLac</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/scientometric/SibColombia'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-sibcolombia- abbreviated-' src={IconSibcolombia} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>SiB Colombia</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/bioinformatic/'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-bioinformatic- abbreviated-' src={IconBioinformatic} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Bioinformatica</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/bioinformatic/pipelines'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-pipelines- abbreviated-' src={IconPipelines} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Flujos de trabajo</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/bioinformatic/persefone/'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-genomebrowser- abbreviated-' src={IconGenomeBrowser} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Persephone</p>
                  </div>
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/bioinformatic/databasesBioinformatic'>
                  <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                      <img className='align-items-center align-self-center icon-sidebar-databases- abbreviated-' src={IconDatabases} alt="" />
                    </div>
                    <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Bases de datos</p>
                  </div>
                </NavLink>
              </li>
              {/*
                <li className='nav-item'>
                  <NavLink className='nav-link' style={({ isActive }) => ({ color: isActive ? 'var(--color-white-)' : 'var(--color-white-)', background: isActive ? 'var(--color-black-)' : 'var(--color-black-)', })} to='/dashboard/userManagement'>
                    <div className='d-flex flex-row justify-content-start align-items-center align-self-center position-relative fs-5- ff-monse-regular-'>
                      <div className='d-flex flex-row justify-content-center align-items-center align-self-center rounded-circle wrapper-icon-'>
                        <img className='align-items-center align-self-center icon-sidebar-user-management- abbreviated-' src={IconUserManagement} alt="" />
                      </div>
                      <p className='m-0 ms-3 align-items-center align-self-center fs-5- ff-monse-regular- unabbreviated- lh-sm'>Gestión de usuarios</p>
                    </div>
                  </NavLink>
                </li>
              */}
            </ul>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}
