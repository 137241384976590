import axios from 'axios';
import { environment } from '../../../../../environments/environment';

const getDatabaseResults = async (token) => {
  const path = environment.api + environment.getDatabase;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getDownloadFile = async (id, token) => {
    const path = environment.api + environment.getFileDatabase + id;
    let config = {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    };
    return await axios.get(path, config);
  };

export { getDatabaseResults, getDownloadFile };
