import IconMinciencias from '../../../../assets/images/icon-minciencias.svg';
import IconSearch from '../../../../assets/images/icon-search.svg';
import React, { useContext, useEffect, useState } from 'react'
import * as echarts from 'echarts';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"

import config from "../../../helper/config.js"
import { json, useNavigate } from 'react-router-dom';
import { getCards, getGraphics, getTableGeneralArticles, getTableInstitutionGroups, getTableTopInstitutions } from '../../../../services/dahsboard/scientometric/minciencias/minciencias';
import Preloader from '../../../shared/preloader/Preloader';
import { Appcontext } from '../../../../appContext';
import Swal from 'sweetalert2';
require('jquery-mousewheel');

export default function Minciencias() {

  const { cards, setCards, generalChartsData, setGeneralChartsData, tableInstitutions, setTableInstitutions, tableArticles, setTableArticles, token, setToken, tableTopInstitutions, setTableTopInstitutions } = useContext(Appcontext);
  const navigate = useNavigate();
  const [dataColors, setDataColors] = useState(['#F49C27', '#19D598', '#EA3A3D', '#A2EA1E', '#7151FE', '#0AB493']);
  const [dataOptionMincienciasOne, setDataOptionMincienciasOne] = useState(null);
  const [dataOptionMincienciasTwo, setDataOptionMincienciasTwo] = useState(null);
  const [dataOptionMincienciasThree, setDataOptionMincienciasThree] = useState(null);
  const [dataOptionMincienciasFour, setDataOptionMincienciasFour] = useState(null);
  const [dataOptionMincienciasFive, setDataOptionMincienciasFive] = useState(null);
  const [dataOptionMincienciasSix, setDataOptionMincienciasSix] = useState(null);
  const [dataOptionMincienciasSeven, setDataOptionMincienciasSeven] = useState(null);
  const [dataOptionMincienciasEight, setDataOptionMincienciasEight] = useState(null);
  const [dataOptionMincienciasNine, setDataOptionMincienciasNine] = useState(null);
  const [dataOptionMincienciasTen, setDataOptionMincienciasTen] = useState(null);
  const [dataOptionMincienciasEleven, setDataOptionMincienciasEleven] = useState(null);
  const [dataOptionMincienciasTwelve, setDataOptionMincienciasTwelve] = useState(null);
  const [dataOptionMincienciasThirteen, setDataOptionMincienciasThirteen] = useState(null);
  const [dataOptionMincienciasFourteen, setDataOptionMincienciasFourteen] = useState(null);
  const [dataOptionMincienciasFifteen, setDataOptionMincienciasFifteen] = useState(null);
  const [dataOptionMincienciasSixteen, setDataOptionMincienciasSixteen] = useState(null);
  const [dataOptionMincienciasSeventeen, setDataOptionMincienciasSeventeen] = useState(null);
  const [dataOptionMincienciasEighteen, setDataOptionMincienciasEighteen] = useState(null);
  const [dataOptionMincienciasNineteen, setDataOptionMincienciasNineteen] = useState(null);
  const [dataOptionMincienciasTwenty, setDataOptionMincienciasTwenty] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [charging, setCharging] = useState(false);
  const [filterInstitutions, setFilterInstitutions] = useState('');
  const [filterArticles, setFilterArticles] = useState('');
  const [searchTableInstitutions, setSearchTableInstitutions] = useState('');

  const mapStackedLine = (stackedLineData) => {
    const result = stackedLineData.valores.Y.map((element, index) => {
      const tag = stackedLineData.valores.etiquetas[index];
      return {
        name: tag,
        type: 'line',
        stack: 'Total',
        symbolSize: false,
        symbol: 'circle',
        smooth: true,
        lineStyle: {
          color: dataColors[index],
          width: 2,
          shadowColor: 'rgba(0,0,0,0.2)',
          shadowBlur: 10,
          shadowOffsetY: 8
        },
        itemStyle: {
          color: dataColors[index]
        },
        areaStyle: {
          opacity: 0.8,
          color: dataColors[index],
          shadowColor: 'rgba(0,0,0,0.2)',
          shadowBlur: 10,
          shadowOffsetY: 8,
        },
        emphasis: {
          focus: 'series',
          label: {
              show: true
            }
        },
        label: {
          normal: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        lableLine: {
          normal: {
            show: false,
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: true,
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        data: element,
        animationDelay: function (idx) {
          return idx * 15;
        }
      }  ;
    });
    return result;
  };

  const mapStackedBar = (stackedBarData) => {
    const result = stackedBarData.valores.Y.map((element, index) => {
      const tag = stackedBarData.valores.etiquetas[index];
      return {
        name: tag,
        type: 'bar',
        stack: 'total',
        label: {
          normal: {
            show: false,
            color: '#040E29',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#040E29',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        itemStyle: {
          color: dataColors[index],
          shadowColor: 'rgba(0,0,0,0.2)',
          shadowBlur: 10,
          shadowOffsetY: 8,
        },
        emphasis: {
          focus: 'series'
        },
        data: element,
        animationDelay: function (idx) {
          return idx * 15;
        }
      }  ;
    });
    return result;
  };
  
  useEffect( () => {
    getServices();
  }, []);

  useEffect(() => {
    if (isLoaded) {
      /**
     * Scrollbar en las tablas en el eje y , x
     */

      $('.wrapper-data-').mCustomScrollbar({
        theme: "minimal",
        mouseWheel:{
          scrollAmount: 60,
          normalizeDelta: true
        },
        scrollInertia:100,
        mouseWheelPixels: 100
      });
      /**
       * Scrollbar en la tabla en el eje x
       */
      $('.table-general-').mCustomScrollbar({
        theme: "minimal",
        axis:"x",
        mouseWheel:{ enable: false },
        scrollInertia:100,
        mouseWheelPixels: 100
      });

      /**
       * Grafica estado de los grupos (PIE CHART)
       */

      let chartDataMincienciasOne = echarts.init(document.getElementById('chart-data-minciencias-one-'));
      let optionDataMincienciasOne;

      optionDataMincienciasOne = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {
              type: 'png',
              name: 'Estado de los grupos',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                  opacity: 1,
                  shadowOffsetX: 0,
                  shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasOne === null ? [] : (dataOptionMincienciasOne.valores) || [],
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionDataMincienciasOne && chartDataMincienciasOne.setOption(optionDataMincienciasOne);

      $(window).on('resize', function(){
        if(chartDataMincienciasOne != null && chartDataMincienciasOne !== undefined){
          chartDataMincienciasOne.resize();
        }
      });

      /**
       * Grafica area de conocimiento (PIE CHART)
       */

      let chartDataMincienciasTwo = echarts.init(document.getElementById('chart-data-minciencias-two-'));
      let optionDataMincienciasTwo;

      optionDataMincienciasTwo = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
              dataView: {show: false, readOnly: false},
              restore: {show: false},
              saveAsImage: {
                type: 'png',
                name: 'Área de conocimiento',
                backgroundColor: '#040E29',
                show: true,
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
                emphasis: {
                  iconStyle: {
                    borderColor: '#FAFAFA'
                  },
                }
              }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                  opacity: 1,
                  shadowOffsetX: 0,
                  shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasTwo === null? []: (dataOptionMincienciasTwo.valores) || [],
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionDataMincienciasTwo && chartDataMincienciasTwo.setOption(optionDataMincienciasTwo);

      $(window).on('resize', function(){
        if(chartDataMincienciasTwo != null && chartDataMincienciasTwo !== undefined){
          chartDataMincienciasTwo.resize();
        }
      });

      /**
       * Grafica cantidad de grupos por año (LINE CHART)
       */

      let chartDataMincienciasThree = echarts.init(document.getElementById('chart-data-minciencias-three-'));
      let optionDataMincienciasThree;

      optionDataMincienciasThree = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#FAFAFA',
              color: '#040E29',
              fontWeight: 'bold',
              fontFamily: 'Monserat-light'
            }
          },
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          data: ['Ocultar']
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Cantidad de grupos por año',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          name: 'Año',
          nameLocation: 'middle',
          nameGap: 40,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          data: dataOptionMincienciasThree === null? [] : (dataOptionMincienciasThree.valores && dataOptionMincienciasThree.valores.X) || []
        },
        yAxis: {
          type: 'value',
          name: 'Cantidad de grupos por año',
          nameLocation: 'middle',
          nameGap: 60,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        grid: [
          {
            containLabel: true,
            borderColor: 'FAFAFA'
          }
        ],
        series: [
          {
            name: 'Ocultar',
            type: 'line',
            symbolSize: false,
            symbol: 'circle',
            smooth: true,
            lineStyle: {
              color: "#A2EB1B",
              width: 2,
              shadowColor: 'rgba(0,0,0,0.2)',
              shadowBlur: 10,
              shadowOffsetY: 8
            },
            itemStyle: {
              color: '#A2EB1B'
            },
            areaStyle: {
              opacity: 0.8,
              color: "#A2EB1B",
              shadowColor: 'rgba(0,0,0,0.2)',
              shadowBlur: 10,
              shadowOffsetY: 8
            },
            top: 15,
            emphasis: {
                label: {
                    show: true
                }
            },
            label: {
              normal: {
                show: false,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: false,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            data: dataOptionMincienciasThree === null? [] : (dataOptionMincienciasThree.valores && dataOptionMincienciasThree.valores.Y) || [],
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionDataMincienciasThree && chartDataMincienciasThree.setOption(optionDataMincienciasThree);

      $(window).on('resize', function(){
        if(chartDataMincienciasThree != null && chartDataMincienciasThree !== undefined){
          chartDataMincienciasThree.resize();
        }
      });

      /**
       * Grafica cantidad de grupos por año por area (LINE STACKED CHART)
       */

      let chartDataMincienciasFour = echarts.init(document.getElementById('chart-data-minciencias-four-'));
      let optionDataMincienciasFour;

      optionDataMincienciasFour = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#FAFAFA',
              color: '#040E29',
              fontWeight: 'bold',
              fontFamily: 'Monserat-light'
            }
          },
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          data: dataOptionMincienciasFour === null ? []: (dataOptionMincienciasFour.valores.etiquetas) || []
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Cantidad de grupos por año por área',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        xAxis: {
          type: 'category',
          name: 'Año',
          nameLocation: 'middle',
          nameGap: 40,
          boundaryGap: false,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          data: dataOptionMincienciasFour === null? []: (dataOptionMincienciasFour.valores.X) || []
        },
        yAxis: {
          type: 'value',
          name: 'Cantidad de grupos por año',
          nameLocation: 'middle',
          nameGap: 60,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        grid: [
          {
            containLabel: true,
            borderColor: 'FAFAFA'
          }
        ],
        series: dataOptionMincienciasFour === null ? []: mapStackedLine(dataOptionMincienciasFour),
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionDataMincienciasFour && chartDataMincienciasFour.setOption(optionDataMincienciasFour);

      $(window).on('resize', function(){
        if(chartDataMincienciasFour != null && chartDataMincienciasFour !== undefined){
          chartDataMincienciasFour.resize();
        }
      });

      /**
       * Grafica programa (PIE CHART)
       */

      let chartDataMincienciasFive = echarts.init(document.getElementById('chart-data-minciencias-five-'));
      let optionDataMincienciasFive;

      optionDataMincienciasFive = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {
              type: 'png',
              name: 'Programa',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                opacity: 1,
                shadowOffsetX: 0,
                shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasFive === null? []: (dataOptionMincienciasFive.valores)||[] ,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionDataMincienciasFive && chartDataMincienciasFive.setOption(optionDataMincienciasFive);

      $(window).on('resize', function(){
        if(chartDataMincienciasFive != null && chartDataMincienciasFive !== undefined){
          chartDataMincienciasFive.resize();
        }
      });

      /**
       * Grafica TOP 10 de instituciones con mas cantidades de grupos (BAR STACKED CHART)
       */

      let chartDataMincienciasSix = echarts.init(document.getElementById('chart-data-minciencias-six-'));
      let optionDataMincienciasSix;

      optionDataMincienciasSix = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#FAFAFA',
              color: '#040E29',
              fontWeight: 'bold',
              fontFamily: 'Monserat-light'
            }
          },
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          data: dataOptionMincienciasSix === null ? []: dataOptionMincienciasSix.valores.etiquetas || []
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Top 10 instituciones con más cantidad de grupos',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        grid: [
          {
            containLabel: true,
            borderColor: 'FAFAFA'
          }
        ],
        xAxis: {
          type: 'value',
          name: 'Lider',
          nameLocation: 'middle',
          nameGap: 40,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        yAxis: {
          type: 'category',
          name: 'Instituciones',
          nameLocation: 'middle',
          nameGap: 110,
          axisLabel: {
            formatter : function(params, value){
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 12;
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    if (p === rowNumber - 1) {
                        tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                    } else {}
                    newParamsName += tempStr;
                  }
              } else {
                  newParamsName = params;
              }
              return newParamsName
            },
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
          data: dataOptionMincienciasSix === null ? []: dataOptionMincienciasSix.valores.X || []
        },
        series: dataOptionMincienciasSix ===null? []:mapStackedBar(dataOptionMincienciasSix)||[],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionDataMincienciasSix && chartDataMincienciasSix.setOption(optionDataMincienciasSix);

      $(window).on('resize', function(){
        if(chartDataMincienciasSix != null && chartDataMincienciasSix !== undefined){
          chartDataMincienciasSix.resize();
        }
      });

      /**
       * Grafica grupos de inventigación (GAUGE CHART)
       */

      let chartGeneralDataOne = echarts.init(document.getElementById('chart-general-data-one-'));
      let optionGeneralDataOne;

      optionGeneralDataOne = {
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            saveAsImage: {
              type: 'png',
              name: 'Grupos de investigación',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        series: [
          {
            name: 'Pressure',
            type: 'gauge',
            center: ['50%', '58%'],
            radius: '100%' ,
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 1000,
            itemStyle: {
              color: '#C1F72B',
              shadowColor: 'rgba(193, 247, 43, 0.45)',
              shadowBlur: 10,
              shadowOffsetX: 2,
              shadowOffsetY: 2
            },
            progress: {
              show: true,
              roundCap: true,
              width: 18
            },
            detail: {
              lineHeight: 40,
              height: 40,
              offsetCenter: [0, '25%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(0) + '}';
              },
              rich: {
                value: {
                  fontSize: 50,
                  color: '#FAFAFA',
                  fontWeight: 'normal',
                  fontFamily: 'Monserat-light'
                }
              }
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '75%',
              width: 16,
              offsetCenter: [0, '5%']
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 18
              }
            },
            axisTick: {
              splitNumber: 1,
              lineStyle: {
                width: 2,
                color: '#FAFAFA'
              }
            },
            splitLine: {
              length: 12,
              lineStyle: {
                width: 3,
                color: '#FAFAFA'
              }
            },
            axisLabel: {
              distance: 30,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-regular'
            },
            data: [
              {
                value: dataOptionMincienciasSeven === null ? 0 : dataOptionMincienciasSeven.valores.X,
                name: 'Grupos',
                title: {
                  show: true,
                  offsetCenter: [0, '42%'] ,
                  color: '#FAFAFA',
                  fontWeight: 'normal',
                  fontFamily: 'Monserat-light'
                },
              }
            ]
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataOne && chartGeneralDataOne.setOption(optionGeneralDataOne);

      $(window).on('resize', function(){
        if(chartGeneralDataOne != null && chartGeneralDataOne !== undefined){
          chartGeneralDataOne.resize();
        }
      });

      /**
       * Grafica instituciones pertenecientes (GAUGE CHART)
       */

      let chartGeneralDataTwo = echarts.init(document.getElementById('chart-general-data-two-'));
      let optionGeneralDataTwo;

      optionGeneralDataTwo = {
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            saveAsImage: {
              type: 'png',
              name: 'Instituciones pertenecientes',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        series: [
          {
            name: 'Pressure',
            type: 'gauge',
            center: ['50%', '58%'],
            radius: '100%' ,
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 1000,
            itemStyle: {
              color: '#C1F72B',
              shadowColor: 'rgba(193, 247, 43, 0.45)',
              shadowBlur: 10,
              shadowOffsetX: 2,
              shadowOffsetY: 2
            },
            progress: {
              show: true,
              roundCap: true,
              width: 18
            },
            detail: {
              lineHeight: 40,
              height: 40,
              offsetCenter: [0, '25%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(0) + '}';
              },
              rich: {
                value: {
                  fontSize: 50,
                  color: '#FAFAFA',
                  fontWeight: 'normal',
                  fontFamily: 'Monserat-light'
                }
              }
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '75%',
              width: 16,
              offsetCenter: [0, '5%']
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 18
              }
            },
            axisTick: {
              splitNumber: 1,
              lineStyle: {
                width: 2,
                color: '#FAFAFA'
              }
            },
            splitLine: {
              length: 12,
              lineStyle: {
                width: 3,
                color: '#FAFAFA'
              }
            },
            axisLabel: {
              distance: 30,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-regular'
            },
            data: [
              {
                value: dataOptionMincienciasEight === null ? 0: dataOptionMincienciasEight.valores.X,
                name: 'Instituciones',
                title: {
                  show: true,
                  offsetCenter: [0, '42%'] ,
                  color: '#FAFAFA',
                  fontWeight: 'normal',
                  fontFamily: 'Monserat-light'
                },
              }
            ]
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataTwo && chartGeneralDataTwo.setOption(optionGeneralDataTwo);

      $(window).on('resize', function(){
        if(chartGeneralDataTwo != null && chartGeneralDataTwo !== undefined){
          chartGeneralDataTwo.resize();
        }
      });

      /**
       * Grafica generación de nuevo conocimiento: articulos (GAUGE CHART)
       */

      let chartGeneralDataThree = echarts.init(document.getElementById('chart-general-data-three-'));
      let optionGeneralDataThree;

      optionGeneralDataThree = {
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            saveAsImage: {
              type: 'png',
              name: 'Generación de nuevo conocimiento Artículos',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        series: [
          {
            name: 'Pressure',
            type: 'gauge',
            center: ['50%', '58%'],
            radius: '100%' ,
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 1000,
            itemStyle: {
              color: '#C1F72B',
              shadowColor: 'rgba(193, 247, 43, 0.45)',
              shadowBlur: 10,
              shadowOffsetX: 2,
              shadowOffsetY: 2
            },
            progress: {
              show: true,
              roundCap: true,
              width: 18
            },
            detail: {
              lineHeight: 40,
              height: 40,
              offsetCenter: [0, '25%'],
              valueAnimation: true,
              formatter: function (value) {
                return '{value|' + value.toFixed(0) + '}';
              },
              rich: {
                value: {
                  fontSize: 50,
                  color: '#FAFAFA',
                  fontWeight: 'normal',
                  fontFamily: 'Monserat-light'
                }
              }
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '75%',
              width: 16,
              offsetCenter: [0, '5%']
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 18
              }
            },
            axisTick: {
              splitNumber: 1,
              lineStyle: {
                width: 2,
                color: '#FAFAFA'
              }
            },
            splitLine: {
              length: 12,
              lineStyle: {
                width: 3,
                color: '#FAFAFA'
              }
            },
            axisLabel: {
              distance: 30,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-regular'
            },
            data: [
              {
                value: dataOptionMincienciasNine === null? 0: dataOptionMincienciasNine.valores.X,
                name: 'Artículos',
                title: {
                  show: true,
                  offsetCenter: [0, '42%'] ,
                  color: '#FAFAFA',
                  fontWeight: 'normal',
                  fontFamily: 'Monserat-light'
                },
              }
            ]
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataThree && chartGeneralDataThree.setOption(optionGeneralDataThree);

      $(window).on('resize', function(){
        if(chartGeneralDataThree != null && chartGeneralDataThree !== undefined){
          chartGeneralDataThree.resize();
        }
      });

      /**
       * Grafica Evolución temporal producción de artículos GrupLAC (LINE CHART)
       */

      let chartGeneralDataFour = echarts.init(document.getElementById('chart-general-data-four-'));
      let optionGeneralDataFour;

      optionGeneralDataFour = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#FAFAFA',
              color: '#040E29',
              fontWeight: 'bold',
              fontFamily: 'Monserat-light'
            }
          },
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          data: ['Ocultar']
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Evolución temporal producción de artículos GrupLAC',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          name: 'Año',
          nameLocation: 'middle',
          nameGap: 40,
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          data: dataOptionMincienciasTen === null ? []: dataOptionMincienciasTen.valores.X
        },
        yAxis: {
          type: 'value',
          name: 'Cantidad de articulos',
          nameLocation: 'middle',
          nameGap: 60,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        grid: [
          {
            containLabel: true,
            borderColor: 'FAFAFA'
          }
        ],
        series: [
          {
            name: 'Ocultar',
            type: 'line',
            symbolSize: false,
            symbol: 'circle',
            smooth: true,
            lineStyle: {
              color: "#A2EB1B",
              width: 2,
              shadowColor: 'rgba(0,0,0,0.2)',
              shadowBlur: 10,
              shadowOffsetY: 8
            },
            itemStyle: {
              color: '#A2EB1B'
            },
            areaStyle: {
              opacity: 0.8,
              color: "#A2EB1B",
              shadowColor: 'rgba(0,0,0,0.2)',
              shadowBlur: 10,
              shadowOffsetY: 8
            },
            top: 15,
            emphasis: {
                label: {
                    show: true
                }
            },
            label: {
              normal: {
                show: false,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: false,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            data: dataOptionMincienciasTen === null ? []: dataOptionMincienciasTen.valores.Y,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataFour && chartGeneralDataFour.setOption(optionGeneralDataFour);

      $(window).on('resize', function(){
        if(chartGeneralDataFour != null && chartGeneralDataFour !== undefined){
          chartGeneralDataFour.resize();
        }
      });

      /**
       * Grafica clasificación de revistas Scimago (PIE CHART)
       */

      let chartGeneralDataFive = echarts.init(document.getElementById('chart-general-data-five-'));
      let optionGeneralDataFive;

      optionGeneralDataFive = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {
              type: 'png',
              name: 'Clasificación de revistas Scimago',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                  opacity: 1,
                  shadowOffsetX: 0,
                  shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasEleven === null? []: dataOptionMincienciasEleven.valores,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataFive && chartGeneralDataFive.setOption(optionGeneralDataFive);

      $(window).on('resize', function(){
        if(chartGeneralDataFive != null && chartGeneralDataFive !== undefined){
          chartGeneralDataFive.resize();
        }
      });

      /**
       * Grafica clasificación de revistas Publindex (PIE CHART)
       */

      let chartGeneralDataSix = echarts.init(document.getElementById('chart-general-data-six-'));
      let optionGeneralDataSix;

      optionGeneralDataSix = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
              dataView: {show: false, readOnly: false},
              restore: {show: false},
              saveAsImage: {
                type: 'png',
                name: 'Clasificación de revistas Publindex',
                backgroundColor: '#040E29',
                show: true,
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
                emphasis: {
                  iconStyle: {
                    borderColor: '#FAFAFA'
                  },
                }
              }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                  opacity: 1,
                  shadowOffsetX: 0,
                  shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasTwelve === null? []: dataOptionMincienciasTwelve.valores,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataSix && chartGeneralDataSix.setOption(optionGeneralDataSix);

      $(window).on('resize', function(){
        if(chartGeneralDataSix != null && chartGeneralDataSix !== undefined){
          chartGeneralDataSix.resize();
        }
      });

      /**
       * Grafica comparación de revistas Scimago (BAR CHART)
       */

      let chartGeneralDataSeven = echarts.init(document.getElementById('chart-general-data-seven-'));
      let optionGeneralDataSeven;

      optionGeneralDataSeven = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Comparación de revistas Scimago',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          max: dataOptionMincienciasThirteen?.valores?.Y[0],
          min: dataOptionMincienciasThirteen?.valores?.Y[dataOptionMincienciasThirteen?.valores?.Y?.length - 1],
          text: ['', ''],
          inRange: {
              color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        grid: [
          {
            containLabel: true,
            right: '14%',
            borderColor: 'FAFAFA'
          }
        ],
        xAxis: {
          type: 'category',
          data: dataOptionMincienciasThirteen?.valores?.X,
          nameGap: 40,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        yAxis: {
          type: 'value',
          nameGap: 60,
          axisLabel: {
            formatter : function(params, value){
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 12;
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    if (p === rowNumber - 1) {
                        tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                    } else {}
                    newParamsName += tempStr;
                  }
              } else {
                  newParamsName = params;
              }
              return newParamsName
            },
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        series: [
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                position: 'top',
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
            },
            data: dataOptionMincienciasThirteen?.valores?.Y,
            animationDelay: function (idx) {
              return idx * 15;
            }
          },
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataSeven && chartGeneralDataSeven.setOption(optionGeneralDataSeven);

      $(window).on('resize', function(){
        if(chartGeneralDataSeven != null && chartGeneralDataSeven !== undefined){
          chartGeneralDataSeven.resize();
        }
      });

      /**
       * Grafica comparación de revistas Publindex (BAR CHART)
       */

      let chartGeneralDataEight = echarts.init(document.getElementById('chart-general-data-eight-'));
      let optionGeneralDataEight;

      optionGeneralDataEight = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Comparación de revistas Publindex',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          max: dataOptionMincienciasFourteen.valores.Y[0],
          min: dataOptionMincienciasFourteen.valores.Y[dataOptionMincienciasFourteen.valores.Y.length - 1],
          text: ['', ''],
          inRange: {
              color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        grid: [
          {
            containLabel: true,
            right: '14%',
            borderColor: 'FAFAFA'
          }
        ],
        xAxis: {
          type: 'category',
          data: dataOptionMincienciasFourteen.valores.X,
          nameGap: 40,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        yAxis: {
          type: 'value',
          nameGap: 60,
          axisLabel: {
            formatter : function(params, value){
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 12;
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    if (p === rowNumber - 1) {
                        tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                    } else {}
                    newParamsName += tempStr;
                  }
              } else {
                  newParamsName = params;
              }
              return newParamsName
            },
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        series: [
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                position: 'top',
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            data: dataOptionMincienciasFourteen.valores.Y,
            animationDelay: function (idx) {
              return idx * 15;
            }
          },
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataEight && chartGeneralDataEight.setOption(optionGeneralDataEight);

      $(window).on('resize', function(){
        if(chartGeneralDataEight != null && chartGeneralDataEight !== undefined){
          chartGeneralDataEight.resize();
        }
      });

      /**
       * Grafica información de artículos: paises por revistas (BAR CHART)
       */

      let chartGeneralDataNine = echarts.init(document.getElementById('chart-general-data-nine-'));
      let optionGeneralDataNine;

      optionGeneralDataNine = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Información de artículos revistas por paises',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: -5,
          min: 12755,
          max: 101852,
          text: ['', ''],
          dimension: 0,
          inRange: {
              color: ['#9AC331', '#FFDA00', '#EF2469']
          },
          textStyle: {
            color: '#212121',
            fontWeight: 'normal',
            fontFamily: 'Monserat-Light'
          }
        },
        dataset: {
          source: [
            ['amount', 'product'],
            [58212, 'Colombia'],
            [78254, 'Estados unidos'],
            [41032, 'Países bajos'],
            [12755, 'Inglaterra'],
            [20145, 'España'],
            [79146, 'Reino unido']
          ]
        },
        grid: [
          {
            containLabel: true,
            right: '14%',
            borderColor: 'FAFAFA'
          }
        ],
        xAxis: {
          type: 'value',
          name: 'Publicaciones',
          nameLocation: 'middle',
          nameGap: 40,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        yAxis: {
          type: 'category',
          name: 'País revista',
          nameLocation: 'middle',
          nameGap: 110,
          axisLabel: {
            formatter : function(params, value){
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 12;
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    if (p === rowNumber - 1) {
                        tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                    } else {}
                    newParamsName += tempStr;
                  }
              } else {
                  newParamsName = params;
              }
              return newParamsName
            },
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
          data: ['Colombia', 'Estados unidos', 'Países bajos', 'Inglaterra', 'España', 'Reino unido']
        },
        series: [
          {
            type: 'bar',
            stack: 'chart',
            z: 3,
            label: {
              position: 'right',
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            encode: {
                x: 'amount',
                y: 'product'
            },
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataNine && chartGeneralDataNine.setOption(optionGeneralDataNine);

      $(window).on('resize', function(){
        if(chartGeneralDataNine != null && chartGeneralDataNine !== undefined){
          chartGeneralDataNine.resize();
        }
      });

      /**
       * Grafica información de artículos: tipo de producto (PIE CHART)
       */

      let chartGeneralDataTen = echarts.init(document.getElementById('chart-general-data-ten-'));
      let optionGeneralDataTen;

      optionGeneralDataTen = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {
              type: 'png',
              name: 'Información de artículos tipo de producto',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                opacity: 1,
                shadowOffsetX: 0,
                shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasSixteen === null? []: dataOptionMincienciasSixteen.valores,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataTen && chartGeneralDataTen.setOption(optionGeneralDataTen);

      $(window).on('resize', function(){
        if(chartGeneralDataTen != null && chartGeneralDataTen !== undefined){
          chartGeneralDataTen.resize();
        }
      });

      /**
       * Grafica tendencia de producción (BUBBLE CHART)
       */

      let chartGeneralDataEleven = echarts.init(document.getElementById('chart-general-data-eleven-'));
      let optionGeneralDataEleven;

      optionGeneralDataEleven = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#FAFAFA',
              color: '#040E29',
              fontWeight: 'bold',
              fontFamily: 'Monserat-light'
            }
          },
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Tendencia de producción',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          max: 80000,
          min: 0,
          text: ['', ''],
          inRange: {
              color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        grid: [
          {
            containLabel: true,
            right: '14%',
            borderColor: 'FAFAFA'
          }
        ],
        xAxis: {
          type: 'category',
          name: 'Año',
          nameLocation: 'middle',
          nameGap: 40,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        yAxis: {
          type: 'value',
          name: 'Cantidad de articulos',
          nameLocation: 'middle',
          nameGap: 60,
          axisLabel: {
            formatter : function(params, value){
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 12;
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    if (p === rowNumber - 1) {
                        tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                    } else {}
                    newParamsName += tempStr;
                  }
              } else {
                  newParamsName = params;
              }
              return newParamsName
            },
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        dataset: [
          {
            source: [
              [1, 4862.4],
              [2, 5294.7],
              [3, 5934.5],
              [4, 7171.0],
              [5, 8964.4],
              [6, 10202.2],
              [7, 11962.5],
              [8, 14928.3],
              [9, 16909.2],
              [10, 18547.9],
              [11, 21617.8],
              [12, 26638.1],
              [13, 34634.4],
              [14, 46759.4],
              [15, 58478.1],
              [16, 67884.6],
              [17, 74462.6],
              [18, 79395.7]
            ]
          },
          {
            transform: {
              type: 'ecStat:regression',
              config: {
                method: 'exponential'
              }
            }
          }
        ],
        series: [
          {
            type: 'scatter',
            symbolSize: 10,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataEleven && chartGeneralDataEleven.setOption(optionGeneralDataEleven);

      $(window).on('resize', function(){
        if(chartGeneralDataEleven != null && chartGeneralDataEleven !== undefined){
          chartGeneralDataEleven.resize();
        }
      });

      /**
       * Grafica clasificación de investigadores (BAR CHART)
       */

      let chartGeneralDataThirteen = echarts.init(document.getElementById('chart-general-data-thirteen-'));
      let optionGeneralDataThirteen;

      optionGeneralDataThirteen = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataZoom: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            restore: {
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            },
            saveAsImage: {
              type: 'png',
              name: 'Clasificación de investigadores',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: -5,
          min: 12755,
          max: 101852,
          text: ['', ''],
          dimension: 0,
          inRange: {
              color: ['#9AC331', '#FFDA00', '#EF2469']
          },
          textStyle: {
            color: '#212121',
            fontWeight: 'normal',
            fontFamily: 'Monserat-Light'
          }
      },
        dataset: {
          source: [
            ['amount', 'product'],
            [58212, 'Sin clasificar'],
            [78254, 'Investigador junior'],
            [41032, 'CvLAC oculto'],
            [12755, 'Investigador asociado'],
            [20145, 'Investigador senior'],
            [79146, 'Investigador emérito']
          ]
        },
        grid: [
          {
            containLabel: true,
            right: '14%',
            borderColor: 'FAFAFA'
          }
        ],
        xAxis: {
          type: 'value',
          name: 'Cantidad de investigadores',
          nameLocation: 'middle',
          nameGap: 40,
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLabel: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
        },
        yAxis: {
          type: 'category',
          name: 'Clasificación',
          nameLocation: 'middle',
          nameGap: 110,
          axisLabel: {
            formatter : function(params, value){
              var newParamsName = "";
              var paramsNameNumber = params.length;
              var provideNumber = 12;
              var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
              if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    if (p === rowNumber - 1) {
                        tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                    } else {}
                    newParamsName += tempStr;
                  }
              } else {
                  newParamsName = params;
              }
              return newParamsName
            },
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          nameTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular'
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: 'FAFAFA',
              width: 1,
              cap: 'round'
            }
          },
          data: ['Sin clasificar', 'Investigador junior', 'CvLAC oculto', 'Investigador asociado', 'Investigador senior', 'Investigador emérito']
        },
        series: [
          {
            type: 'bar',
            stack: 'chart',
            z: 3,
            label: {
              position: 'right',
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            encode: {
                x: 'amount',
                y: 'product'
            },
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataThirteen && chartGeneralDataThirteen.setOption(optionGeneralDataThirteen);

      $(window).on('resize', function(){
        if(chartGeneralDataThirteen != null && chartGeneralDataThirteen !== undefined){
          chartGeneralDataThirteen.resize();
        }
      });

      /**
       * Grafica formación de investigadores (PIE CHART)
       */

      let chartGeneralDataFourteen = echarts.init(document.getElementById('chart-general-data-fourteen-'));
      let optionGeneralDataFourteen;

      optionGeneralDataFourteen = {
        tooltip: {
          trigger: 'item',
          showDelay: 0,
          transitionDuration: 0.2,
          backgroundColor: 'rgba(250, 250, 250, 1)',
          borderWidth: 1,
          borderColor: '#FAFAFA',
          padding: 5,
          textStyle: {
            color: '#212121',
            fontSize: 13,
            lineHeight:10,
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          },
          extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
        },
        legend: {
          type: 'scroll',
          orient: 'horizontal',
          left: 'center',
          top: 'top',
          bottom: 20,
          itemGap : 25,
          width: '90%',
          inactiveColor: '#728998',
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          pageIconSize: 12,
          pageIconColor: '#C1F72B',
          pageIconInactiveColor: '#FAFAFA',
          pageTextStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-regular, Verdana'
          },
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 50;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          }
        },
        toolbox: {
          show: true,
          orient: 'horizontal',
          showTitle: false,
          feature: {
            dataView: {show: false, readOnly: false},
            restore: {show: false},
            saveAsImage: {
              type: 'png',
              name: 'Formación de investigadores',
              backgroundColor: '#040E29',
              show: true,
              iconStyle: {
                borderColor: '#FAFAFA'
              },
              emphasis: {
                iconStyle: {
                  borderColor: '#FAFAFA'
                },
              }
            }
          },
          iconStyle: {
            borderColor: '#FAFAFA'
          },
          emphasis: {
            iconStyle: {
              borderColor: '#FAFAFA'
            },
          },
          bottom: 14,
          pixelRatio: 2,
        },
        visualMap: {
          top: 'middle',
          right: 0,
          min: 0,
          max: 1,
          text: ['', ''],
          dimension: 0,
          inRange: {
            color: ['#EF2469', '#FFDA00', '#9AC331']
          },
          textStyle: {
            color: '#FAFAFA',
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            hoverOffset: 12,
            top: 15,
            label: {
              show: false,
              position: 'center',
              normal: {
                show: true,
                color: '#FAFAFA',
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            emphasis: {
              label: {
                show: true,
                color: '#FAFAFA',
                fontSize: 14,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light',
                formatter: "({d}%)",
              },
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            lableLine: {
              normal: {
                show: false,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              },
              emphasis: {
                show: true,
                fontSize: 12,
                fontWeight: 'normal',
                fontFamily: 'Monserat-light'
              }
            },
            itemStyle: {
              borderRadius: 8,
              normal: {
                  opacity: 1,
                  shadowOffsetX: 0,
                  shadowOffsetY: 0
              }
            },
            data: dataOptionMincienciasTwenty === null? []: dataOptionMincienciasTwenty.valores,
            animationDelay: function (idx) {
              return idx * 15;
            }
          }
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      };

      optionGeneralDataFourteen && chartGeneralDataFourteen.setOption(optionGeneralDataFourteen);

      $(window).on('resize', function(){
        if(chartGeneralDataFourteen != null && chartGeneralDataFourteen !== undefined){
          chartGeneralDataFourteen.resize();
        }
      });
    }
  }, [isLoaded]);

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  const getServices = async () => {
    if (generalChartsData.length === 0) {      
      await getGeneralChartsData();
    } else {
      setInfo(generalChartsData);
    } 
    if (!cards) {
      await getCardsInfo();
    }
    if (tableInstitutions.data.length === 0) {
      await getTableGroups();      
    } 
    if (tableArticles.data.length === 0) {
      await getTableGeneral();
    } 
    if (tableTopInstitutions.length === 0) {
      await getTableInstitutions();
    }
  };

  const getGeneralChartsData = async () => {
    setCharging(true);
    let response = await getGraphics(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las gráficas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setGeneralChartsData(data);
      setInfo(data);
      setCharging(false);
    }
  };

  const getCardsInfo = async () => {
    setCharging(true);
    let response = await getCards(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCards(data);
      setCharging(false);
    }
  };

  const getTableGroups = async () => {
    setCharging(true);
    let response = await getTableInstitutionGroups(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tablas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setTableInstitutions(data);
      setCharging(false);
    }
  };

  const getTableGeneral = async () => {
    setCharging(true);
    let response = await getTableGeneralArticles(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tablas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setTableArticles(data);
      setCharging(false);
    }
  };

  const getTableInstitutions = async () => {
    setCharging(true);
    let response = await getTableTopInstitutions(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tablas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setTableTopInstitutions(data);
      setCharging(false);
    }
  };

  const setInfo = (data) => {
    setDataOptionMincienciasOne(data.filter(chart => chart.nombre === 'estado_pastel')[0]);
    setDataOptionMincienciasTwo(data.filter(chart => chart.nombre === 'gran_area_pastel')[0]);      
    setDataOptionMincienciasThree(data.filter(chart => chart.nombre === 'anio_linea')[0]);    
    setDataOptionMincienciasFour(data.filter(chart => chart.nombre === 'anio_gran_area_linea_stack')[0]);
    setDataOptionMincienciasFive(data.filter(chart => chart.nombre === 'programa_pastel')[0]);    
    setDataOptionMincienciasSix(data.filter(chart => chart.nombre === 'instituciones_gran_area_barra_stack')[0]);
    setDataOptionMincienciasSeven(data.filter(chart => chart.nombre === 'nombre grupo_gauge')[0]);
    setDataOptionMincienciasEight(data.filter(chart => chart.nombre === 'instituciones_gauge')[0]);
    setDataOptionMincienciasNine(data.filter(chart => chart.nombre === 'cantidad_articulos_gauge')[0]);
    setDataOptionMincienciasTen(data.filter(chart => chart.nombre === 'anio__linea')[0]);
    setDataOptionMincienciasEleven(data.filter(chart => chart.nombre === 'sjr_q_pastel')[0]);
    setDataOptionMincienciasTwelve(data.filter(chart => chart.nombre === 'publindex_pastel')[0]);
    setDataOptionMincienciasThirteen(data.filter(chart => chart.nombre === 'sjr_q_barra')[0]);
    setDataOptionMincienciasFourteen(data.filter(chart => chart.nombre === 'publindex_barra')[0]);
    setDataOptionMincienciasSixteen(data.filter(chart => chart.nombre === 'tipo_articulo_pastel')[0]);
    setDataOptionMincienciasTwenty(data.filter(chart => chart.nombre === 'posgrado_pastel')[0]);
    setIsLoaded(!isLoaded);
  };

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='row'>
        <div className='col-12'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-minciencias-' src={IconMinciencias} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Minciencias Colombia
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  Dentro de la producción científica se encuentran elementos cómo: publicación de artículos, revistas, tesis de grado o cantidad de publicaciones por entidad. En esta sección se mostrará cómo ha sido la producción científica a lo largo del tiempo de manera gráfica.
                </p>
              </div>
            </div>
            <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
              <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                <div className='col-12 col-sm-7 col-md-8 col-lg-8 col-xl-7 col-xxl-5'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Articulos - capitulos - libros</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        # <span>{ cards?.articulos }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Instituciones</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        + <span>{ cards?.instituciones }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-2'>
        <div className='accordion row gy-4 gx-0 mt-2' id="accordionPanelsStayOpenExample">
          <div className='accordion-item'>
            <div className='d-flex flex-row justify-content-between align-items-end align-self-end justify-content-sm-between align-items-sm-end align-self-sm-end justify-content-md-between align-items-md-end align-self-md-end justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
              <div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-start align-self-center justify-content-sm-center align-items-sm-start align-self-sm-center justify-content-md-center align-items-md-start align-self-md-center justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                <h5 className='m-0 ms-4 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Datos Minciencias Colombia</h5>
              </div>
              <h2 className='accordion-header d-flex flex-column justify-content-xxl-center align-items-xxl-center align-self-xxl-center ms-4 me-4' id="panelsStayOpen-headingThree">
                <button className='accordion-button ps-0 pe-0 pt-2 pb-2' type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne">
                </button>
              </h2>
            </div>
            <div id="panelsStayOpen-collapseOne" className='accordion-collapse collapse show' aria-labelledby="panelsStayOpen-headingOne">
              <div className='accordion-body'>
                <div className="row row-cols-auto g-4 mb-2">
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <p className='m-0 p-0 lh-sm fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>En esta sección se presenta una información general de todos los grupos</p>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Estado de los grupos
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-data-minciencias-one-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Área de conocimiento
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-data-minciencias-two-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Cantidad de grupos por año
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-data-minciencias-three-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Cantidad de grupos por año por área
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-data-minciencias-four-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Programa
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-data-minciencias-five-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Top 10 de instituciones con más cantidad de grupos
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-data-minciencias-six-'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <div className='d-flex flex-row justify-content-between align-items-end align-self-end justify-content-sm-between align-items-sm-end align-self-sm-end justify-content-md-between align-items-md-end align-self-md-end justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
              <div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-start align-self-center justify-content-sm-center align-items-sm-start align-self-sm-center justify-content-md-center align-items-md-start align-self-md-center justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                <h5 className='m-0 ms-4 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Datos generales 3BIO</h5>
              </div>
              <h2 className='accordion-header d-flex flex-column justify-content-xxl-center align-items-xxl-center align-self-xxl-center ms-4 me-4' id="panelsStayOpen-headingThree">
                <button className='accordion-button ps-0 pe-0 pt-2 pb-2' type="button" data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                </button>
              </h2>
            </div>
            <div id="panelsStayOpen-collapseTwo" className='accordion-collapse collapse show' aria-labelledby="panelsStayOpen-headingTwo">
              <div className='accordion-body'>
                <div className="row row-cols-auto g-4 mb-2">
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Grupos de investigación
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-one-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                          <div className='col d-flex flex-column'>
                            <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Tabla de información general de todos los grupos
                              </h1>
                            </div>
                            <div className='w-auto mt-3 '>
                              <form action="" className='position-relative wrapper-search-small- me-3'>
                                <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                  <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={filterInstitutions} onChange={({ target }) => setFilterInstitutions(target.value)} />
                                  <img src={IconSearch} alt="" />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                            <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                              <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                  <div className='card-header border-0 bg-transparent pb-0'>
                                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                  </div>
                                  <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                    <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                      # <span>{ tableInstitutions?.amount }</span>
                                    </p>
                                    <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                      Registros
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body p-0 w-100 wrapper-data-'>
                        <div className='table-responsive table-general-'>
                          <table className='table table-sm table-striped table-no-border- align-middle'>
                            <thead>
                              <tr>
                                <th scope="col" className='th-width-sm-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Código de grupo</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-md-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Nombre grupo</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-sm-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Fecha creación</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-sm-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Departamento</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-md-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Lider</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-md-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Clasificación</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-md-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Instituciones</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                tableInstitutions.data.filter(table => {
                                  if (filterInstitutions !== '') {
                                    let keys = Object.keys(table);
                                    let flagKeys = false;
                                    let i = 0;
                                    while (i < keys.length && !flagKeys) {
                                      if (keys[i] === 'codigo_grupo' || keys[i] === 'nombre_grupo' || keys[i] === 'fecha_formacion' || keys[i] === 'localidad' || keys[i] === 'lider' || keys[i] === 'clasificacion' || keys[i] === 'instituciones') {
                                        let word = '' + table[keys[i]];
                                        let words = word.split(' ');
                                        let j = 0;
                                        flagKeys = word.toUpperCase().includes(filterInstitutions.toUpperCase());
                                        while (j < words.length && !flagKeys) {
                                          if (words[j].toUpperCase().includes(filterInstitutions.toUpperCase())) {
                                            flagKeys = true;
                                          }
                                          j ++;
                                        }
                                        if (filterInstitutions.toUpperCase() === word.substring(0, filterInstitutions.length).toUpperCase()) {
                                          flagKeys = true;
                                        }
                                      }
                                      if (flagKeys) {
                                        return true;
                                      }  
                                      i ++;
                                    }
                                    
                                  } else {
                                    return true;
                                  }
                                }).map(table => (
                                  <tr>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.codigo_grupo }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.nombre_grupo }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.fecha_formacion }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.localidad }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.lider }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.clasificacion }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.instituciones }</p>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/*  <div class="card-footer border-0 bg-transparent pb-3 pt-3">
                          <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                            <div className='col-auto'>
                              <nav>
                                <ul className="pagination justify-content-center">
                                  {startPage !== 1 && (
                                    <li className="page-item">
                                      <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                    </li>
                                  )}
                                  {startPage > 2 && (
                                    <li className="page-item disabled">
                                      <span className="page-link">...</span>
                                    </li>
                                  )}
                                  {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                    <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                      <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                    </li>
                                  ))}
                                  {endPage < totalPages - 1 && (
                                    <li className="page-item disabled">
                                      <span className="page-link">...</span>
                                    </li>
                                  )}
                                  {endPage !== totalPages && (
                                    <li className="page-item">
                                      <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                    </li>
                                  )}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Instituciones pertenecientes
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-two-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                          <div className='col d-flex flex-column'>
                            <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Top 10 de instituciones con más grupos de investigación
                              </h1>
                            </div>
                            <div className='w-auto mt-3 '>
                              <form action="" className='position-relative wrapper-search-small- me-3'>
                                <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                  <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchTableInstitutions} onChange={({target}) => setSearchTableInstitutions(target.value)} />
                                  <img src={IconSearch} alt=""/>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                            <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                              <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                  <div className='card-header border-0 bg-transparent pb-0'>
                                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                  </div>
                                  <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                    <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                      # <span>{ tableTopInstitutions.length }</span>
                                    </p>
                                    <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                      Registros
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body p-0 w-100 wrapper-data-'>
                        <div className='table-responsive table-general-'>
                          <table className='table table-sm table-striped table-no-border- align-middle'>
                            <thead>
                              <tr>
                                <th scope="col" className='th-width-auto-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Institución</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-auto-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cantidad</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                tableTopInstitutions.filter(table => {
                                  if (searchTableInstitutions !== '') {
                                    let keys = Object.keys(table);
                                    let flagKeys = false;
                                    let i = 0;
                                    while (i < keys.length && !flagKeys) {
                                      if (keys[i] === 'institucion' || keys[i] === 'cantidad') {
                                        let word = '' + table[keys[i]];
                                        let words = word.split(' ');
                                        let j = 0;
                                        flagKeys = word.toUpperCase().includes(searchTableInstitutions.toUpperCase());
                                        while (j < words.length && !flagKeys) {
                                          if (words[j].toUpperCase().includes(searchTableInstitutions.toUpperCase())) {
                                            flagKeys = true;
                                          }
                                          j ++;
                                        }
                                        if (searchTableInstitutions.toUpperCase() === word.substring(0, searchTableInstitutions.length).toUpperCase()) {
                                          flagKeys = true;
                                        }
                                      }
                                      if (flagKeys) {
                                        return true;
                                      }  
                                      i ++;
                                    }                                  
                                  } else {
                                    return true;
                                  }
                                }).map(row => (
                                  <tr>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.institucion }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.cantidad }</p>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/*                      
                        <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                          <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                            <div className='col-auto'>
                              <nav>
                                <ul className="pagination justify-content-center">
                                  {startPage !== 1 && (
                                    <li className="page-item">
                                      <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                    </li>
                                  )}
                                  {startPage > 2 && (
                                    <li className="page-item disabled">
                                      <span className="page-link">...</span>
                                    </li>
                                  )}
                                  {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                    <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                      <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                    </li>
                                  ))}
                                  {endPage < totalPages - 1 && (
                                    <li className="page-item disabled">
                                      <span className="page-link">...</span>
                                    </li>
                                  )}
                                  {endPage !== totalPages && (
                                    <li className="page-item">
                                      <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                    </li>
                                  )}
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      */}
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Generación de nuevo conocimiento: Artículos
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-three-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                          <div className='col d-flex flex-column'>
                            <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                Tabla de información general de los artículos
                              </h1>
                            </div>
                            <div className='w-auto mt-3 '>
                              <form action="" className='position-relative wrapper-search-small- me-3'>
                                <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                  <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={filterArticles} onChange={({ target }) => setFilterArticles(target.value)} />
                                  <img src={IconSearch} alt="" />
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                            <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                              <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                  <div className='card-header border-0 bg-transparent pb-0'>
                                    <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                  </div>
                                  <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                    <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                      # <span>{ tableArticles?.amount }</span>
                                    </p>
                                    <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                      Registros
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='card-body p-0 w-100 wrapper-data-'>
                        <div className='table-responsive table-general-'>
                          <table className='table table-sm table-striped table-no-border- align-middle'>
                            <thead>
                              <tr>
                                <th scope="col" className='th-width-xs-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'></span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-md-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Título</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-sm-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tipo producto</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-sm-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Revista</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-sm-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Categoria revista</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-auto-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>SJR Q</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-auto-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Año</span>
                                  </div>
                                </th>
                                <th scope="col" className='th-width-md-'>
                                  <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                    <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Autores</span>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                tableArticles.data.filter(table => {
                                  if (filterArticles !== '') {
                                    let keys = Object.keys(table);
                                    let flagKeys = false;
                                    let i = 0;
                                    while (i < keys.length && !flagKeys) {
                                      if (keys[i] === 'titulo' || keys[i] === 'revista' || keys[i] === 'tipo_articulo' || keys[i] === 'sqj_q' || keys[i] === 'year' || keys[i] === 'autores') {
                                        let word = '' + table[keys[i]];
                                        let words = word.split(' ');
                                        let j = 0;
                                        flagKeys = word.toUpperCase().includes(filterArticles.toUpperCase());
                                        while (j < words.length && !flagKeys) {
                                          if (words[j].toUpperCase().includes(filterArticles.toUpperCase())) {
                                            flagKeys = true;
                                          }
                                          j ++;
                                        }
                                        if (filterArticles.toUpperCase() === word.substring(0, filterArticles.length).toUpperCase()) {
                                          flagKeys = true;
                                        }
                                      }
                                      if (flagKeys) {
                                        return true;
                                      }  
                                      i ++;
                                    }
                                    
                                  } else {
                                    return true;
                                  }
                                }).map(table => (
                                  <tr>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>0</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.titulo }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>Publicado en revista especializada</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.revista }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.tipo_articulo }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.sqj_q }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.year }</p>
                                    </td>
                                    <td className='align-middle'>
                                      <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ table.autores }</p>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div class="card-footer border-0 bg-transparent pb-3 pt-3">
                        <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                          <div className='col-auto'>
                            <nav>
                              <ul className="pagination justify-content-center">
                                {startPage !== 1 && (
                                  <li className="page-item">
                                    <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                  </li>
                                )}
                                {startPage > 2 && (
                                  <li className="page-item disabled">
                                    <span className="page-link">...</span>
                                  </li>
                                )}
                                {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                  <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                    <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                  </li>
                                ))}
                                {endPage < totalPages - 1 && (
                                  <li className="page-item disabled">
                                    <span className="page-link">...</span>
                                  </li>
                                )}
                                {endPage !== totalPages && (
                                  <li className="page-item">
                                    <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                  </li>
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                        </div> */}
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Evolución temporal producción de artículos GrupLAC
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Número de articulos por año</p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-four-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Clasificación de revistas: artículos</h1>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Scimago
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-five-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Publindex
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-six-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Comparación entre Scimago y Publindex</h1>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Scimago
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-seven-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Publindex
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Ranking</p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-eight-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Información de artículos</h1>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Países por revista
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-nine-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Tipo de producto
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-ten-'></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Tendencia de producción
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-eleven-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                    <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Información de investigadores: Cvlac</h1>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Clasificación de investigadores
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-thirteen-'></div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
                    <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                      <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                        <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                          Formación de investigadores
                        </h1>
                        <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                      </div>
                      <div className='card-body p-0 ps-2 pe-2 w-100'>
                        <div className='w-100 h-100 mx-auto' id='chart-general-data-fourteen-'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
