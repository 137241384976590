import './Scientometric.css';
import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom"
import ScrollToTop from "react-scroll-to-top";
import Search from './search/Search';
import Minciencias from './minciencias/Minciencias';
import GrupLac from './gruplac/GrupLac';
import SibColombia from './sibColombia/SibColombia';

export default function ScientometricRoutes() {
  return (
    <React.Fragment>
      <div className='container-fluid overflow-x-hidden'>
        <Routes>
          <Route path="" element={ <Navigate to="Search" /> }/>
          <Route path="Search" element={<Search/>} />
          <Route path="Minciencias" element={<Minciencias/>} />
          <Route path="GrupLac" element={<GrupLac/>} />
          <Route path="SibColombia" element={<SibColombia/>} />
        </Routes>
        <ScrollToTop smooth width='20' height='20' color="#6f00ff" />
      </div>
    </React.Fragment>
  )
}
