import './Home.css'
import IconBioinformatic from '../../../../assets/images/icon-bioinformatic.svg';
import IconPipelines from '../../../../assets/images/icon-pipeline.svg';
import IconDatabases from '../../../../assets/images/icon-databases.svg';
import IconPersephone from '../../../../assets/images/logo-persphone.png';
import IconSearch from '../../../../assets/images/icon-search.svg';
import React, { useEffect, useState } from 'react'
import { NavLink} from "react-router-dom"
import * as echarts from 'echarts';
import Swiper, { Pagination, Manipulation, FreeMode } from 'swiper';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
require('jquery-mousewheel');

export default function Home() {

  useEffect(() => {

    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    /**
     * Swiper para la navegación de las vistas de bioinformática
     */

    new Swiper('.swiper-container-navigation-', {
      modules: [Manipulation, FreeMode, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 20,
      grabCursor: true,
      breakpoints: {
        320: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        576: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        768: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        992: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1400: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
      }
    });

    /**
     * Swiper para mostrar los valores del numero de las especies
     */

    new Swiper('.swiper-container-count-species-', {
      modules: [Manipulation, FreeMode, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 20,
      grabCursor: true,
      breakpoints: {
        320: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        576: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        768: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        992: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1400: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
        1920: {
          slidesPerView: "auto",
          spaceBetween: 20,
        },
      }
    });

  }, []);

  /**
   * Scrollbar en las tablas en el eje y , x
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-bioinformatic-' src={IconBioinformatic} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Bioinformática
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  Desde la forma de vida más pequeña hasta la más grande, desde bacterias e insectos, hasta la ballena azul. La bioinformática permite acercarnos a ese maravilloso misterio que es la vida. El estudio de la vida facilitado mediante métodos informáticos, esa es la bioinformática. Un campo interdisciplinario que combina biología, ciencias de la computación, matemáticas, estadística y la aplicación de métodos computacionales para entender procesos biológicos. En este módulo encontrarás herramientas y bases de datos para facilitar tus estudios en genómica y transcriptómica. Este es solo el inicio, nuestro objetivo es seguir creciendo y poder integrar más campos de la bioinformática.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row row-cols-auto d-flex flex-wrap justify-content-center align-items-center align-self-center mt-4'>
        <div className='col-12'>
          <div className="swiper swiper-container-navigation-">
            <div className="swiper-wrapper">
              <div className='swiper-slide d-flex flex-row justify-content-center align-items-start align-self-start'>
                <NavLink className='nav-link' to='/dashboard/bioinformatic/pipelines/'>
                  <div className='d-flex flex-column justify-content-center align-items-center align-self-start wrapper-card-navigation-'>
                    <div id="card-navigation-" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                      <div className='card border-0 position-relative overflow-hidden bs-5-'>
                        <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                          <img src={IconPipelines} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className='w-auto mt-3'>
                      <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-normal text-center'>Flujos de trabajo</p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='swiper-slide d-flex flex-row justify-content-center align-items-start align-self-start'>
                <NavLink className='nav-link' to='/dashboard/bioinformatic/databasesBioinformatic/'>
                  <div className='d-flex flex-column justify-content-center align-items-center align-self-start wrapper-card-navigation-'>
                    <div id="card-navigation-" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                      <div className='card border-0 position-relative overflow-hidden bs-5-'>
                        <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                          <img className='icon-databases' src={IconDatabases} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className='w-auto mt-3'>
                      <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-normal text-center'>Bases de datos</p>
                    </div>
                  </div>
                </NavLink>
              </div>
              <div className='swiper-slide d-flex flex-row justify-content-center align-items-start align-self-start'>
                <NavLink className='nav-link' to='/dashboard/bioinformatic/persefone/'>
                  <div className='d-flex flex-column justify-content-center align-items-center align-self-start wrapper-card-navigation-'>
                    <div id="card-navigation-" className='w-100 d-flex flex-row justify-content-center align-items-center align-self-center cursor-'>
                      <div className='card border-0 position-relative overflow-hidden bs-5-'>
                        <div className='w-100 h-100 d-flex flex-row justify-content-center align-items-center align-self-center'>
                          <img src={IconPersephone} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className='w-auto mt-3'>
                      <p className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-normal text-center'>Persephone</p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
