import './Results.css'
import IconResults from '../../../../../assets/images/icon-results.svg';
import IconSearch from '../../../../../assets/images/icon-search.svg';
import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactFlow, { Background, Handle, Position, MarkerType, Controls } from 'reactflow';
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'reactflow/dist/style.css';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
import { Appcontext } from '../../../../../appContext';
import { getDataResults, getDownloadFile, postCancelResults } from '../../../../../services/dahsboard/bioinformatic/pipelines/results/results';
import Preloader from '../../../../shared/preloader/Preloader';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
require('jquery-mousewheel');

export default function Results() {
  const { token, setToken } = useContext(Appcontext);
  const navigate = useNavigate();
  const [charging, setCharging] = useState(false);
  const [dataResults, setDataResults] = useState(null);
  const [search, setSearch] = useState('');
  const [activeTab, setActiveTab] = useState('genomics'); // Cambia a 'transcriptomics' para mostrar el gráfico de Transcriptómica
  const [activeResult, setActiveResult] = useState(null);
  const [nodeTypesGenomics, setNodeTypesGenomics] = useState({});
  const [nodeTypesTranscriptomics, setNodeTypesTranscriptomics] = useState({});
  const [genomeSteps, setGenomeSteps] = useState({
    'ANÁLISIS DE CALIDAD INICIAL': 1,
    'ANÁLISIS DE CALIDAD CURADO': 3,
    'ANÁLISIS DE CALIDAD DE ENSAMBLAJE': 6,
    'ANOTACION ESTRUCTURAL': 7,
    'ANOTACION FUNCIONAL': 8
  });
  const [transcriptomicSteps, setTranscriptomicSteps] = useState({
    'ANÁLISIS DE CALIDAD INICIAL': 1,
    'ANÁLISIS DE CALIDAD CURADO': 3,
    'ANÁLISIS DE CALIDAD DE ENSAMBLAJE': 6,
    'ANOTACION ESTRUCTURAL': 10,
    'ANOTACION FUNCIONAL': 11
  });
  const [defaultNodesGenomics, setDefaultNodeGenomics] = useState([
    {
      id: 'A',
      step: 1,
      type: 'nodeQualityControlStepOne',
      data: { name: 'Control de calidad de lecturas', job: '(FastQC, MultiQC)', action: '', description: '' },
      style: { border: '2px solid var(--color-green-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 20 }
    },
    {
      id: 'B',
      step: 2,
      type: 'nodeNormal',
      data: { name: 'Preprocesamiento de lecturas', job: '(Trimmomatic, Cutadapt)' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 344, y: 20 }
    },
    {
      id: 'C',
      step: 3,
      type: 'nodeQualityControlStepTwo',
      data: { name: 'Control de calidad de lecturas', job: '(FastQC, MultiQC)', action: '', description: '' },
      style: { border: '2px solid var(--color-yellow-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 20 }
    },
    {
      id: 'D',
      step: 4,
      type: 'nodeNormal',
      data: { name: 'Ensamblaje de novo', job: '(SPAdes, MaSuRCA, ABySs)' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 200 }
    },
    {
      id: 'E',
      step: 5,
      type: 'nodeAssembledGenome',
      data: { name: 'Genoma ensamblado', job: '(Formato fasta)', action: '' },
      style: { border: '2px solid var(--color-secondary-pink)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 100 },
      draggable: false,
      position: { x: 344, y: 200 }
    },
    {
      id: 'F',
      step: 6,
      type: 'nodeQualityControlAssembly',
      data: { name: 'Control de calidad del ensamblaje', job: '(QUAST, BUSCO)', action: '', description: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 200 }
    },
    {
      id: 'G',
      step: 7,
      type: 'nodeStructuralAnnotation',
      data: { name: 'Anotación estructural', job: '(Prokka, MAKER2)', action: '', description: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 380 }
    },
    {
      id: 'H',
      step: 8,
      type: 'nodeFunctionalAnnotation',
      data: { name: 'Anotación funcional', job: '', action: '', description: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 344, y: 380 }
    },
    {
      id: 'I',
      step: 9,
      type: 'nodeAnnotationFile',
      data: { name: 'Archivo de anotación', job: '', action: '' },
      style: { border: '2px solid var(--color-white-)', backgroundColor: 'var(--color-black-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 380 }
    },
  ]);

  const [defaultNodesTranscriptomics, setDefaultNodesTranscriptomics] = useState([
    {
      id: 'A',
      type: 'nodeQualityControlStepOneTranscriptome',
      data: { name: 'Control de calidad de lecturas', job: '(FastQC, MultiQC)', action: '' },
      style: { border: '2px solid var(--color-green-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 20 }
    },
    {
      id: 'B',
      type: 'nodeNormal',
      data: { name: 'Preprocesamiento de lecturas', job: '(Trimmomatic, Cutadapt, Fastp)' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 344, y: 20 }
    },
    {
      id: 'C',
      type: 'nodeQualityControlStepTwoTranscriptome',
      data: { name: 'Control de calidad de lecturas', job: '(FastQC, MultiQC)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 20 }
    },
    {
      id: 'D',
      type: 'nodeNormal',
      data: { name: 'Ensamblaje de novo', job: '(Trínity)' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 200 }
    },
    {
      id: 'E',
      type: 'nodeAssembledTranscriptome',
      data: { name: 'Transcriptoma ensamblado', job: '(Formato fasta)', action: '' },
      style: { border: '2px solid var(--color-secondary-pink)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 100 },
      draggable: false,
      position: { x: 344, y: 200 }
    },
    {
      id: 'F',
      type: 'nodeAssemblyQualityControlTranscriptome',
      data: { name: 'Control de calidad del ensamblaje', job: '(rnaQUAST)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 200 }
    },
    {
      id: 'G',
      type: 'nodeNormal',
      data: { name: 'Cuantificación de la expresión de transcripción', job: '', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 380 }
    },
    {
      id: 'H',
      type: 'nodeNormal',
      data: { name: 'Matriz de recuento de transcritos', job: '', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 344, y: 380 }
    },
    {
      id: 'I',
      type: 'nodeDifferentialExpressionTranscriptome',
      data: { name: 'Expresión diferencial', job: '(Gráfico Volcano)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 380 }
    },
    {
      id: 'J',
      type: 'nodeClusterExtractionTranscriptome',
      data: { name: 'Extración de grupos de transcritos por perfiles de expresión', job: '(Dendrograma)', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 44, y: 560 }
    },
    {
      id: 'K',
      type: 'nodeNormal',
      data: { name: 'Anotación funcional', job: '', action: '' },
      style: { border: '2px solid var(--color-white-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 344, y: 560 }
    },
    {
      id: 'L',
      type: 'nodeAnnotationReportTranscriptome',
      data: { name: 'Reporte de anotación', job: 'TRINOTATE', action: '' },
      style: { border: '2px solid var(--color-white-)', backgroundColor: 'var(--color-black-)', width: '220px', minWidth: '200px', maxWidth: '220px', height: '130px', borderRadius: 15 },
      draggable: false,
      position: { x: 644, y: 560 }
    },
  ]);

  const QualityControlStepOne = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md" data-tooltip-id="tooltip-successful">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        {
          data.description
          ?
          <ReactTooltip
            id="tooltip-successful"
            place="bottom"
            style={{ backgroundColor: "var(--color-green-)", color: "var(--color-black-)" }}
            html={`<p class='p-0 m-0 lh-sm'>${data.description}</p>`}
          />
          :
          null
        }
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const QualityControlStepTwo = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md" data-tooltip-id="tooltip-warning">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        {
          data.description
          ?
          <ReactTooltip
            id="tooltip-warning"
            place="bottom"
            style={{ backgroundColor: "var(--color-yellow-)", color: "var(--color-black-)" }}
            html={`<p class='p-0 m-0 lh-sm'>${data.description}</p>`}
          />
          :
          null
        }
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const AssembledGenome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md" data-tooltip-id="tooltip-error">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        {
          data.description
          ?
          <ReactTooltip
            id="tooltip-error"
            place="bottom"
            style={{ backgroundColor: "var(--color-secondary-pink)", color: "var(--color-white-)" }}
            html="<p class='p-0 m-0 lh-sm'>Error en la ejecución del trabajo</p>"
            offset={20}
          />
          :
          null
        }
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const QualityControlAssembly = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const StructuralAnnotation = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const FunctionalAnnotation = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const AnnotationFile = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeEnd-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const CustomNodeNormal = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };


  const QualityControlStepOneTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const QualityControlStepTwoTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const AssembledTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const AssemblyQualityControlTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const DifferentialExpressionTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const ClusterExtractionTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeCustom-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const AnnotationReportTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center nodeEnd-">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const CustomNodeNormalTranscriptome = ({ data }) => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center align-self-center p-2 shadow-md rounded-md">
        <div className="d-flex flex-column justify-content-center align-items-center align-self-center">
          <div className="mt-2">
            <p className="m-0 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-white-">{data.name}</p>
            <p className="m-0 mt-1 p-0 lh-sm text-center fs-5- ff-monse-regular- tx-gray-light-">{data.job}</p>
          </div>
        </div>
        <Handle type="source" position={Position.Right} />
        <Handle type="target" position={Position.Left} />
      </div>
    );
  };

  const defaultEdgesTranscriptomics = [
    {
      id: 'A->B',
      type: 'smoothstep',
      source: 'A',
      sourceHandle: 'right',
      target: 'B',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 18,
        height: 18,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'B->C',
      type: 'smoothstep',
      source: 'B',
      sourceHandle: 'right',
      target: 'C',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 18,
        height: 18,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'C->D',
      type: 'smoothstep',
      source: 'C',
      sourceHandle: 'right',
      target: 'D',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'D->E',
      type: 'smoothstep',
      source: 'D',
      sourceHandle: 'right',
      target: 'E',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'E->F',
      type: 'smoothstep',
      source: 'E',
      sourceHandle: 'right',
      target: 'F',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'F->G',
      type: 'smoothstep',
      source: 'F',
      sourceHandle: 'right',
      target: 'G',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'G->H',
      type: 'smoothstep',
      source: 'G',
      sourceHandle: 'right',
      target: 'H',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'H->I',
      type: 'smoothstep',
      source: 'H',
      sourceHandle: 'right',
      target: 'I',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'I->J',
      type: 'smoothstep',
      source: 'I',
      sourceHandle: 'right',
      target: 'J',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'J->K',
      type: 'smoothstep',
      source: 'J',
      sourceHandle: 'right',
      target: 'K',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'K->L',
      type: 'smoothstep',
      source: 'K',
      sourceHandle: 'right',
      target: 'L',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
  ];

  const defaultEdgesGenomics = [
    {
      id: 'A->B',
      type: 'smoothstep',
      source: 'A',
      sourceHandle: 'right',
      target: 'B',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 18,
        height: 18,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'B->C',
      type: 'smoothstep',
      source: 'B',
      sourceHandle: 'right',
      target: 'C',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 18,
        height: 18,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'C->D',
      type: 'smoothstep',
      source: 'C',
      sourceHandle: 'right',
      target: 'D',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'D->E',
      type: 'smoothstep',
      source: 'D',
      sourceHandle: 'right',
      target: 'E',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'E->F',
      type: 'smoothstep',
      source: 'E',
      sourceHandle: 'right',
      target: 'F',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'F->G',
      type: 'smoothstep',
      source: 'F',
      sourceHandle: 'right',
      target: 'G',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      animated: true,
      style: { strokeWidth: 1, stroke: '#FFFFFF', strokeDasharray: 6 },
      arrowHeadType: 'arrow',
    },
    {
      id: 'G->H',
      type: 'smoothstep',
      source: 'G',
      sourceHandle: 'right',
      target: 'H',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    },
    {
      id: 'H->I',
      type: 'smoothstep',
      source: 'H',
      sourceHandle: 'right',
      target: 'I',
      targetHandle: 'left',
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: '#FFFFFF'
      },
      style: { strokeWidth: 1, stroke: '#FFFFFF' },
      arrowHeadType: 'arrow',
    }
  ];

  useEffect(() => {
    configurations();
    getData();

    /**
     * Responsive de la grafica de flowchart genomics
     */

    const handleResizeGenomics = () => {
      const widthGenomics = containerRefGenomics.current.offsetWidth;
      const heightGenomics = containerRefGenomics.current.offsetHeight;

      setcontainerSizeGenomics({ widthGenomics, heightGenomics });
    };

    window.addEventListener('resize', handleResizeGenomics);
    handleResizeGenomics();

    return () => {
      window.removeEventListener('resize', handleResizeGenomics);
    };
  }, []);

  useEffect(() => {
    if (nodeTypesGenomics) {
      const resizeEvent = new Event('resize');
      window.dispatchEvent(resizeEvent);
    }
  }, [nodeTypesGenomics]);

  useEffect(() => {
    if (nodeTypesTranscriptomics) {
      const resizeEvent = new Event('resize');
      window.dispatchEvent(resizeEvent);
    }
  }, [nodeTypesTranscriptomics]);

  /**
   * Container de la grafica de flowchart genomics
   */

  const containerRefGenomics = useRef(null);
  const [containerSizeGenomics, setcontainerSizeGenomics] = useState({ width: 0, height: 0 });

  /**
   * REVIEW GENOMICS
   */


  /**
   * Container de la grafica de flowchart transcriptomics
   */

  const containerRefTranscriptomics = useRef(null);
  const [containerSizeTranscriptomics, setcontainerSizeTranscriptomics] = useState({ width: 0, height: 0 });

  useEffect(() => {

    /**
    * Responsive de la grafica de flowchart genomics
    */

    const handleResizeTranscriptomics = () => {
      const widthTranscriptomics = containerRefTranscriptomics.current.offsetWidth;
      const heightTranscriptomics = containerRefTranscriptomics.current.offsetHeight;

      setcontainerSizeTranscriptomics({ widthTranscriptomics, heightTranscriptomics });
    };

    window.addEventListener('resize', handleResizeTranscriptomics);
    handleResizeTranscriptomics();

    return () => {
      window.removeEventListener('resize', handleResizeTranscriptomics);
    };

  }, []);

  /**
  * REVIEW TRANSCRIPTOMICS
  */

  const configurations = () => {
    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis: "y",
      mouseWheel: {
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia: 100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis: "x",
      mouseWheel: { enable: false },
      scrollInertia: 100,
      mouseWheelPixels: 100
    });
  };

  const getData = async () => {
    setCharging(true);
    let response = await getDataResults(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'Ocurrió un error al intentar cargar los resultados',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      let array = [];
      data.map(row => {
        array.push({
          ...row,
          checked: false
        });
      });
      setDataResults(array);
    }
  };

  const changeCheck = (row) => {
    let array = dataResults.map(result => result);
    let index = array.findIndex(result => result === row);
    array[index] = {
      ...array[index],
      checked: !array[index].checked
    };
    setDataResults(array);
  };

  const conditionsCancel = () => {
    let bool = false;
    if (dataResults && dataResults.length > 0) {
      let index = 0;
      while (!bool && index < dataResults.length) {
        bool = dataResults[index].checked;
        index++;
      }
    }
    return bool;
  };

  const cancelResults = async () => {
    Swal.fire({
      text: 'Seguro que desea cancelar los resultados?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setCharging(true);
        let response = await postCancelResults(dataResults, token).catch(error => {
          setCharging(false);
          if (error?.response.status === 401) {
            Swal.fire({
              text: 'Su sesión expiró. Le cerraremos la sesión',
              icon: 'info'
            });
            setToken(null);
            sessionStorage.setItem('token', '');  
            navigate('/auth');
          } else {
            Swal.fire({
              text: 'Ocurrión un error al intentar eliminar los trabajos',
              icon: 'error'
            });
          }
        });
        if (response) {
          setSearch('');
          setCharging(false);
          await getData();
        }
      }
    });
  };

  const downloadResult = async (result) => {
    setCharging(true);
    let response = await getDownloadFile(result.id, token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'El archivo no existe',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      let fileUrl = URL.createObjectURL(data);
      window.open(fileUrl, '_blank');
      setCharging(false);
    }
  };

  const displayInfo = (result) => {
    let array = [];
    if (result.analysis === 'genomics') { 
      for (let index = 0; index < result.steps.length; index++) {
        array.push({
          stepName: result.steps[index].step,
          step: obtainStepGenome(result.steps[index].step),
          status: result.steps[index].status,
          description: result.steps[index].description
        });      
      }
      configurationNodesGenomics(array, result.status);
    } else if (result.analysis === 'transcriptomics') {
      for (let index = 0; index < result.steps.length; index++) {
        array.push({
          stepName: result.steps[index].step,
          step: obtainStepTranscriptomic(result.steps[index].step),
          status: result.steps[index].status,
          description: result.steps[index].description
        });      
      }
      configurationNodesTranscriptomics(array, result.status);
    }
    setActiveResult(result);    
  };

  const obtainStepGenome = (value) => {
    for (let key in genomeSteps) {
      if (key === value) {
        return genomeSteps[key];
      }
    }
  };

  const obtainStepValueGenome = (value) => {
    for (let key in genomeSteps) {
      if (genomeSteps[key] === value) {
        return true;
      }
    }
    return false;
  };

  const configurationNodesGenomics = (array, status) => {
    let newArray = defaultNodesGenomics.map(node => node);
    for (let index = 0; index < newArray.length; index++) {
      let object = array.find(node => node.step === newArray[index].step);
      let objectStep = obtainStepValueGenome(newArray[index].step);
      let otherCase = array.find(node => node.step > newArray[index].step);
      if (object) {
        newArray[index].style.border = defineColorGenome(object.status);
        newArray[index].data.description = object.description;
      } else if (objectStep) {
        newArray[index].data.description = '';
        let colorStep = status === 'FINALIZADO' ? status : null
        newArray[index].style.border = defineColorGenome(colorStep);
      } else if (status === 'FINALIZADO') {
        newArray[index].style.border = defineColorGenome('FINALIZADO');
      } else {
        let colorStatus = otherCase ? 'FINALIZADO' : null;
        newArray[index].style.border = defineColorGenome(colorStatus);
      }
    }
    setDefaultNodeGenomics(newArray);
    setNodeTypesGenomics({
      nodeQualityControlStepOne: QualityControlStepOne,
      nodeQualityControlStepTwo: QualityControlStepTwo,
      nodeAssembledGenome: AssembledGenome,
      nodeQualityControlAssembly: QualityControlAssembly,
      nodeStructuralAnnotation: StructuralAnnotation,
      nodeFunctionalAnnotation: FunctionalAnnotation,
      nodeAnnotationFile: AnnotationFile,
      nodeNormal: CustomNodeNormal,
    });
  };

  const defineColorGenome = (status) => {
    if (status === 'FINALIZADO') {
      return '2px solid var(--color-green-)';
    } else if (status === 'ADVERTENCIA') {
      return '2px solid var(--color-yellow-)';
    } else if (status === 'FALLO') {
      return '2px solid var(--color-secondary-pink)';
    } else {
      return '2px solid var(--color-white-)';
    }
  }

  const obtainStepTranscriptomic = (value) => {
    for (let key in transcriptomicSteps) {
      if (key === value) {
        return transcriptomicSteps[key];
      }
    }
  };

  const obtainStepValueTranscriptomic = (value) => {
    for (let key in transcriptomicSteps) {
      if (transcriptomicSteps[key] === value) {
        return true;
      }
    }
    return false;
  };

  const configurationNodesTranscriptomics = (array, status) => {
    let newArray = defaultNodesTranscriptomics.map(node => node);
    for (let index = 0; index < newArray.length; index++) {
      let object = array.find(node => node.step === newArray[index].step);
      let objectStep = obtainStepValueTranscriptomic(newArray[index].step);
      let otherCase = array.find(node => node.step > newArray[index].step);
      if (object) {
        newArray[index].style.border = defineColorTranscriptomics(object.status);
        newArray[index].data.description = object.description;
      } else if (objectStep) {
        newArray[index].data.description = '';
        let colorStep = status === 'FINALIZADO' ? status : null
        newArray[index].style.border = defineColorTranscriptomics(colorStep);
      } else if (status === 'FINALIZADO') {
        newArray[index].style.border = defineColorTranscriptomics('FINALIZADO');
      } else {
        let colorStatus = otherCase ? 'FINALIZADO' : null;
        newArray[index].style.border = defineColorTranscriptomics(colorStatus);
      }
    }
    setDefaultNodesTranscriptomics(newArray);
    setNodeTypesTranscriptomics({
      nodeQualityControlStepOneTranscriptome: QualityControlStepOneTranscriptome,
      nodeQualityControlStepTwoTranscriptome: QualityControlStepTwoTranscriptome,
      nodeAssembledTranscriptome: AssembledTranscriptome,
      nodeAssemblyQualityControlTranscriptome: AssemblyQualityControlTranscriptome,
      nodeDifferentialExpressionTranscriptome: DifferentialExpressionTranscriptome,
      nodeClusterExtractionTranscriptome: ClusterExtractionTranscriptome,
      nodeAnnotationReportTranscriptome: AnnotationReportTranscriptome,
      nodeNormal: CustomNodeNormalTranscriptome,
    });
  };

  const defineColorTranscriptomics = (status) => {
    if (status === 'FINALIZADO') {
      return '2px solid var(--color-green-)';
    } else if (status === 'ADVERTENCIA') {
      return '2px solid var(--color-yellow-)';
    } else if (status === 'FALLO') {
      return '2px solid var(--color-secondary-pink)';
    } else {
      return '2px solid var(--color-white-)';
    }
  }

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  return (
    <React.Fragment>
      {
        charging
          ?
          <Preloader></Preloader>
          :
          null
      }
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-results-' src={IconResults} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Resultados
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  En aras de ofrecer interpretaciones biológicas valiosas para tus estudios, ponemos a disposición una carpeta con los resultados de cada módulo del flujo de trabajo. De cada módulo, surgen una serie de archivos.  Algunos de ellos serán la entrada para el siguiente módulo de procesamiento y otros archivos son resultados complementarios que quedan a tu disposición. Al final los dos resultados principales serán: para genómica, genomas ensamblados y anotaciones y para transcriptómica, anotaciones de transcritos y paneles de genes diferencialmente expresados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12'>
          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-3 pt-3'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                <div className='col d-flex flex-column'>
                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                      Datos registrados
                    </h1>
                  </div>
                  <div className='w-auto mt-3 '>
                    <form action="" className='position-relative wrapper-search-small- me-3'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={search} onChange={({ target }) => setSearch(target.value)} />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                        <div className='card-header border-0 bg-transparent pb-0'>
                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                        </div>
                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                            # <span>{dataResults?.length}</span>
                          </p>
                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                            Registros
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-0 w-100 wrapper-data-'>
              <div className='table-responsive table-general-'>
                <table className='table table-sm table-striped table-no-border- align-middle'>
                  <thead>
                    <tr>
                      <th scope="col" className='th-width-xs-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'></span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Especie</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Estado</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tamaño del genoma</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tamaño del reporte</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Análisis</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Fecha inicial</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Fecha de ejecución</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-md-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Fecha final</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-sm-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Acciones</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataResults?.filter(table => {
                        if (search !== '') {
                          let keys = Object.keys(table);
                          let flagKeys = false;
                          let i = 0;
                          while (i < keys.length && !flagKeys) {
                            if (keys[i] === 'specie_name' || keys[i] === 'status' || keys[i] === 'genome_size' || keys[i] === 'report_size' || keys[i] === 'analysis' || keys[i] === 'start_date' || keys[i] === 'execution_start_date' || keys[i] === 'finish_date') {
                              let word = '' + table[keys[i]];
                              let words = word.split(' ');
                              let j = 0;
                              flagKeys = word.toUpperCase().includes(search.toUpperCase());
                              while (j < words.length && !flagKeys) {
                                if (words[j].toUpperCase().includes(search.toUpperCase())) {
                                  flagKeys = true;
                                }
                                j++;
                              }
                              if (search.toUpperCase() === word.substring(0, search.length).toUpperCase()) {
                                flagKeys = true;
                              }
                            }
                            if (flagKeys) {
                              return true;
                            }
                            i++;
                          }

                        } else {
                          return true;
                        }
                      }).map(row => (
                        <tr>
                          <td className='align-middle'>
                            <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-center'>
                              {
                                row.status !== 'FINALIZADO' && row.status !== 'CANCELADO'
                                  ?
                                  <div className='checks-radios-'>
                                    <label>
                                      <input type="checkbox" name="radio" onClick={() => changeCheck(row)} checked={row.checked} />
                                      <span className='lh-sm fs-5- ff-monse-regular- tx-dark-purple-'></span>
                                    </label>
                                  </div>
                                  :
                                  null
                              }
                            </div>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.specie_name}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.status}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.genome_size}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.report_size}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.analysis}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.start_date}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.execution_start_date}</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{row.finish_date}</p>
                          </td>
                          <td className='align-middle'>
                            <div className='row gx-1 d-flex flex-row justify-content-center align-items-start align-self-start'>
                              <div className='col-auto'>
                                <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" data-bs-toggle="offcanvas" data-bs-target="#pipeline-review  " aria-controls="pipeline-review" onClick={() => displayInfo(row)} >
                                  <i className='fa icon-pipeleine fs-xxs'></i>
                                </button>
                              </div>
                              <div className='col-auto'>
                                <button className='btn rounded-pill p-2 d-flex flex-row justify-content-center align-items-center align-self-center' type="button" onClick={() => downloadResult(row)} >
                                  <i className='fa icon-download fs-xxs'></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer border-0 bg-transparent pb-3 pt-3">
              {
                /*
                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                  <div className='col-auto'>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {startPage !== 1 && (
                          <li className="page-item">
                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                          </li>
                        )}
                        {startPage > 2 && (
                          <li className="page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                          </li>
                        ))}
                        {endPage < totalPages - 1 && (
                          <li className="page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        {endPage !== totalPages && (
                          <li className="page-item">
                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
                */
              }
              <div className='row g-2 d-flex flex-row justify-content-end align-items-start align-self-start'>
                <div className='col-auto'>
                  <button className='btn rounded-4 ps-3 pe-3 ps-sm-3 pe-sm-3 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5 ps-xl-5 pe-xl-5 ps-xxl-5 pe-xxl-5 h-40- d-flex flex-row justify-content-center align-items-center align-self-center btn-red- bs-6-' type="button" disabled={!conditionsCancel()} onClick={cancelResults} >
                    <i className="fa icon-genomics d-block d-sm-block d-md-none d-lg-none d-xl-none d-xxl-none me-0 me-sm-0 me-md-2 me-lg-2 me-xl-2 me-xxl-2"></i><span className='d-none d-sm-none d-md-block d-lg-block d-xl-block d-xxl-block lh-1 fs-5- ff-monse-regular-'>Cancelar trabajo</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='offcanvas offcanvas-bottom offcanvas-bottom-' tabIndex="-1" data-bs-backdrop="false" id="pipeline-review"
        aria-labelledby="pipeline-review" data-bs-scroll="false">
        <div className='offcanvas-header pt-4 pb-4'>
          <h2 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-green-light-'>Pipeline</h2>
          <button type="button"
            className='btn-close-offcanvas'
            data-bs-dismiss="offcanvas">
            <i className='fa icon-close'></i>
          </button>
        </div>
        <div className='offcanvas-body'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='w-100 mb-5'>
                  <ul className="nav nav-pills d-flex flex-row justify-content-center row gx-2" role="tablist">
                    {
                      activeResult && activeResult.analysis === 'genomics'
                        ?
                        <li className="nav-item" role="presentation">
                          <button className={`nav-link active ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-50- bs-6-`}
                            id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills-genomics" type="button" role="tab" aria-controls="pills-genomics" aria-selected={activeTab === 'genomics' ? 'true' : 'false'} ><i className='fa icon-genomics me-2 fs-xs'></i><span className="ff-monse-regular-">Genómica</span></button>
                        </li>
                        :
                        null
                    }
                    {
                      activeResult && activeResult.analysis === 'transcriptomics'
                        ?
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link active ps-4 pe-4 rounded-pill rounded-4 d-flex flex-row justify-content-center align-items-center align-self-center h-50- bs-6-`}
                            id="data-patient-tab" data-bs-toggle="pill" data-bs-target="#pills-transcriptomics" type="button" role="tab" aria-controls="pills-transcriptomics" aria-selected={activeTab === 'transcriptomics' ? 'true' : 'false'}><i className='fa icon-transcriptomics me-2 fs-xs'></i><span className="ff-monse-regular-">Transcriptómica</span></button>
                        </li>
                        :
                        null
                    }
                  </ul>
                </div>
                <div className='w-100'>
                  <div className="tab-content w-100" id="contentTables">
                    <div className={`tab-pane fade ${activeResult && activeResult.analysis === 'genomics' ? 'show active' : ''} w-100`} id="pills-genomics" role="tabpanel" aria-labelledby="genomics-tab" tabIndex="0">
                      <div id='review-genomics-' className='card border-0 br-1- w-100 bg-blue-translucent-'>
                        <div className='card-body p-0 w-100' ref={containerRefGenomics}>
                          {containerSizeGenomics.widthGenomics && containerSizeGenomics.heightGenomics && (
                            <ReactFlow
                              style={{ width: containerSizeGenomics.widthGenomics, height: containerSizeGenomics.heightGenomics }}
                              nodeTypes={nodeTypesGenomics}
                              defaultNodes={defaultNodesGenomics}
                              defaultEdges={defaultEdgesGenomics}>
                              <Background show={false} />
                              <Controls showZoom={false} showZoomOut={false} showInteractive={false} />
                            </ReactFlow>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane fade ${activeResult && activeResult.analysis === 'transcriptomics' ? 'show active' : ''} w-100`} id="pills-transcriptomics" role="tabpanel" aria-labelledby="transcriptomics-tab" tabIndex="0">
                      <div id='review-transcriptomics-' className='card border-0 br-1- w-100 bg-blue-translucent-'>
                        <div className='card-body p-0 w-100' ref={containerRefTranscriptomics}>
                          {containerSizeTranscriptomics.widthTranscriptomics && containerSizeTranscriptomics.heightTranscriptomics && (
                            <ReactFlow
                              style={{ width: containerSizeTranscriptomics.widthTranscriptomics, height: containerSizeTranscriptomics.heightTranscriptomics }}
                              nodeTypes={nodeTypesTranscriptomics}
                              defaultNodes={defaultNodesTranscriptomics}
                              defaultEdges={defaultEdgesTranscriptomics}>
                              <Background show={false} />
                              <Controls showZoom={false} showZoomOut={false} showInteractive={false} />
                            </ReactFlow>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
