import Logo3bio from '../../../assets/images/logo-3bio-small.png';
import React, { useContext, useEffect, useState } from 'react'
import { postForgot } from '../../../services/auth/forgot/forgot';
import Preloader from '../../shared/preloader/Preloader';
import Swal from 'sweetalert2';
import { Appcontext } from '../../../appContext';

export default function Forgot() {
  const { setFoldersBioinformatic } = useContext(Appcontext);
  const [charging, setCharging] = useState(false);
  const [user, setUSer] = useState({
    email: '',
    repeatEmail: ''
  });

  useEffect(() => {
    setFoldersBioinformatic(null);
  }, []);

  const changeValue = ({ target }) => {
    setUSer({
      ... user,
      [target.name]: target.value
    });
  };

  const conditions = () => {
    return user.email === '' || user.repeatEmail === '' || user.email !== user.repeatEmail;
  };

  const sendEmail = async () => {
    setCharging(true);
    let response = await postForgot(user).catch(error => {
      setCharging(false);
      if (error?.response?.data?.detail === 'Usuario no existe') {
        Swal.fire({
          text: 'El usuario no existe',
          icon: 'error'
        });
      } else {
        Swal.fire({
          text: 'Ha ocurrido un error. Vuelva a intentar',
          icon: 'error'
        });
      }
    });
    if (response) {
      setCharging(false);
      Swal.fire({
        text: 'Hemos enviado un correo a tu email registrado para el cambio de contraseña',
        icon: 'success'
      });
    }
  };

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='container-fluid overflow-x-hidden d-flex flex-column justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center justify-content-xxl-center align-items-center align-self-center vw-100 pt-5 pb-5 bg-medical- min-h-auth-'>
        <div className='row'>
          <div className='col-12 h-auto d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
            <div className='w-100 d-flex flex-column justify-content-center align-items-center align-self-center pb-4'>
              <img className='logo-3bio-small' src={Logo3bio} alt="" />
            </div>
            <div className='w-100 d-flex flex-column justify-content-start align-items-center align-self-center ps-2 pe-2 ps-sm-2 pe-sm-2 ps-md-2 pe-md-2 ps-lg-2 pe-lg-2 ps-xl-2 pe-xl-2 ps-xxl-2 pe-xxl-2 pb-3'>
            </div>
            <div className='card border-0 br-1- bg-blue-translucent- glass-effect- bs-4- p-0 card-small-auth-'>
                <div className='card-body'>
                  <form id='internal-form-inline' action='' className='position-relative'>
                    <div className='row'>
                      <div className='col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Correo</label>
                        <input type="text" className='form-control' placeholder="Ingrese su correo" name='email' value={user.email} onChange={changeValue} />
                      </div>
                      <div className='col-12 mb-3 mb-sm-3 mb-md-3 mb-lg-3 mb-xl-3 mb-xxl-3'>
                        <label class="form-label h-sm fs-5- ff-monse-regular- fw-bold tx-white-">Repetir Correo</label>
                        <input type="text" className='form-control' placeholder="Confirme su correo de nuevo" name='repeatEmail' value={user.repeatEmail} onChange={changeValue} />
                      </div>
                    </div>
                    <div className='row g-3 d-flex flex-row justify-content-center align-items-start align-self-start mt-2'>
                      <div className='col-12'>
                        <button className='btn rounded-pill d-flex flex-row justify-content-center align-items-center align-self-center w-100 btn-green-light- h-40-' type="button" onClick={sendEmail} disabled={conditions()} >
                          <span className='lh-1 fs-6- ff-monse-regular- fw-semibold'>Recuperar</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
