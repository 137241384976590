import axios from 'axios';
import { environment } from '../../../../environments/environment';

const getGraphics = async (token) => {
  const path = environment.api + environment.getGeneralGraphics;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getCards = async (token) => {
  const path = environment.api + environment.getCards;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getTableInstitutionGroups = async (token) => {
  const path = environment.api + environment.getTableGroups;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getTableGeneralArticles = async (token) => {
  const path = environment.api + environment.getTableGeneral;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

const getTableTopInstitutions = async (token) => {
  const path = environment.api + environment.getTableInstitutions;
  let config = {
    headers: {
      Authorization: 'Bearer ' + token,
    }
  };
  return await axios.get(path, config);
};

export { getGraphics, getCards, getTableInstitutionGroups, getTableGeneralArticles, getTableTopInstitutions };
