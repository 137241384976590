import React, { createContext, useState } from "react";

const Appcontext = createContext(null);
let tokenUser = sessionStorage.getItem('token');
let userInfo = JSON.parse(sessionStorage.getItem('UserInfo'));

function AppProvider(props) {

    const [token, setToken] = useState(tokenUser);

    // USUARIO
    const [userApplication, setUserApplication] = useState(userInfo);

    // CIENCIOMETRÍA

    const [cards, setCards] = useState(null);

    // Minciencias
    const [generalChartsData, setGeneralChartsData] = useState([]);
    const [tableInstitutions, setTableInstitutions] = useState({ data: [] });
    const [tableArticles, setTableArticles] = useState({ data: [] });
    const [tableTopInstitutions, setTableTopInstitutions] = useState([]);
    const [graphicsSib, setGraphicsSib] = useState([]);

    // Gruplac
    const [optionsInstitutions, setOptionsInstitutions] = useState(null);

    // BIOINFORMÁTICA

    // Archivos
    const [foldersBioinformatic, setFoldersBioinformatic] = useState(null);
    const [filesBioinformatic, setFilesBioinformatic] = useState(null);

    return (
        <Appcontext.Provider value={{
            userApplication, setUserApplication, token, setToken, cards, setCards, generalChartsData, setGeneralChartsData, tableInstitutions, setTableInstitutions, tableArticles, setTableArticles, graphicsSib, setGraphicsSib, optionsInstitutions, setOptionsInstitutions, foldersBioinformatic, setFoldersBioinformatic, filesBioinformatic, setFilesBioinformatic, tableTopInstitutions, setTableTopInstitutions
        }}>
           {props.children}
        </Appcontext.Provider>
    );
}

export {Appcontext, AppProvider} 
