import IconPersephone from '../../../../assets/images/logo-persphone.png';
import React from 'react'

export default function Persefone() {
  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-10'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-persefone-' src={IconPersephone} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Persefone
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  Las carpetas con miles y miles de archivos y los archivos con miles y miles de datos pueden ser abrumadores a veces. Es por ello que pensamos integrar una herramienta para visualizar los datos generados a partir de la ejecución de los flujos de trabajo. Persephone es un navegador de genomas capaz de mostrar rápidamente grandes conjuntos de datos gracias a algoritmos de compresión únicos permitiendo la visualización de genes y anotaciones, mapas genéticos, QTLs, SNPs, datos de secuenciación de ARN y sintenia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
