import IconSibcolombia from '../../../../assets/images/icon-sibcolombia.svg';
import IconSearch from '../../../../assets/images/icon-search.svg';
import React, { useContext, useEffect, useState } from 'react'
import * as echarts from 'echarts';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import { getGraphicsGeneralSib } from '../../../../services/dahsboard/scientometric/sibColombia/sibColombia';
import { Appcontext } from '../../../../appContext';
import Preloader from '../../../shared/preloader/Preloader';
import { getCards } from '../../../../services/dahsboard/scientometric/minciencias/minciencias';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

require('jquery-mousewheel');

export default function SibColombia() {
  const { cards, setCards, graphicsSib, setGraphicsSib, token, setToken } = useContext(Appcontext);
  const navigate = useNavigate();
  const [charging, setCharging] = useState(false);
  const [tableTopOrganizations, setTableTopOrganizations] = useState(null);
  const [tableTopCities, setTableTopCities] = useState(null);
  const [tableTopCountries, setTableTopCountries] = useState(null);
  const [searchOrganizations, setSearchOrganizations] = useState('');
  const [searchCities, setSearchCities] = useState('');
  const [searchCountries, setSearchCountries] = useState('');

  useEffect( () => {
    getServices();
  }, []);

  /**
   * Rango de número de instituciones
   */

  const [range, setRange ] = React.useState({
    min: 0,
    max: 20,
  });

  /**
   * Paginación para las tablas
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  const getServices = async () => {
    if (!cards) {
      await getCardsInfo();
    }
    if (graphicsSib.length === 0) {
      getGeneralChartsData();
    } else {
      configurations(graphicsSib);
      configurationsTables(graphicsSib);
    }
  };

  const getCardsInfo = async () => {
    setCharging(true);
    let response = await getCards(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCards(data);
      setCharging(false);
    }
  };

  const getGeneralChartsData = async () => {
    setCharging(true);
    let response = await getGraphicsGeneralSib(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las gráficas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setGraphicsSib(data);
      setCharging(false);
      configurations(data);
      configurationsTables(data);
    }
  };

  const configurations = (info) => {
    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });
    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    
    /**
     * Grafica top 10 organizaciones (BAR CHART)
    */
   
    let chart1 = [];
    let chartTop10 = info.find(chart => chart.nombre === 'publishingOrganizationTitle_barra');
    for (let index = 0; index < chartTop10.valores.X.length + 1; index++) {
      let ch = index === 0 ? ['amount', 'product'] : [chartTop10.valores.Y[index - 1], chartTop10.valores.X[index - 1]]
      chart1.push(ch);      
    }
    let chartSibColombiaOne = echarts.init(document.getElementById('chart-sib-colombia-one-'));
    let optionSibColombiaOne;

    optionSibColombiaOne = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Top 10 organizaciones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: Math.min(...chartTop10.valores.Y),
        max: Math.max(...chartTop10.valores.Y),
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      dataset: {
        source: chart1        
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Organización',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: chartTop10.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaOne && chartSibColombiaOne.setOption(optionSibColombiaOne);

    $(window).on('resize', function(){
      if(chartSibColombiaOne != null && chartSibColombiaOne !== undefined){
        chartSibColombiaOne.resize();
      }
    });

    /**
     * Grafica lenguaje usado en la publicación de los datos (PIE CHART)
     */

    let languagePastel = info.find(chart => chart.nombre === 'language_pastel');
    let chartSibColombiaTwo = echarts.init(document.getElementById('chart-sib-colombia-two-'));
    let optionSibColombiaTwo;

    optionSibColombiaTwo = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Lenguaje usado en la publicación de los datos',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: 0,
        max: 1,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: languagePastel.valores,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaTwo && chartSibColombiaTwo.setOption(optionSibColombiaTwo);

    $(window).on('resize', function(){
      if(chartSibColombiaTwo != null && chartSibColombiaTwo !== undefined){
        chartSibColombiaTwo.resize();
      }
    });

    /**
    * Grafica tipo de conjunto de datos (PIE CHART)
    */

    let typePastel = info.find(chart => chart.nombre === 'type_pastel');
    let chartSibColombiaThree = echarts.init(document.getElementById('chart-sib-colombia-three-'));
    let optionSibColombiaThree;

    optionSibColombiaThree = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Tipo de conjunto de datos',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: 0,
        max: 1,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: typePastel.valores,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaThree && chartSibColombiaThree.setOption(optionSibColombiaThree);

    $(window).on('resize', function(){
      if(chartSibColombiaThree != null && chartSibColombiaThree !== undefined){
        chartSibColombiaThree.resize();
      }
    });

    /**
     * Grafica Publicaciones por año (LINE CHART)
     */

    let createdYear = info.find(chart => chart.nombre === 'created_linea');
    let chartSibColombiaFour = echarts.init(document.getElementById('chart-sib-colombia-four-'));
    let optionSibColombiaFour;

    optionSibColombiaFour = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Publicaciones por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: createdYear.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'Cantidad',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: createdYear.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaFour && chartSibColombiaFour.setOption(optionSibColombiaFour);

    $(window).on('resize', function(){
      if(chartSibColombiaFour != null && chartSibColombiaFour !== undefined){
        chartSibColombiaFour.resize();
      }
    });

    /**
     * Grafica Publicaciones por año (LINE CHART)
     */

    let chartPercentage = info.find(chart => chart.nombre === 'created_linea_tasa_cambio');
    let chartSibColombiaFive = echarts.init(document.getElementById('chart-sib-colombia-five-'));
    let optionSibColombiaFive;

    optionSibColombiaFive = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Publicaciones por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartPercentage.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartPercentage.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaFive && chartSibColombiaFive.setOption(optionSibColombiaFive);

    $(window).on('resize', function(){
      if(chartSibColombiaFive != null && chartSibColombiaFive !== undefined){
        chartSibColombiaFive.resize();
      }
    });

    /**
     * Grafica hosting organización (BAR CHART)
     */

    let chartHosting = info.find(chart => chart.nombre === 'hostingOrganizationTitle_barra');
    let chartSibColombiaSix = echarts.init(document.getElementById('chart-sib-colombia-six-'));
    let optionSibColombiaSix;

    optionSibColombiaSix = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Hosting organización',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: -5,
        min: Math.min(... chartHosting.valores.Y),
        max: Math.max(... chartHosting.valores.Y),
        text: ['', ''],
        dimension: 0,
        inRange: {
            color: ['#9AC331', '#FFDA00', '#EF2469']
        },
        textStyle: {
          color: '#212121',
          fontWeight: 'normal',
          fontFamily: 'Monserat-Light'
        }
      },
      dataset: {
        source: [
          ['amount', 'product'],
          ... chartHosting.valores.X.map((graph, index) => [chartHosting.valores.Y[index], graph])
        ]
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'value',
        name: 'Frecuencia',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'category',
        name: 'Hosting Organization',
        nameLocation: 'middle',
        nameGap: 110,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
                newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        data: chartHosting.valores.X
      },
      series: [
        {
          type: 'bar',
          stack: 'chart',
          z: 3,
          label: {
            position: 'right',
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          emphasis: {
            show: false,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          encode: {
              x: 'amount',
              y: 'product'
          },
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaSix && chartSibColombiaSix.setOption(optionSibColombiaSix);

    $(window).on('resize', function(){
      if(chartSibColombiaSix != null && chartSibColombiaSix !== undefined){
        chartSibColombiaSix.resize();
      }
    });

    /**
     * Grafica relación entre organizaciones (GRAPH CHART)
     */

    /*let graphOrganizations = info.find(chart => chart.nombre === 'organization_grafo');
    let chartSibColombiaTen = echarts.init(document.getElementById('chart-sib-colombia-ten-'));
    let optionSibColombiaTen;

    optionSibColombiaTen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      series: [{
        type: 'graph',
        layout: 'force',
        label: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular',
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        width: '100%',
        height: '100%',
        force: {
          edgeLength: 50,
          repulsion: 110,
          gravity: 0.1
        },
        data: graphOrganizations.valores.nodos.map((chart, index) => {
          return {
            id: index,
            name: chart,
            symbolSize: 14,
            value: 1,
            category: 0
          }
        }),
        links: graphOrganizations.valores.conexiones.map(chart => {
          return {
            source: graphOrganizations.valores.nodos.findIndex(graph => graph === chart[0]),
            target: graphOrganizations.valores.nodos.findIndex(graph => graph === chart[1]),
            lineStyle: {
                width: 8,
                opacity: 1,
                cap: "round",
                join: "round",
                color: "rgba(162, 235, 27, 0.90)",
                shadowColor: "rgba(162, 235, 27, 0.90)",
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
            }
          }
        })
        categories: [
          {
            name: 'Organización 1',
            itemStyle: {
              color: '#FAFAFA'
            }
          },
          {
            name: 'Organización 2',
            itemStyle: {
              color: '#728998'
            }
          }
        ],
        draggable: true,
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 8
          },
          label: {
            position: 'right',
            show: true
          }
        },
        roam: true,
        labelLayout: {
          hideOverlap: true
        },
        scaleLimit: {
          min: 1,
          max: 5
        },
        lineStyle: {
          color: 'source',
          curveness: 0.4,
          width: 3,
          opacity: 1
        }
      }],
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      animationEasing: 'elasticOut',
    }

    optionSibColombiaTen && chartSibColombiaTen.setOption(optionSibColombiaTen);

    $(window).on('resize', function(){
      if(chartSibColombiaTen != null && chartSibColombiaTen !== undefined){
        chartSibColombiaTen.resize();
      }
    });*/

    /**
     * Grafica número de conexiones por año (LINE CHART)
     */

    let chartConnections = info.find(chart => chart.nombre === 'organization_tamanioGrafo_linea');
    let chartSibColombiaEleven = echarts.init(document.getElementById('chart-sib-colombia-eleven-'));
    let optionSibColombiaEleven;

    optionSibColombiaEleven = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de conexiones por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartConnections.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaEleven && chartSibColombiaEleven.setOption(optionSibColombiaEleven);

    $(window).on('resize', function(){
      if(chartSibColombiaEleven != null && chartSibColombiaEleven !== undefined){
        chartSibColombiaEleven.resize();
      }
    });

    /**
     * Grafica conexiones nuevas por año (LINE CHART)
     */

    let chartNewConnections = info.find(chart => chart.nombre === 'organization_ConexionesNuevas_linea');
    let chartSibColombiaTwelve = echarts.init(document.getElementById('chart-sib-colombia-twelve-'));
    let optionSibColombiaTwelve;

    optionSibColombiaTwelve = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'conexiones nuevas por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartNewConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartNewConnections.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaTwelve && chartSibColombiaTwelve.setOption(optionSibColombiaTwelve);

    $(window).on('resize', function(){
      if(chartSibColombiaTwelve != null && chartSibColombiaTwelve !== undefined){
        chartSibColombiaTwelve.resize();
      }
    });

    /**
     * Grafica número de nodos vs número de conexiones (LINE STACKED CHART)
     */

    let chartVsConnections = info.find(chart => chart.nombre === 'organization_ConexionesVsNodos_linea_stack');
    let chartDataMincienciasThirteen = echarts.init(document.getElementById('chart-sib-colombia-thirteen-'));
    let optionDataMincienciasThirteen;

    optionDataMincienciasThirteen = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Número de nodos', 'Número de conexiones']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de nodos vs número de conexiones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartVsConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Número de nodos',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#EA3A3D",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#EA3A3D'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#EA3A3D",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartVsConnections.valores.Y[0],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
        {
          name: 'Número de conexiones',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartVsConnections.valores.Y[1],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionDataMincienciasThirteen && chartDataMincienciasThirteen.setOption(optionDataMincienciasThirteen);

    $(window).on('resize', function(){
      if(chartDataMincienciasThirteen != null && chartDataMincienciasThirteen !== undefined){
        chartDataMincienciasThirteen.resize();
      }
    });

    /**
     * Grafica número de nodos vs número de conexiones (LINE STACKED CHART)
     */

    let chartDataMincienciasFourteen = echarts.init(document.getElementById('chart-sib-colombia-fourteen-'));
    let optionDataMincienciasFourteen;

    optionDataMincienciasFourteen = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Número de nodos', 'Número de conexiones']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de nodos vs número de conexiones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Número de nodos',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#EA3A3D",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#EA3A3D'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#EA3A3D",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: [20, 40, 60, 80, 100, 110, 200, 214, 90, 50, 250],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
        {
          name: 'Número de conexiones',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: [20, 40, 60, 80, 100, 290, 160, 80, 95, 189, 270],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionDataMincienciasFourteen && chartDataMincienciasFourteen.setOption(optionDataMincienciasFourteen);

    $(window).on('resize', function(){
      if(chartDataMincienciasFourteen != null && chartDataMincienciasFourteen !== undefined){
        chartDataMincienciasFourteen.resize();
      }
    });

    /**
     * Grafica relaciones entre ciudades (GRAPH CHART)
     */

    /*let chartDataMincienciasFifteen = echarts.init(document.getElementById('chart-sib-colombia-fifteen-'));
    let optionSibColombiaFifteen;

    optionSibColombiaFifteen = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      series: [{
        type: 'graph',
        layout: 'force',
        label: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular',
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        width: '100%',
        height: '100%',
        force: {
          edgeLength: 50,
          repulsion: 110,
          gravity: 0.1
        },
        data: [
          {
            id: '0',
            name: 'Mun 1',
            symbolSize: 14,
            value: 1,
            category: 0
          },
          {
            id: '1',
            name: 'Mun 2',
            symbolSize: 14,
            value: 1,
            category: 0
          },
          {
            id: '3',
            name: 'Mun 3',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '4',
            name: 'Mun 4',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '5',
            name: 'Mun 5',
            symbolSize: 14,
            value: 1,
            category: 0
          },
          {
            id: '6',
            name: 'Mun 6',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '7',
            name: 'Mun 7',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '8',
            name: 'Mun 8',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '9',
            name: 'Mun 9',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '10',
            name: 'Mun 10',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '11',
            name: 'Mun 11',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '12',
            name: 'Mun 12',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '13',
            name: 'Mun 13',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '14',
            name: 'Mun 14',
            symbolSize: 14,
            value: 1,
            category: 1
          },
          {
            id: '15',
            name: 'Mun 15',
            symbolSize: 14,
            value: 1,
            category: 1
          },
          {
            id: '16',
            name: 'Mun 16',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '17',
            name: 'Mun 17',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '18',
            name: 'Mun 18',
            symbolSize: 14,
            value: 1,
            category: 1
          },
          {
            id: '19',
            name: 'Mun 19',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '20',
            name: 'Mun 20',
            symbolSize: 14,
            value: 1,
            category: 1
          }
        ],
        links: [
          {
            source: '0',
            target: '11',
            lineStyle: {
                width: 8,
                opacity: 1,
                cap: "round",
                join: "round",
                color: "rgba(162, 235, 27, 0.90)",
                shadowColor: "rgba(162, 235, 27, 0.90)",
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
            }
          }
        ],
        categories: [
          {
            name: 'Ciudad 1',
            itemStyle: {
              color: '#FAFAFA'
            }
          },
          {
            name: 'Ciudad 2',
            itemStyle: {
              color: '#728998'
            }
          }
        ],
        draggable: true,
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 8
          },
          label: {
            position: 'right',
            show: true
          }
        },
        roam: true,
        labelLayout: {
          hideOverlap: true
        },
        scaleLimit: {
          min: 1,
          max: 5
        },
        lineStyle: {
          color: 'source',
          curveness: 0.4,
          width: 3,
          opacity: 1
        }
      }],
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      animationEasing: 'elasticOut',
    }

    optionSibColombiaFifteen && chartDataMincienciasFifteen.setOption(optionSibColombiaFifteen);

    $(window).on('resize', function(){
      if(chartDataMincienciasFifteen != null && chartDataMincienciasFifteen !== undefined){
        chartDataMincienciasFifteen.resize();
      }
    });*/

    /**
     * Grafica número de conexiones por año (LINE CHART)
     */

    let chartCity = info.find(chart => chart.nombre === 'city_tamanioGrafo_linea');
    let chartSibColombiaSixteen = echarts.init(document.getElementById('chart-sib-colombia-sixteen-'));
    let optionSibColombiaSixteen;

    optionSibColombiaSixteen = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de conexiones por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartCity.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCity.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaSixteen && chartSibColombiaSixteen.setOption(optionSibColombiaSixteen);

    $(window).on('resize', function(){
      if(chartSibColombiaSixteen != null && chartSibColombiaSixteen !== undefined){
        chartSibColombiaSixteen.resize();
      }
    });

    /**
      * Grafica conexiones nuevas por año (LINE CHART)
      */

    let chartCityConnections = info.find(chart => chart.nombre === 'city_ConexionesNuevas_linea');
    let chartSibColombiaSeventeen = echarts.init(document.getElementById('chart-sib-colombia-seventeen-'));
    let optionSibColombiaSeventeen;

    optionSibColombiaSeventeen = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Conexiones nuevas por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartCityConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
              label: {
                  show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCityConnections.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaSeventeen && chartSibColombiaSeventeen.setOption(optionSibColombiaSeventeen);

    $(window).on('resize', function(){
      if(chartSibColombiaSeventeen != null && chartSibColombiaSeventeen !== undefined){
        chartSibColombiaSeventeen.resize();
      }
    });

    /**
     * Grafica número de nodos vs número de conexiones (LINE STACKED CHART)
     */

    let chartCityVsConnections = info.find(chart => chart.nombre === 'city_ConexionesVsNodos_linea_stack');
    let chartDataMincienciasEighteen = echarts.init(document.getElementById('chart-sib-colombia-eighteen-'));
    let optionDataMincienciasEighteen;

    optionDataMincienciasEighteen = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Número de nodos', 'Número de conexiones']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de nodos vs número de conexiones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartCityVsConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Número de nodos',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#EA3A3D",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#EA3A3D'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#EA3A3D",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCityVsConnections.valores.Y[0],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
        {
          name: 'Número de conexiones',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCityVsConnections.valores.Y[1],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionDataMincienciasEighteen && chartDataMincienciasEighteen.setOption(optionDataMincienciasEighteen);

    $(window).on('resize', function(){
      if(chartDataMincienciasEighteen != null && chartDataMincienciasEighteen !== undefined){
        chartDataMincienciasEighteen.resize();
      }
    });

    /**
    * Grafica número de nodos vs número de conexiones (LINE STACKED CHART)
    */

    let chartDataMincienciasNineteen = echarts.init(document.getElementById('chart-sib-colombia-nineteen-'));
    let optionDataMincienciasNineteen;

    optionDataMincienciasNineteen = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Número de nodos', 'Número de conexiones']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de nodos vs número de conexiones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Número de nodos',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#EA3A3D",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#EA3A3D'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#EA3A3D",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: [20, 40, 60, 80, 100, 110, 200, 214, 90, 50, 250],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
        {
          name: 'Número de conexiones',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: [20, 40, 60, 80, 100, 290, 160, 80, 95, 189, 270],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionDataMincienciasNineteen && chartDataMincienciasNineteen.setOption(optionDataMincienciasNineteen);

    $(window).on('resize', function(){
      if(chartDataMincienciasNineteen != null && chartDataMincienciasNineteen !== undefined){
        chartDataMincienciasNineteen.resize();
      }
    });

    /**
     * Grafica relaciones entre paises (GRAPH CHART)
     */

    /*let chartDataMincienciasTwenty = echarts.init(document.getElementById('chart-sib-colombia-twenty-'));
    let optionSibColombiaTwenty;
    
    chartDataMincienciasTwenty.hideLoading();
    optionSibColombiaTwenty = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      series: [{
        type: 'graph',
        layout: 'force',
        label: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular',
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        width: '100%',
        height: '100%',
        force: {
          edgeLength: 50,
          repulsion: 110,
          gravity: 0.1
        },
        data: [
          {
            id: '0',
            name: 'pai 1',
            symbolSize: 14,
            value: 1,
            category: 0
          },
          {
            id: '1',
            name: 'pai 2',
            symbolSize: 14,
            value: 1,
            category: 0
          },
          {
            id: '3',
            name: 'pai 3',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '4',
            name: 'pai 4',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '5',
            name: 'pai 5',
            symbolSize: 14,
            value: 1,
            category: 0
          },
          {
            id: '6',
            name: 'pai 6',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '7',
            name: 'pai 7',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '8',
            name: 'pai 8',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '9',
            name: 'pai 9',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '10',
            name: 'pai 10',
            symbolSize: 14,
            value: 2,
            category: 0
          },
          {
            id: '11',
            name: 'pai 11',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '12',
            name: 'pai 12',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '13',
            name: 'pai 13',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '14',
            name: 'pai 14',
            symbolSize: 14,
            value: 1,
            category: 1
          },
          {
            id: '15',
            name: 'pai 15',
            symbolSize: 14,
            value: 1,
            category: 1
          },
          {
            id: '16',
            name: 'pai 16',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '17',
            name: 'pai 17',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '18',
            name: 'pai 18',
            symbolSize: 14,
            value: 1,
            category: 1
          },
          {
            id: '19',
            name: 'pai 19',
            symbolSize: 14,
            value: 2,
            category: 1
          },
          {
            id: '20',
            name: 'pai 20',
            symbolSize: 14,
            value: 1,
            category: 1
          }
        ],
        links: [
          {
            source: '0',
            target: '11',
            lineStyle: {
                width: 8,
                opacity: 1,
                cap: "round",
                join: "round",
                color: "rgba(162, 235, 27, 0.90)",
                shadowColor: "rgba(162, 235, 27, 0.90)",
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
            }
          }
        ],
        categories: [
          {
            name: 'Pais 1',
            itemStyle: {
              color: '#FAFAFA'
            }
          },
          {
            name: 'Pais 2',
            itemStyle: {
              color: '#728998'
            }
          }
        ],
        draggable: true,
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 8
          },
          label: {
            position: 'right',
            show: true
          }
        },
        roam: true,
        labelLayout: {
          hideOverlap: true
        },
        scaleLimit: {
          min: 1,
          max: 5
        },
        lineStyle: {
          color: 'source',
          curveness: 0.4,
          width: 3,
          opacity: 1
        }
      }],
      animationDuration: 1500,
      animationEasingUpdate: 'quinticInOut',
      animationEasing: 'elasticOut',
    }

    optionSibColombiaTwenty && chartDataMincienciasTwenty.setOption(optionSibColombiaTwenty);

    $(window).on('resize', function(){
      if(chartDataMincienciasTwenty != null && chartDataMincienciasTwenty !== undefined){
        chartDataMincienciasTwenty.resize();
      }
    });*/

    /**
     * Grafica número de conexiones por año (LINE CHART)
     */

    let chartCountry = info.find(chart => chart.nombre === 'country_tamanioGrafo_linea');
    let chartSibColombiaTwentyOne = echarts.init(document.getElementById('chart-sib-colombia-twentyone-'));
    let optionSibColombiaTwentyOne;

    optionSibColombiaTwentyOne = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de conexiones por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartCountry.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
              label: {
                  show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCountry.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaTwentyOne && chartSibColombiaTwentyOne.setOption(optionSibColombiaTwentyOne);

    $(window).on('resize', function(){
      if(chartSibColombiaTwentyOne != null && chartSibColombiaTwentyOne !== undefined){
        chartSibColombiaTwentyOne.resize();
      }
    });

    /**
     * Grafica conexiones nuevas por año (LINE CHART)
     */

    let chartCountryConnections = info.find(chart => chart.nombre === 'country_ConexionesNuevas_linea');
    let chartSibColombiaTwentyTwo = echarts.init(document.getElementById('chart-sib-colombia-twentytwo-'));
    let optionSibColombiaTwentyTwo;

    optionSibColombiaTwentyTwo = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Conexiones nuevas por año',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartCountryConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
              show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCountryConnections.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSibColombiaTwentyTwo && chartSibColombiaTwentyTwo.setOption(optionSibColombiaTwentyTwo);

    $(window).on('resize', function(){
      if(chartSibColombiaTwentyTwo != null && chartSibColombiaTwentyTwo !== undefined){
        chartSibColombiaTwentyTwo.resize();
      }
    });

    /**
     * Grafica número de nodos vs número de conexiones (LINE STACKED CHART)
     */

    let chartCountryVsConnections = info.find(chart => chart.nombre === 'country_ConexionesVsNodos_linea_stack');
    let chartDataMincienciasTwentyThree = echarts.init(document.getElementById('chart-sib-colombia-twentythree-'));
    let optionDataMincienciasTwentyThree;

    optionDataMincienciasTwentyThree = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Número de nodos', 'Número de conexiones']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de nodos vs número de conexiones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: chartCountryVsConnections.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Número de nodos',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#EA3A3D",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#EA3A3D'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#EA3A3D",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCountryVsConnections.valores.Y[0],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
        {
          name: 'Número de conexiones',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: chartCountryVsConnections.valores.Y[1],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionDataMincienciasTwentyThree && chartDataMincienciasTwentyThree.setOption(optionDataMincienciasTwentyThree);

    $(window).on('resize', function(){
      if(chartDataMincienciasTwentyThree != null && chartDataMincienciasTwentyThree !== undefined){
        chartDataMincienciasTwentyThree.resize();
      }
    });

    /**
   * Grafica número de nodos vs número de conexiones (LINE STACKED CHART)
   */

    let chartDataMincienciasTwentyFour = echarts.init(document.getElementById('chart-sib-colombia-twentyfour-'));
    let optionDataMincienciasTwentyFour;

    optionDataMincienciasTwentyFour = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Número de nodos', 'Número de conexiones']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Número de nodos vs número de conexiones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        name: 'x',
        nameLocation: 'middle',
        nameGap: 40,
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: ['2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022']
      },
      yAxis: {
        type: 'value',
        name: 'y',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Número de nodos',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#EA3A3D",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#EA3A3D'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#EA3A3D",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8,
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: [20, 40, 60, 80, 100, 110, 200, 214, 90, 50, 250],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
        {
          name: 'Número de conexiones',
          type: 'line',
          stack: 'Total',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          emphasis: {
            focus: 'series',
            label: {
                show: true
              }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: [20, 40, 60, 80, 100, 290, 160, 80, 95, 189, 270],
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionDataMincienciasTwentyFour && chartDataMincienciasTwentyFour.setOption(optionDataMincienciasTwentyFour);

    $(window).on('resize', function(){
      if(chartDataMincienciasTwentyFour != null && chartDataMincienciasTwentyFour !== undefined){
        chartDataMincienciasTwentyFour.resize();
      }
    });
  };

  const configurationsTables = (info) => {
    setTableTopOrganizations(info.find(table => table.nombre === 'top10Organizaciones_tabla'));
    setTableTopCities(info.find(table => table.nombre === 'top10Ciudades_tabla'));
    setTableTopCountries(info.find(table => table.nombre === 'top10Paises_tabla'));
  };

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='row'>
        <div className='col-12'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-sibcolombia-' src={IconSibcolombia} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  SIB Colombia
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                  En esta sección se brinda acceso abierto a información sobre la diversidad biológica del país para la construcción de una sociedad sostenible. Además, facilita la publicación en línea de datos e información sobre biodiversidad, y promueve su uso por parte de una amplia variedad de audiencias, apoyando de forma oportuna y eficiente la gestión integral de la biodiversidad.
                </p>
              </div>
            </div>
            <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
              <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                <div className='col-12 col-sm-7 col-md-8 col-lg-8 col-xl-7 col-xxl-5'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Articulos - capitulos - libros</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        # <span>{ cards?.articulos }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Instituciones</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        + <span>{ cards?.instituciones }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row row-cols-auto g-4 mt-2 mb-2'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>
            Análisis exploratorio de datos
          </h1>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-white-'>Top 10 organizaciones que más publican datos</h1>
          <p className='m-0 mt-1 p-0 lh-sm fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>En esta sección se presenta un gráfico con el top 10 de las organizaciones que más publican conjuntos de datos en el SiB</p>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Top 10 organizaciones
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-one-'></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Lenguaje usado en la publicación de los datos
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>En esta sección se presenta un gráfico con el porcentaje de uso del lengua en el que se publicaron los datos</p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-two-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Tipo de conjunto de datos
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>En esta sección se presenta un gráfico con el porcentaje de tipos de datos almacenados en la base de datos del SiB</p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-three-'></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Cantidad de datos publicados por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>En esta sección se presenta un gráfico con la cantidad de conjuntos de datos publicados cada año</p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-four-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Porcentaje de cambio en cantidad de publicaciones por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-five-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Organización anfitriona</h1>
          <p className='m-0 mt-1 p-0 lh-sm fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>En esta sección se presenta un gráfico con la cantidad de conjuntos de datos almacenados en cada organización anfitriona</p>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Hosting organization
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            {/** */}
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-six-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-white-'>Relación entre organizaciones y cantidad de conexiones por año</h1>
          <p className='m-0 mt-1 p-0 lh-sm fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>En esta sección se presenta un gráfico de cantidad de conexiones por año, un gráfico de cantidad de conexiones nuevas cada año y una tabla de las organizaciones con la mayor cantidad de relaciones</p>
        </div>
        {/* 
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
              <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                  Relaciones entre organizaciones
                </h1>
                <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
              </div>            
              <div className='card-body p-0 ps-2 pe-2 w-100'>
                <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-ten-'></div>
              </div>          
            </div>
          </div>
        */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de conexiones por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-eleven-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Conexiones nuevas por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-twelve-'></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de nodos vs Número de conexiones
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-thirteen-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de nodos vs Número de conexiones normalizado
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Área de conocimiento</p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-fourteen-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                <div className='col d-flex flex-column'>
                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                      Top 10 tabla de organizaciones
                    </h1>
                  </div>
                  <div className='w-auto mt-3 '>
                    <form action="" className='position-relative wrapper-search-small- me-3'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchOrganizations} onChange={({ target }) => setSearchOrganizations(target.value)} />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                        <div className='card-header border-0 bg-transparent pb-0'>
                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                        </div>
                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                            # <span>{ tableTopOrganizations?.length }</span>
                          </p>
                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                            Registros
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-0 w-100 wrapper-data-'>
              <div className='table-responsive table-general-'>
                <table className='table table-sm table-striped table-no-border- align-middle'>
                  <thead>
                    <tr>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Organización</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Número de conexiones</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableTopOrganizations?.valores.X.Organizacion.map((row, index) => {
                        if (searchOrganizations !== '') {
                          let flag = false;
                          let word1 = '' + row;
                          let word2 = '' + tableTopOrganizations.valores.X.Numero_Conexiones[index];
                          let words = word1.split(' ');
                          let j = 0;
                          flag = word1.toUpperCase().includes(searchOrganizations.toUpperCase());
                          while (j < words.length && !flag) {
                            if (words[j].toUpperCase().includes(searchOrganizations.toUpperCase())) {
                              flag = true;
                            }
                            j++;
                          }
                          if (searchOrganizations.toUpperCase() === word1.substring(0, searchOrganizations.length).toUpperCase() || word2.toUpperCase().includes(searchOrganizations.toUpperCase()) || searchOrganizations.toUpperCase() === word2.substring(0, searchOrganizations.length).toUpperCase()) {
                            flag = true;
                          }
                          if (flag) {
                            return {
                              name: row,
                              connections: tableTopOrganizations.valores.X.Numero_Conexiones[index]
                            };
                          }                        
                        } else {
                          return {
                            name: row,
                            connections: tableTopOrganizations.valores.X.Numero_Conexiones[index]
                          };;
                        }
                      }).filter(item => item !== undefined).map(row => (
                        <tr>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.name }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.connections }</p>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer border-0 bg-transparent pb-3 pt-3">
              {/* 
              <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                <div className='col-auto'>
                  <nav>
                    <ul className="pagination justify-content-center">
                      {startPage !== 1 && (
                        <li className="page-item">
                          <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                        </li>
                      )}
                      {startPage > 2 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                        <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                        </li>
                      ))}
                      {endPage < totalPages - 1 && (
                        <li className="page-item disabled">
                          <span className="page-link">...</span>
                        </li>
                      )}
                      {endPage !== totalPages && (
                        <li className="page-item">
                          <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
              */}
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Análisis de relaciones ciudades y cantidad de conexiones por año</h1>
          <p className='m-0 mt-1 p-0 lh-sm fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>En esta sección se presenta un gráfico de cantidad de conexiones por año, un gráfico de cantidad de conexiones nuevas cada año y una tabla de las ciudades con la mayor cantidad de relaciones</p>
        </div>
        {/*
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
              <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                  Relaciones entre ciudades
                </h1>
                <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
              </div>
              <div className='card-body p-0 ps-2 pe-2 w-100'>
                <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-fifteen-'></div>
              </div>
            </div>
          </div>
        */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de conexiones por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-sixteen-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Conexiones nuevas por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-seventeen-'></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de nodos vs Número de conexiones
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-eighteen-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de nodos vs Número de conexiones normalizado
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Área de conocimiento</p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-nineteen-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                <div className='col d-flex flex-column'>
                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                      Top 10 tabla de ciudades
                    </h1>
                  </div>
                  <div className='w-auto mt-3 '>
                    <form action="" className='position-relative wrapper-search-small- me-3'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchCities} onChange={({ target }) => setSearchCities(target.value)} />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                        <div className='card-header border-0 bg-transparent pb-0'>
                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                        </div>
                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                            # <span>{ tableTopCities?.length }</span>
                          </p>
                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                            Registros
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-0 w-100 wrapper-data-'>
              <div className='table-responsive table-general-'>
                <table className='table table-sm table-striped table-no-border- align-middle'>
                  <thead>
                    <tr>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Ciudad</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Número de conexiones</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableTopCities?.valores.X.Organizacion.map((row, index) => {
                        if (searchCities !== '') {
                          let flag = false;
                          let word1 = '' + row;
                          let word2 = '' + tableTopCities.valores.X.Numero_Conexiones[index];
                          let words = word1.split(' ');
                          let j = 0;
                          flag = word1.toUpperCase().includes(searchCities.toUpperCase());
                          while (j < words.length && !flag) {
                            if (words[j].toUpperCase().includes(searchCities.toUpperCase())) {
                              flag = true;
                            }
                            j++;
                          }
                          if (searchCities.toUpperCase() === word1.substring(0, searchCities.length).toUpperCase() || word2.toUpperCase().includes(searchCities.toUpperCase()) || searchCities.toUpperCase() === word2.substring(0, searchCities.length).toUpperCase()) {
                            flag = true;
                          }
                          if (flag) {
                            return {
                              name: row,
                              connections: tableTopCities.valores.X.Numero_Conexiones[index]
                            };
                          }                        
                        } else {
                          return {
                            name: row,
                            connections: tableTopCities.valores.X.Numero_Conexiones[index]
                          };;
                        }
                      }).filter(item => item !== undefined).map(row => (
                        <tr>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.name }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.connections }</p>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer border-0 bg-transparent pb-3 pt-3">
              {/* 
                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                  <div className='col-auto'>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {startPage !== 1 && (
                          <li className="page-item">
                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                          </li>
                        )}
                        {startPage > 2 && (
                          <li className="page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                          </li>
                        ))}
                        {endPage < totalPages - 1 && (
                          <li className="page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        {endPage !== totalPages && (
                          <li className="page-item">
                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              */}
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <h1 className='m-0 p-0 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Análisis de relaciones países y cantidad de conexiones por año</h1>
          <p className='m-0 mt-1 p-0 lh-sm fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>En esta sección se presenta un gráfico de cantidad de conexiones por año, un gráfico de cantidad de conexiones nuevas cada año y una tabla de las instituciones con la mayor cantidad de relaciones</p>
        </div>
        {/*
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
              <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                  Relaciones entre países
                </h1>
                <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
              </div>
              <div className='card-body p-0 ps-2 pe-2 w-100'>
                <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-twenty-'></div>
              </div>
            </div>
          </div>
        */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de conexiones por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-twentyone-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Conexiones nuevas por año
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-twentytwo-'></div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de nodos vs Número de conexiones
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-twentythree-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6'>
          <div id='card-scientometric-small-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                Número de nodos vs Número de conexiones normalizado
              </h1>
              <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'></p>
            </div>
            <div className='card-body p-0 ps-2 pe-2 w-100'>
              <div className='w-100 h-100 mx-auto' id='chart-sib-colombia-twentyfour-'></div>
            </div>
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
          <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
            <div className='card-header border-0 bg-transparent pb-4 pt-4'>
              <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                <div className='col d-flex flex-column'>
                  <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                    <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                      Top 10 tabla de países
                    </h1>
                  </div>
                  <div className='w-auto mt-3 '>
                    <form action="" className='position-relative wrapper-search-small- me-3'>
                      <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                        <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={searchCountries} onChange={({ target }) => setSearchCountries(target.value)} />
                        <img src={IconSearch} alt="" />
                      </div>
                    </form>
                  </div>
                </div>
                <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                  <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                      <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                        <div className='card-header border-0 bg-transparent pb-0'>
                          <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                        </div>
                        <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                          <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                            # <span>{ tableTopCountries?.length }</span>
                          </p>
                          <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                            Registros
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body p-0 w-100 wrapper-data-'>
              <div className='table-responsive table-general-'>
                <table className='table table-sm table-striped table-no-border- align-middle'>
                  <thead>
                    <tr>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Pais</span>
                        </div>
                      </th>
                      <th scope="col" className='th-width-auto-'>
                        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                          <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Número de conexiones</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableTopCountries?.valores.X.Organizacion.map((row, index) => {
                        if (searchCountries !== '') {
                          let flag = false;
                          let word1 = '' + row;
                          let word2 = '' + tableTopCountries.valores.X.Numero_Conexiones[index];
                          let words = word1.split(' ');
                          let j = 0;
                          flag = word1.toUpperCase().includes(searchCountries.toUpperCase());
                          while (j < words.length && !flag) {
                            if (words[j].toUpperCase().includes(searchCountries.toUpperCase())) {
                              flag = true;
                            }
                            j++;
                          }
                          if (searchCountries.toUpperCase() === word1.substring(0, searchCountries.length).toUpperCase() || word2.toUpperCase().includes(searchCountries.toUpperCase()) || searchCountries.toUpperCase() === word2.substring(0, searchCountries.length).toUpperCase()) {
                            flag = true;
                          }
                          if (flag) {
                            return {
                              name: row,
                              connections: tableTopCountries.valores.X.Numero_Conexiones[index]
                            };
                          }                        
                        } else {
                          return {
                            name: row,
                            connections: tableTopCountries.valores.X.Numero_Conexiones[index]
                          };;
                        }
                      }).filter(item => item !== undefined).map(row => (
                        <tr>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.name }</p>
                          </td>
                          <td className='align-middle'>
                            <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.connections }</p>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer border-0 bg-transparent pb-3 pt-3">
              {/* 
                <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                  <div className='col-auto'>
                    <nav>
                      <ul className="pagination justify-content-center">
                        {startPage !== 1 && (
                          <li className="page-item">
                            <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                          </li>
                        )}
                        {startPage > 2 && (
                          <li className="page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                          <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                            <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                          </li>
                        ))}
                        {endPage < totalPages - 1 && (
                          <li className="page-item disabled">
                            <span className="page-link">...</span>
                          </li>
                        )}
                        {endPage !== totalPages && (
                          <li className="page-item">
                            <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                          </li>
                        )}
                      </ul>
                    </nav>
                  </div>
                </div>
              */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
