import './App.css';
import { Index } from './app/routes';
import { AppProvider } from './appContext';

function App() {
  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;
