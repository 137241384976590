import IconSearchGlobal from '../../../../assets/images/icon-search-global.svg';
import IconSearch from '../../../../assets/images/icon-search.svg';
import React, { useContext, useEffect, useState } from 'react'
import * as echarts from 'echarts';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from "jquery"
import Preloader from '../../../shared/preloader/Preloader';
import { Appcontext } from '../../../../appContext';
import { getGraphicsGeneralSearch } from '../../../../services/dahsboard/scientometric/search/search';
import { getCards } from '../../../../services/dahsboard/scientometric/minciencias/minciencias';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
require('jquery-mousewheel');

export default function Search() {
  const { cards, setCards, token, setToken } = useContext(Appcontext);
  const navigate = useNavigate();
  const [charging, setCharging] = useState(false);
  const [search, setSearch] = useState('');
  const [chargeSearch, setChargeSearch] = useState(false);
  const [filterTablePublications, setFilterTablePublications] = useState('');
  const [filterTableInstitutions, setFilterTableInstitutions] = useState('');
  const [tablePublications, setTablePublications] = useState({});
  const [tableInstitutions, setTableInstitutions] = useState({});
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data && chargeSearch) {
      configurations(data);
    }
  }, [data, chargeSearch]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    if (!cards) {
      await getCardsInfo();
    }
  };

  const getCardsInfo = async () => {
    setCharging(true);
    let response = await getCards(token).catch(error => {
      setCharging(false);
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      } else {
        Swal.fire({
          text: 'No se pudo obtener información de las tarjetas',
          icon: 'error'
        });
      }
    });
    if (response) {
      let { data } = response;
      setCards(data);
      setCharging(false);
    }
  };

  const getGeneralChartsData = async () => {
    setFilterTableInstitutions('');
    setFilterTablePublications('');
    setChargeSearch(false);
    setCharging(true);
    let response = await getGraphicsGeneralSearch(search, token).catch(error => {
      if (error?.response.status === 401) {
        Swal.fire({
          text: 'Su sesión expiró. Le cerraremos la sesión',
          icon: 'info'
        });
        setToken(null);
        sessionStorage.setItem('token', '');  
        navigate('/auth');
      }
      setCharging(false);
    });
    if (response) {
      let { data } = response;
      setCharging(false);
      setChargeSearch(true);
      setTablePublications(data.find(table => table.nombre === 'tabla_lista_publicaciones'));
      setTableInstitutions(data.find(table => table.nombre === 'top_10_institucion'));
      setData(data);
    }
  };

  const configurations = (info) => {
    /**
     * Scrollbar en las tablas en el eje y , x
     */

    $('.wrapper-data-').mCustomScrollbar({
      theme: "minimal",
      axis:"y",
      mouseWheel:{
        scrollAmount: 60,
        normalizeDelta: true
      },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    $('.table-general-').mCustomScrollbar({
      theme: "minimal",
      axis:"x",
      mouseWheel:{ enable: false },
      scrollInertia:100,
      mouseWheelPixels: 100
    });

    /**
     * Grafica Evolución temporal de las publicaciones (LINE CHART)
     */

    let temporalChart = info.find(chart => chart.nombre === 'evolucion_temporal_search');

    let chartSearchOne = echarts.init(document.getElementById('chart-search-one-'));
    let optionSearchOne;

    optionSearchOne = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#FAFAFA',
            color: '#040E29',
            fontWeight: 'bold',
            fontFamily: 'Monserat-light'
          }
        },
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        },
        data: ['Ocultar']
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Evolución temporal de las publicaciones',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        name: 'Año',
        nameLocation: 'middle',
        nameGap: 40,
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        data: temporalChart.valores.X
      },
      yAxis: {
        type: 'value',
        name: 'Publicaciones',
        nameLocation: 'middle',
        nameGap: 60,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      grid: [
        {
          containLabel: true,
          borderColor: 'FAFAFA'
        }
      ],
      series: [
        {
          name: 'Ocultar',
          type: 'line',
          symbolSize: false,
          symbol: 'circle',
          smooth: true,
          lineStyle: {
            color: "#A2EB1B",
            width: 2,
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          itemStyle: {
            color: '#A2EB1B'
          },
          areaStyle: {
            opacity: 0.8,
            color: "#A2EB1B",
            shadowColor: 'rgba(0,0,0,0.2)',
            shadowBlur: 10,
            shadowOffsetY: 8
          },
          top: 15,
          emphasis: {
            label: {
                show: true
            }
          },
          label: {
            normal: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'bold',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: false,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          data: temporalChart.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSearchOne && chartSearchOne.setOption(optionSearchOne);

    $(window).on('resize', function(){
      if(chartSearchOne != null && chartSearchOne !== undefined){
        chartSearchOne.resize();
      }
    });

    /**
     * Grafica tipo de publicación (PIE CHART)
     */

    let publindex = info.find(chart => chart.nombre === 'tipo_publicacion');

    let chartSearchTwo = echarts.init(document.getElementById('chart-search-two-'));
    let optionSearchTwo;

    optionSearchTwo = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Tipo de la publicación',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: publindex.valores.length > 0 ? Math.min(... publindex.valores.map(row => row.value)) : 0,
        max: publindex.valores.length > 0 ? Math.max(... publindex.valores.map(row => row.value)) : 1,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: publindex.valores,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSearchTwo && chartSearchTwo.setOption(optionSearchTwo);

    $(window).on('resize', function(){
      if(chartSearchTwo != null && chartSearchTwo !== undefined){
        chartSearchTwo.resize();
      }
    });

    /**
     * Grafica paises por revistas (BAR CHART)
     */

    let scimago = info.find(chart => chart.nombre === 'scimago_pastel');

    let chartSearchThree = echarts.init(document.getElementById('chart-search-three-'));
    let optionSearchThree;

    optionSearchThree = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Tipo de la publicación',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: scimago.valores.length > 0 ? Math.min(... scimago.valores.map(row => row.value)) : 0,
        max: scimago.valores.length > 0 ? Math.max(... scimago.valores.map(row => row.value)) : 1,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: scimago.valores,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSearchThree && chartSearchThree.setOption(optionSearchThree);

    $(window).on('resize', function(){
      if(chartSearchThree != null && chartSearchThree !== undefined){
        chartSearchThree.resize();
      }
    });

    /**
     * Grafica Clasificación de los investigadores, cantidad de investigadores por clasificación (BAR CHART)
     */

    let researchClassification = info.find(chart => chart.nombre === 'clasificacion_investigadores_search');

    let chartSearchFour = echarts.init(document.getElementById('chart-search-four-'));
    let optionSearchFour;

    optionSearchFour = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataZoom: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          restore: {
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          },
          saveAsImage: {
            type: 'png',
            name: 'Clasificación de los investigadores',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        max: researchClassification.valores.Y.length > 0 ? Math.max(... researchClassification.valores.Y) : 1,
        min: researchClassification.valores.Y.length > 0 ? Math.min(... researchClassification.valores.Y) : 0,
        text: ['', ''],
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      grid: [
        {
          containLabel: true,
          right: '14%',
          borderColor: 'FAFAFA'
        }
      ],
      xAxis: {
        type: 'category',
        data: researchClassification.valores.X,
        name: 'Clasificación',
        nameLocation: 'middle',
        nameGap: 40,
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLabel: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      yAxis: {
        type: 'value',
        name: 'Cantidad',
        nameLocation: 'middle',
        nameGap: 60,
        axisLabel: {
          formatter : function(params, value){
            var newParamsName = "";
            var paramsNameNumber = params.length;
            var provideNumber = 12;
            var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
            if (paramsNameNumber > provideNumber) {
                for (var p = 0; p < rowNumber; p++) {
                  var tempStr = "";
                  if (p === rowNumber - 1) {
                      tempStr = (params.length > 6 ? (params.slice(0,12)+"...") : '' );
                  } else {}
                  newParamsName += tempStr;
                }
            } else {
              newParamsName = params;
            }
            return newParamsName
          },
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        nameTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular'
        },
        axisLine: {
          onZero: false,
          lineStyle: {
            color: 'FAFAFA',
            width: 1,
            cap: 'round'
          }
        },
      },
      series: [
        {
          type: 'bar',
          label: {
            normal: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              position: 'top',
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
          },
          data: researchClassification.valores.Y,
          animationDelay: function (idx) {
            return idx * 15;
          }
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSearchFour && chartSearchFour.setOption(optionSearchFour);

    $(window).on('resize', function(){
      if(chartSearchFour != null && chartSearchFour !== undefined){
        chartSearchFour.resize();
      }
    });

    /**
     * Grafica tipo de publicación (PIE CHART)
     */

    let researchTraining = info.find(chart => chart.nombre === 'formacion_investigador');

    let chartSearchFive = echarts.init(document.getElementById('chart-search-five-'));
    let optionSearchFive;

    optionSearchFive = {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        backgroundColor: 'rgba(250, 250, 250, 1)',
        borderWidth: 1,
        borderColor: '#FAFAFA',
        padding: 5,
        textStyle: {
          color: '#212121',
          fontSize: 13,
          lineHeight:10,
          fontWeight: 'bold',
          fontFamily: 'Monserat-light'
        },
        extraCssText: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;'
      },
      legend: {
        type: 'scroll',
        orient: 'horizontal',
        left: 'center',
        top: 'top',
        bottom: 20,
        itemGap : 25,
        width: '90%',
        inactiveColor: '#728998',
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        pageIconSize: 12,
        pageIconColor: '#C1F72B',
        pageIconInactiveColor: '#FAFAFA',
        pageTextStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-regular, Verdana'
        },
        formatter : function(params, value){
          var newParamsName = "";
          var paramsNameNumber = params.length;
          var provideNumber = 50;
          var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
          if (paramsNameNumber > provideNumber) {
              for (var p = 0; p < rowNumber; p++) {
                var tempStr = "";
                if (p === rowNumber - 1) {
                    tempStr = (params.length > 6 ? (params.slice(0,50)+"...") : '' );
                } else {}
                newParamsName += tempStr;
              }
          } else {
              newParamsName = params;
          }
          return newParamsName
        }
      },
      toolbox: {
        show: true,
        orient: 'horizontal',
        showTitle: false,
        feature: {
          dataView: {show: false, readOnly: false},
          restore: {show: false},
          saveAsImage: {
            type: 'png',
            name: 'Tipo de publicación',
            backgroundColor: '#040E29',
            show: true,
            iconStyle: {
              borderColor: '#FAFAFA'
            },
            emphasis: {
              iconStyle: {
                borderColor: '#FAFAFA'
              },
            }
          }
        },
        iconStyle: {
          borderColor: '#FAFAFA'
        },
        emphasis: {
          iconStyle: {
            borderColor: '#FAFAFA'
          },
        },
        bottom: 14,
        pixelRatio: 2,
      },
      visualMap: {
        top: 'middle',
        right: 0,
        min: researchTraining.valores.length > 0 ? Math.min(... researchTraining.valores.map(row => row.value)) : 0,
        max: researchTraining.valores.length > 0 ? Math.max(... researchTraining.valores.map(row => row.value)) : 1,
        text: ['', ''],
        dimension: 0,
        inRange: {
          color: ['#EF2469', '#FFDA00', '#9AC331']
        },
        textStyle: {
          color: '#FAFAFA',
          fontWeight: 'normal',
          fontFamily: 'Monserat-light'
        }
      },
      series: [
        {
          type: 'pie',
          radius: ['30%', '70%'],
          center: ['50%', '50%'],
          roseType: 'area',
          hoverOffset: 12,
          top: 15,
          label: {
            show: false,
            position: 'center',
            normal: {
              show: true,
              color: '#FAFAFA',
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          emphasis: {
            label: {
              show: true,
              color: '#FAFAFA',
              fontSize: 14,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light',
              formatter: "({d}%)",
            },
            show: true,
            color: '#FAFAFA',
            fontSize: 12,
            fontWeight: 'normal',
            fontFamily: 'Monserat-light'
          },
          lableLine: {
            normal: {
              show: false,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            },
            emphasis: {
              show: true,
              fontSize: 12,
              fontWeight: 'normal',
              fontFamily: 'Monserat-light'
            }
          },
          itemStyle: {
            borderRadius: 8,
            normal: {
              opacity: 1,
              shadowOffsetX: 0,
              shadowOffsetY: 0
            }
          },
          data: researchTraining.valores,
          animationDelay: function (idx) {
            return idx * 15;
          }
        }
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: function (idx) {
        return idx * 5;
      }
    };

    optionSearchFive && chartSearchFive.setOption(optionSearchFive);

    $(window).on('resize', function(){
      if(chartSearchFive != null && chartSearchFive !== undefined){
        chartSearchFive.resize();
      }
    });
  };

  const keyPressed = (e) => {
    if (e.key === 'Enter' && search.length >= 3) {
      e.preventDefault();
      getGeneralChartsData();
    }
  };

  /**
   * Scrollbar en las tablas en el eje y , x
   */

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const pagesToShow = 3;
  const startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
  const endPage = Math.min(startPage + pagesToShow - 1, totalPages);

  return (
    <React.Fragment>
      {
        charging
        ?
        <Preloader></Preloader>
        :
        null
      }
      <div className='row'>
        <div className='col-12'>
          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
            <div className='col d-flex flex-column'>
              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                <div className='me-3 p-2 rounded-circle d-flex flex-row justify-content-center align-items-center align-self-center bg-white- wrapper-icon-rounded-'>
                  <img className='icon-search-global-' src={IconSearchGlobal} alt="" />
                </div>
                <h1 className='m-0 p-0 lh-sm fs-0- ff-monse-extrabold- tx-white-'>
                  Buscador
                </h1>
              </div>
              <div className='w-100 mt-4'>
                <p className='m-0 p-0 lh-sm text-justify- fs-5- ff-monse-regular- fw-normal tx-bone-gray-'>
                En 3bio queremos integrar datos útiles para los análisis de biodiversidad en Colombia, es por esto que, en este buscador, se facilitará el acceso al área de cienciometría en la cual encontrarás una recopilación de estudios sobre organismos de interés, instituciones colombianas partícipes de éstos, publicaciones y datos geográficos descritos en estadísticos para una interpretación global de un organismo o tema en particular. Además de esta mirada general, tendrás la posibilidad de generar tus propios datos en el área de bioinformática y consultar resultados almacenados de otros estudios generados por investigadores del país.
                </p>
              </div>
            </div>
            <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
              <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                <div className='col-12 col-sm-7 col-md-8 col-lg-8 col-xl-7 col-xxl-5'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Articulos - capitulos - libros</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        # <span>{ cards?.articulos }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-sm-5 col-md-4 col-lg-4 col-xl-5 col-xxl-4'>
                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4-'>
                    <div className='card-header border-0 bg-transparent pb-0'>
                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Instituciones</h1>
                    </div>
                    <div className='card-body w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                        + <span>{ cards?.instituciones }</span>
                      </p>
                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                        Registros
                      </p>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-4'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7'>
          <div className='w-auto mt-3 '>
            <form action="" className='position-relative wrapper-search-small-' onSubmit={(e) => { e.preventDefault(); }} >
              <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={search} onChange={({target}) => setSearch(target.value)} onKeyUp={keyPressed} />
                <img src={IconSearch} alt="" />
                {
                  search.length < 3
                  ?
                  <span className='h-sm fs-6- ff-monse-regular- fw-normal validation-error-'>Debe tener al menos 3 caracteres</span>
                  :
                  null
                }
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        !chargeSearch 
        ?
        null
        :
        <div className='row mt-2'>
          <div className='accordion row gy-4 gx-0 mt-2' id="accordionPanelsStayOpenExample">
            <div className='accordion-item'>
              <div className='d-flex flex-row justify-content-between align-items-end align-self-end justify-content-sm-between align-items-sm-end align-self-sm-end justify-content-md-between align-items-md-end align-self-md-end justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                <div className='d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-center align-items-start align-self-center justify-content-sm-center align-items-sm-start align-self-sm-center justify-content-md-center align-items-md-start align-self-md-center justify-content-lg-between align-items-lg-center align-self-lg-center justify-content-xl-between align-items-xl-center align-self-xl-center justify-content-xxl-between align-items-xxl-center align-self-xxl-center w-100'>
                  <h5 className='m-0 ms-4 lh-sm fs-3- ff-monse-regular- fw-bold tx-white-'>Publicaciones</h5>
                </div>
                <h2 className='accordion-header d-flex flex-column justify-content-xxl-center align-items-xxl-center align-self-xxl-center ms-4 me-4' id="panelsStayOpen-headingThree">
                  <button className='accordion-button ps-0 pe-0 pt-2 pb-2' type="button" data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne">
                  </button>
                </h2>
              </div>
              <div id="panelsStayOpen-collapseOne" className='accordion-collapse collapse show' aria-labelledby="panelsStayOpen-headingOne">
                <div className='accordion-body'>
                  <div className='row row-cols-auto g-4 mb-2'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                            Evolución temporal de las publicaciones
                          </h1>
                          <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                        </div>
                        <div className='card-body p-0 ps-2 pe-2 w-100'>
                          <div className='w-100 h-100 mx-auto' id='chart-search-one-'></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                            <div className='col d-flex flex-column'>
                              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                                <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                  Tabla con la lista de publicaciones
                                </h1>
                              </div>
                              <div className='w-auto mt-3 '>
                                <form action="" className='position-relative wrapper-search-small- me-3'>
                                  <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                    <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={filterTablePublications} onChange={({ target }) => setFilterTablePublications(target.value)} />
                                    <img src={IconSearch} alt="" />
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                              <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                    <div className='card-header border-0 bg-transparent pb-0'>
                                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                    </div>
                                    <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                        # <span>{ tablePublications.valores.length }</span>
                                      </p>
                                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                        Registros
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-body p-0 w-100 wrapper-data-'>
                          <div className='table-responsive table-general-'>
                            <table className='table table-sm table-striped table-no-border- align-middle'>
                              <thead>
                                <tr>
                                  <th scope="col" className='th-width-md-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Título</span>
                                    </div>
                                  </th>
                                  <th scope="col" className='th-width-sm-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Tipo publicación</span>
                                    </div>
                                  </th>
                                  <th scope="col" className='th-width-md-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>donde se publicó</span>
                                    </div>
                                  </th>
                                  <th scope="col" className='th-width-auto-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Año</span>
                                    </div>
                                  </th>
                                  <th scope="col" className='th-width-md-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Autores</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  tablePublications.valores.filter(table => {
                                    if (filterTablePublications !== '') {
                                      let keys = Object.keys(table);
                                      let flagKeys = false;
                                      let i = 0;
                                      while (i < keys.length && !flagKeys) {
                                        if (keys[i] === 'titulo' || keys[i] === 'tipo_articulo' || keys[i] === 'revista' || keys[i] === 'year' || keys[i] === 'autores') {
                                          let word = '' + table[keys[i]];
                                          let words = word.split(' ');
                                          let j = 0;
                                          flagKeys = word.toUpperCase().includes(filterTablePublications.toUpperCase());
                                          while (j < words.length && !flagKeys) {
                                            if (words[j].toUpperCase().includes(filterTablePublications.toUpperCase())) {
                                              flagKeys = true;
                                            }
                                            j ++;
                                          }
                                          if (filterTablePublications.toUpperCase() === word.substring(0, filterTablePublications.length).toUpperCase()) {
                                            flagKeys = true;
                                          }
                                        }
                                        if (flagKeys) {
                                          return true;
                                        }  
                                        i ++;
                                      }
                                      
                                    } else {
                                      return true;
                                    }
                                  }).map(row => (
                                    <tr key={row._id}>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.titulo }</p>
                                      </td>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.tipo_articulo }</p>
                                      </td>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.revista }</p>
                                      </td>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.year }</p>
                                      </td>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.autores }</p>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                        { /*
                          <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                            <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                              <div className='col-auto'>
                                <nav>
                                  <ul className="pagination justify-content-center">
                                    {startPage !== 1 && (
                                      <li className="page-item">
                                        <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                      </li>
                                    )}
                                    {startPage > 2 && (
                                      <li className="page-item disabled">
                                        <span className="page-link">...</span>
                                      </li>
                                    )}
                                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                      <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                      </li>
                                    ))}
                                    {endPage < totalPages - 1 && (
                                      <li className="page-item disabled">
                                        <span className="page-link">...</span>
                                      </li>
                                    )}
                                    {endPage !== totalPages && (
                                      <li className="page-item">
                                        <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                      </li>
                                    )}
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>                        
                        */}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                            Tipo de publicación Publindex
                          </h1>
                          <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                        </div>
                        <div className='card-body p-0 ps-2 pe-2 w-100'>
                          <div className='w-100 h-100 mx-auto' id='chart-search-two-'></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                            Tipo de publicación Scimago
                          </h1>
                          <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-semibold tx-gray-light-'></p>
                        </div>
                        <div className='card-body p-0 ps-2 pe-2 w-100'>
                          <div className='w-100 h-100 mx-auto' id='chart-search-three-'></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='container-card-big-datatable-' className='card border-0 br-1- w-100 position-relative bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <div className='row gx-0 gx-sm-0 gx-md-0 gx-lg-5 gx-xl-5 gx-xxl-5 d-flex flex-wrap flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row flex-xxl-row justify-content-start justify-content-sm-start justify-content-md-start justify-content-lg-between justify-content-xl-between justify-content-xxl-between align-items-center align-self-center'>
                            <div className='col d-flex flex-column'>
                              <div className='w-auto d-flex flex-row justify-content-center align-items-center align-self-start'>
                                <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                                  Top 10 instituciones con más publicaciones
                                </h1>
                              </div>
                              <div className='w-auto mt-3 '>
                                <form action="" className='position-relative wrapper-search-small- me-3'>
                                  <div className='form-search inner-addon- left-addon- right-addon- input-gradient'>
                                    <input type="text" className='form-control search-' id="search" placeholder="Buscador" value={filterTableInstitutions} onChange={({ target }) => setFilterTableInstitutions(target.value)} />
                                    <img src={IconSearch} alt="" />
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className='col mt-4 mt-sm-4 mt-md-4 mt-lg-0 mt-xl-0 mt-xxl-0'>
                              <div className='row g-3 justify-content-md-end align-items-center align-self-center'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-7 col-xxl-5'>
                                  <div id="wrapper-indicators-" className='card border-0 br-1- w-100 bg-blue- bs-4-'>
                                    <div className='card-header border-0 bg-transparent pb-0'>
                                      <h1 className='m-0 p-0 lh-sm fs-6- ff-monse-medium- fw-semibold tx-green-light-'>Titulo tarjeta</h1>
                                    </div>
                                    <div className='p-0 pt-3 pb-3 w-100 d-flex flex-column justify-content-center align-items-center align-self-center p-0'>
                                      <p className='m-0 p-0 text-center lh-sm fs-0- ff-monse-bold- tx-green-light-'>
                                        # <span>{ tableInstitutions.valores.length }</span>
                                      </p>
                                      <p className='m-0 p-0 text-center lh-sm fs-6- ff-monse-regular- fw-semibold tx-white-'>
                                        Registros
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='card-body p-0 w-100 wrapper-data-'>
                          <div className='table-responsive table-general-'>
                            <table className='table table-sm table-striped table-no-border- align-middle'>
                              <thead>
                                <tr>
                                  <th scope="col" className='th-width-md-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Institución</span>
                                    </div>
                                  </th>
                                  <th scope="col" className='th-width-sm-'>
                                    <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
                                      <span className='fs-5- ff-monse-regular- fw-bold tx-white-'>Cantidad de publicaciones</span>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  tableInstitutions.valores.filter(table => {
                                    if (filterTableInstitutions !== '') {
                                      let keys = Object.keys(table);
                                      let flagKeys = false;
                                      let i = 0;
                                      while (i < keys.length && !flagKeys) {
                                        if (keys[i] === 'institucion' || keys[i] === 'cantidad') {
                                          let word = '' + table[keys[i]];
                                          let words = word.split(' ');
                                          let j = 0;
                                          flagKeys = word.toUpperCase().includes(filterTableInstitutions.toUpperCase());
                                          while (j < words.length && !flagKeys) {
                                            if (words[j].toUpperCase().includes(filterTableInstitutions.toUpperCase())) {
                                              flagKeys = true;
                                            }
                                            j ++;
                                          }
                                          if (filterTableInstitutions.toUpperCase() === word.substring(0, filterTableInstitutions.length).toUpperCase()) {
                                            flagKeys = true;
                                          }
                                        }
                                        if (flagKeys) {
                                          return true;
                                        }  
                                        i ++;
                                      }
                                      
                                    } else {
                                      return true;
                                    }
                                  }).map(row => (
                                    <tr>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.institucion }</p>
                                      </td>
                                      <td className='align-middle'>
                                        <p className='m-0 lh-sm fs-5- ff-monse-regular- fw-normal text-center'>{ row.cantidad }</p>
                                      </td>
                                    </tr>
                                  ))                                
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/*                        
                          <div className="card-footer border-0 bg-transparent pb-3 pt-3">
                            <div className='row gx-2 d-flex flex-row justify-content-center align-items-start align-self-start'>
                              <div className='col-auto'>
                                <nav>
                                  <ul className="pagination justify-content-center">
                                    {startPage !== 1 && (
                                      <li className="page-item">
                                        <button className="page-link" onClick={() => setCurrentPage(1)}>1</button>
                                      </li>
                                    )}
                                    {startPage > 2 && (
                                      <li className="page-item disabled">
                                        <span className="page-link">...</span>
                                      </li>
                                    )}
                                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                                      <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                                        <button className="page-link" onClick={() => setCurrentPage(page)}>{page}</button>
                                      </li>
                                    ))}
                                    {endPage < totalPages - 1 && (
                                      <li className="page-item disabled">
                                        <span className="page-link">...</span>
                                      </li>
                                    )}
                                    {endPage !== totalPages && (
                                      <li className="page-item">
                                        <button className="page-link" onClick={() => setCurrentPage(totalPages)}>{totalPages}</button>
                                      </li>
                                    )}
                                  </ul>
                                </nav>
                              </div>
                            </div>
                          </div>
                        */}
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                            Clasificación de los investigadores
                          </h1>
                          <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Cantidad de investigadores por clasificación</p>
                        </div>
                        <div className='card-body p-0 ps-2 pe-2 w-100'>
                          <div className='w-100 h-100 mx-auto' id='chart-search-four-'></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                      <div id='card-scientometric-large-' className='card border-0 br-1- w-100 bg-blue-translucent- glass-effect- bs-4- p-0'>
                        <div className='card-header border-0 bg-transparent pb-4 pt-4'>
                          <h1 className='m-0 p-0 lh-sm fs-4- ff-monse-regular- fw-bold tx-green-light-'>
                            Formación investigadores
                          </h1>
                          <p className='m-0 p-0 pt-1 lh-sm fs-6- ff-monse-light- fw-normal tx-white-'>Cantidad de investigadores por tipo de formación</p>
                        </div>
                        <div className='card-body p-0 ps-2 pe-2 w-100'>
                          <div className='w-100 h-100 mx-auto' id='chart-search-five-'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}
